import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IQuoteDocument } from '../model/IQuoteDocument';
import { CustomResponse } from '../../../_shared/classes/CustomResponse';
import { Observable } from 'rxjs';
import { BaseService } from 'src/app/services/base-service.service';
import { SessionStorageUtils } from 'src/app/util/session-storage';

@Injectable({
  providedIn: 'root'
})
export class QuoteDocumentService extends BaseService{
  
  constructor(
    private httpClient: HttpClient) {   
    super();
  }

  public getDocuments(quoteId: number): Observable<CustomResponse<Array<IQuoteDocument>>>{
    return this.httpClient.get<CustomResponse<Array<IQuoteDocument>>>(this.buildXenithEndpoint(`/HighValueQuoteDocument?quoteId=${quoteId}`), this.GetAuthHeaderJson());
  }

  public generateQuoteDocument(document: IQuoteDocument, quoteId: number) : Observable<File> {
    return this.httpClient.get<File>(this.buildXenithEndpoint(`${document.GenerationEndpoint}?quoteId=${quoteId}`), this.GetAuthHeaderJson());
  }

  public downloadQuoteDocument(document: IQuoteDocument) : Observable<File> {
    const httpOptions = {
      responseType: 'blob' as 'json',
      headers: {
        'Authorization': `Bearer ${SessionStorageUtils.getUserToken().AccessToken}`
      }
    };
    return this.httpClient.get<File>(this.buildXenithEndpoint(`/HighValueQuoteDocument/Download?documentId=${document.Id}`), httpOptions);
  }

  public downloadSubmittedQuoteDocument(UploadedFileId: number): Observable<File> {
    const httpOptions = {
      responseType: 'blob' as 'json',
      headers: {
        'Authorization': `Bearer ${SessionStorageUtils.getUserToken().AccessToken}`
      }
    };
    return this.httpClient.get<File>(this.buildXenithEndpoint(`/HighValueQuoteDocument/Submitted/Download?submittedDocumentId=${UploadedFileId}`), httpOptions);
  }

  public uploadQuoteDocumentFile(result: any) : Observable<CustomResponse<boolean>>{
    var file = result.Files[0];
    let formData:FormData = new FormData();    
    formData.append('QuoteDocumentFile', file, file.name);
    formData.append('DocumentId', result.DocumentId);
    formData.append('QuoteId', result.QuoteId);
    
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    headers.append('Authorization', `Bearer ${SessionStorageUtils.getUserToken().AccessToken}`)

    return this.httpClient.post<CustomResponse<boolean>>(this.buildXenithEndpoint(`/HighValueQuoteDocument`), formData, {headers: headers});
  }

} 
