import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { OptionsService } from '../_helpers/options.service';
import { QuoteService } from '../_helpers/quote.service';
import { QuoteUpdate } from '../_shared/classes/QuoteUpdate';
import { Validation } from '../_shared/classes/Validation';
import { OptionsModel } from './options.class';
import { UserService } from '../user/services/user.service';
import { FormBaseComponent } from '../components/shared/base-components/form-base.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { IOption } from '../_shared/interfaces/IOption';
import { AuthService } from '../_helpers/auth.service';

type CoverageDescription = {
  [key: string]: string;
};

@Component({
  selector: 'app-options',
  templateUrl: './options.component.html',
  styleUrls: ['./options.component.css']
})
export class OptionsComponent extends FormBaseComponent implements OnInit {

  entityProp = new OptionsModel;
  entityLiab = new OptionsModel;
  premiumValues = new OptionsModel;
  cantRows: string;
  today: Date = new Date();
  continue: boolean = true;
  validation: Validation = JSON.parse(sessionStorage.getItem('validation')) ?? new Validation();
  quoteId: number = + (sessionStorage.getItem('quoteId') ?? 0);
  quoteNumber: string = sessionStorage.getItem('quoteNumber');
  agentInfo: any = {};
  alreadyCreated = false;
  showDesc = false;
  prices: any;
  riskAddressHeader: string;
  data: any;
  email: string = "";
  @ViewChild('emailModal', {}) emailModal: ElementRef;
  saveData: IOption;
  propertyDescList: CoverageDescription = {
    "Replacement Cost on Personal Property": "Covered losses to personal property (coverage C) are settled at replacement cost at the time of the loss.",
    "Increased Coverage C Special Limits": "Special limits are increased for specific property under coverage C including money, gold, securities, jewelry and watches.",
    "Inflation Guard": "Limits for coverages A, B, C and D are increased annually by 3% to ensure sufficient coverage is provided.",
    "Increased Ordinance or Law": "Increased percentage of the limit that applies to coverage A for the increased costs incurred due to the enforcement of any ordinance or law following damage by an insured peril.",
    "Increased Limit on Business Property": "Special limit is increased for property which is primarily used for business purposes under coverage C.",
    "Water Back-up and Sump Discharge": "Damage caused by water which backs-up through sewers or drains, or overflows, or is discharged from a sump or sump pump.",
    "Supplemental Loss Assessment": "Additional amount for share of loss assessment charged by a corporation or association of property owners following a direct loss to collectively owned property.",
    "Mold Coverage": "Physical damage to coverage A and B and subsequent loss of use by mold, mildew or fungus following fire, accidental discharge or overflow of water.",
    "Enhanced Additional Covers": "Increased limits for section one additional covers including debris removal, trees and fire department service charges.",
    "Extended Replacement Cost on Coverage A": "Additional percentage amount above limit of coverage A following a loss.",
    "Lock Replacement Expense": "Cost of replacing locks on outside doors, windows, safes and alarms if keys are lost or stolen.",
    "Service Line": "Coverage for underground piping and wiring which is located on the property for which there is a legal liability for repair or replacement.",
    "AOP Deductible Waiver": "The AOP deductible is waived for a covered loss over a stated amount.",
    "Golf Cart Physical Loss": "Physical damage to golf cart.",
    "Pollution": "Property remediation for escaped fuel.",
    "Mechanical Breakdown": "Loss to a household appliance as a result of failure of pressure or mechanical failure.",
    "Mortgage Replacement Expense": "Mortgage acquisition costs and corresponding legal fees if a mortgage has to be obtained following a total loss including title search fees and appraisal and survey fees."
  };

  liabilityDescList: CoverageDescription = {
    "Personal Injury": "Legal liability if a claim is made for damages resulting from an offense including false arrest, malicious prosecution or slanderous or libel publication.",
    "Identity Fraud Recovery": "Expenses incurred as the result of identity fraud including costs for notarising affidavits and reasonable attorney fees.",
    "Pollution 0": "Limited liability for lead and escaped liquid fuel with respect to bodily injury or property damage.",
    "Recreational Motor Vehicle Liability": "Broadens the motor vehicle liability cover provided as standard."};

  constructor(
    private router: Router,
    private service: OptionsService,
    private authService: QuoteService,
    private titleService: Title,
    private userService: UserService,
    private modalService: NgbModal,
  ) {
    super();
    console.log(this.today);
  }

  ngOnInit(): void {   
    this.userService.refreshToken();
    this.setRiskAddressHeader();
    this.titleService.setTitle("Hiscox High Value - Options");
    if (this.quoteId != 0 && this.quoteId != null && this.quoteId != undefined) {
      this.service.getByQuote(this.quoteId).subscribe(item => {
        
        this.data = item.Data;
        console.log(this.data)
        let dataProp = item.Data.coverageOptionsProperty;
        let dataLiab = item.Data.coverageOptionsLiability;
        let dataP = item.Data.quotePremiums;
        let check = item.Success;
        if (check) {
          this.cantRows = "12";
          this.alreadyCreated = true;
          this.entityProp.Detail = dataProp;
          this.entityLiab.Detail = dataLiab;
          this.premiumValues.Detail = dataP;
        }
      })
    }
  }

  buyGold()
  {
    this.options(1);
  }

  buyPlat()
  {
    this.options(2);
  }

  buyPlatPlus()
  {
    this.options(3);
  }

  buyCustomized()
  {
    this.options(4);
  }

  options(option: number) {
    var update = new QuoteUpdate();
    update.data.OptionId = option;
    update.data.OptionUpdate = true;
    update.data.QuoteId = this.quoteId;
    this.service.update(update.data).subscribe(result => {
      let check = result.Success;
      if(check)
        this.router.navigate(['end']);
      else{
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'There was an error checking the option'
        });
      }
    },
    error => {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'There was an error checking the option'
      });
    })
  }

  printOptions(){
    this.service.downloadPDF(this.data);
  }

  openEmailWithOptions(){
    this.modalService.open(this.emailModal, { ariaLabelledBy: 'modal-basic-title', size: 'xl', backdrop: false })
  }

  sendEmailWithOptions(){
    let value = {... this.data, email: this.email}
    console.log(value);
    this.service.sendEmail(value).subscribe(item => {
      let data = item.Data;
      let check = item.Success;
      if (!check && !data) {
        Swal.fire({
          icon: 'error',
          title: 'Something went wrong',
          text: 'Please try again later'
        });
      }
    })

  }

  getDescriptionByLiability(coverage: string){
    console.log(coverage, this.liabilityDescList[coverage])
    return this.liabilityDescList[coverage];
  }

  getDescriptionByProperty(coverage: string){
    console.log(coverage, this.propertyDescList[coverage]);
    return this.propertyDescList[coverage];
  }

  showDescription(){
    this.showDesc = !this.showDesc;
  }

  onPrevious() {
    this.router.navigate(['loss-history']);
  }
}
