import { ILogin } from "../_shared/interfaces/ILogin";
import { IAgencySelector } from "../_shared/interfaces/IAgencySelector";
import { IAddress } from "../_shared/interfaces/IAddress";
import { IMoratorium } from "../_shared/interfaces/IMoratorium";
import { IState } from "../_shared/interfaces/IState";
import { ISession } from "../_shared/interfaces/ISession";
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

export class HomeInput {
  Login: ILogin = {
    email: '',
    password: ''
  };
  AgencySelector: IAgencySelector = {
    agencies: null,
    selected: null
  };
  Declinations: IDeclinations = {
    rented: false,
    damaged: false,
    construction: false,
    barred: false,
    large: false,
    solar: false,
    flooded: false,
    floodDamageFixed: null
  };
  EffectiveDate: NgbDateStruct = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate()
  };
  AutoCompletePlace: any;
  RawAddressInput: string = '';
  NotFoundAddress: IAddress = {
    Address1: '',
    Address2: '',
    City: '',
    State: null,
    Zip: null,
    County: null
  };
  HydrantDistance: number = null;
}

export class HomeVisible {
  FindAddress: boolean = true;
  FindAddressValidation: boolean = false;
  EffectiveDate: boolean = false;
  Declinations: boolean = false;
  Spinner: boolean = false;
  Mobile: boolean;
  InvalidItems: string[] = [];
  InvalidItemsText: string[] = [];
  ViewSize: number;
}

export class HomeData {
  Progress: number = 0;
  InactiveCounties: IMoratorium[];
  QuoteableStates: IState[];
  Counties: string[];
  SessionModel: ISession = sessionStorage.getItem('model') != null ? (JSON.parse(sessionStorage.getItem('model')) as ISession) : {};
  Today: NgbDateStruct = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate()
  };
  MaxEffDate: NgbDateStruct = {
    year: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 44).getFullYear(),
    month: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 44).getMonth() + 1,
    day: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 44).getDate()
  };
  WorkingAddress: IAddress;
  WorkingVeriskPpcId: number;
}

interface IDeclinations {
  rented: boolean;
  damaged: boolean;
  construction: boolean;
  barred: boolean;
  solar: boolean;
  large: boolean;
  flooded: boolean;
  floodDamageFixed?: boolean;
}
