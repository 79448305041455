import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CoverageService } from '../_helpers/coverage.service';
import { QuoteService } from '../_helpers/quote.service';
import { QuoteUpdate } from '../_shared/classes/QuoteUpdate';
import { Validation } from '../_shared/classes/Validation';
import { CoverageModel } from './coverage.class';
import { UserService } from '../user/services/user.service';
import { FormBaseComponent } from '../components/shared/base-components/form-base.component';
import { SessionStorageUtils } from '../util/session-storage';
import { ValuationInformation } from '../_shared/interfaces/verisk/valuation/IValuationInformation';


@Component({
  selector: 'app-coverage',
  templateUrl: './coverage.component.html',
  styleUrls: ['./coverage.component.css']
})
export class CoverageComponent extends FormBaseComponent implements OnInit, AfterViewInit {

  @ViewChildren('Underwriter') inputRef : ElementRef[];
  entity = new CoverageModel;
  today: Date = new Date();
  liabilityOpt: any[] = [{ value: 300000, text: "$300,000" }, { value: 500000, text: "$500,000" }, { value: 1000000, text: "$1,000,000" }];
  windOpt: any[] = [{ value: 1, text: "1%" }, { value: 2, text: "2%" }, { value: 3, text: "3%" }, { value: 5, text: "5%" }];
  stormOpt: any[] = [{ value: 3, text: "3%" }, { value: 5, text: "5%" }];
  aopOpt: any[] = [{ value: 1, text: "1%" }, { value: 2, text: "2%" }, { value: 3, text: "3%" }, { value: 5, text: "5%" }];
  lossUseOpt: any[] = [{ value: 10, text: "10%" }, { value: 15, text: "15%" }, { value: 20, text: "20%" }]
  otherStrucOpt: any[] = [{ value: 2, text: "2%" }, { value: 5, text: "5%" }, { value: 10, text: "10%" }, { value: 15, text: "15%" }, { value: 20, text: "20%" }]
  personalPropertyopt: any[] = [{ value: 40, text: "40%" }, { value: 45, text: "45%" }, { value: 50, text: "50%" }, { value: 55, text: "55%" }, { value: 60, text: "60%" }, { value: 65, text: "65%" }, { value: 70, text: "70%" }]
  extendedLiabilityAmtOpt: any[] = [{ value: 0, text: "$0" }, { value: 300000, text: "$300,000" }, { value: 500000, text: "$500,000" }, { value: 1000000, text: "$1,000,000" }];
  medicalPaymentOpt: any[] = [{ value: 1000, text: "$1,000" }, { value: 2000, text: "$2,000" }, { value: 3000, text: "$3,000" }, { value: 4000, text: "$4,000" }, { value: 5000, text: "$5,000" }];
  windSideHwyOpt: any[] = [{ value: "East", text: "East" }, { value: "West", text: "West" }];
  windDeductibleOpt: any[] = [{ value: 1, text: "1%", disabled: false }, { value: 2, text: "2%", disabled: false }, { value: 3, text: "3%", disabled: false }, { value: 5, text: "5%", disabled: false }];
  
  isWindSideHwyZipCode = false;
  showWindExclusion = false;
  showWindDeductible = false;
  hwyZipCodes: number[] = [77571, 77586, 77507];
  showNamedStorm = false;
  
  validation: Validation = JSON.parse(sessionStorage.getItem('validation')) ?? new Validation;
  quoteId: number = + (sessionStorage.getItem('quoteId') ?? 0);
  quoteNumber: string = sessionStorage.getItem('quoteNumber').replace(/"/g, '');
  alreadyCreated = false;
  riskAddressHeader: string;

  valuationInformation: ValuationInformation = SessionStorageUtils.getValuationInformation();
  dwellingMinValue: number; 
  dwellingMaxValue: number;

  extendedLiabilityEnabled: boolean;
  isSettedextendedLiabilityEnabled: Boolean;
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    private service: CoverageService,
    private quoteService: QuoteService,
    private titleService: Title,
    private userService: UserService
  ){
    super();
  }
  ngAfterViewInit(): void {
    super.configureCheckForChange(this.inputRef);
  }

  ngOnInit(): void {
    this.userService.refreshToken();
    this.initWindExclusion();
    this.setDwellingValues();
    this.setRiskAddressHeader();
   
    this.isSettedextendedLiabilityEnabled = false;
    this.titleService.setTitle("Hiscox High Value - Coverage Options");
    if (this.quoteId != 0 && this.quoteId != null && this.quoteId != undefined) {
      this.service.getByQuote(this.quoteId).subscribe(item => {
        let data = item.Data.CoverageObj;
        if (item.Data.CoverageObj.WindExclusion == 0) {
          this.showWindDeductible = true;
        }
        let check = item.Success;
        this.extendedLiabilityEnabled = item.Data.OccupancyTypeObj;    
        this.isSettedextendedLiabilityEnabled = true;    
        if (check && data != null) {
          this.alreadyCreated = true;
          this.entity.Detail = data;
        }
      })
    }
    else if(localStorage.getItem("coverageprev")){
      this.entity.Detail = JSON.parse(localStorage.getItem("coverageprev"));
      localStorage.removeItem("coverageprev");
    }

    if(this.isSettedextendedLiabilityEnabled == false)
    {
      
      this.extendedLiabilityEnabled = localStorage.getItem("occupancyTypeSelected") != "1" ? true : false
    }
    

    

    this.mouseHandlers();
  }

  coverage() {
    this.service.save(this.entity.Detail).subscribe(item => {
      let data = item.Data;
      let check = item.Success;
      if (check) {
        sessionStorage.setItem("validation", JSON.stringify(this.validation));
        if (!this.alreadyCreated) {
          let value = new QuoteUpdate;
          value.data.CoverageId = data;
          value.data.CoverageUpdate = true;
          value.data.QuoteId = this.quoteId;
          this.quoteService.update(value.data).subscribe(result => {
            if (result.Success) {
              this.router.navigate(['credits']);
            }
          })
        }
        else {
          this.router.navigate(['credits']);
        }
      }
    })
  }

  setDwellingValues() {
    this.entity.Detail.Dwelling = Math.round(this.valuationInformation.ReplacementCost / 1000) * 1000; 
    this.dwellingMinValue = this.entity.Detail.Dwelling;

    this.dwellingMaxValue = Math.round((this.valuationInformation.ReplacementCost + (this.valuationInformation.ReplacementCost * 0.20)) / 1000) * 1000;

    SessionStorageUtils.saveDwelling(this.dwellingMaxValue);
  }

  get showTivWarning() {
    var tivValue = this.calculateTiv(); 

    return tivValue >= 5000000 || ((this.valuationInformation.PPC == "9" || this.valuationInformation.PPC == "10") && tivValue >= 2000000);
  }

  private calculateTiv(): number{
    var returnValue = 0; 
    if(this.entity.Detail.Dwelling != null){
      returnValue += this.entity.Detail.Dwelling; 
    }
    if(this.entity.Detail.StructuresAmt != null){
      returnValue += this.entity.Detail.StructuresAmt; 
    }

    if(this.entity.Detail.PersonalPropertyAmt != null){
      returnValue += this.entity.Detail.PersonalPropertyAmt; 
    }

    if(this.entity.Detail.LossAmt != null){
      returnValue += this.entity.Detail.LossAmt; 
    }

    return returnValue; 
  }

  protected initWindExclusion() {
    this.isWindSideHwyZipCode = this.hwyZipCodes.includes(this.validation.data.ZipCode);
    this.showWindExclusion = !this.isWindSideHwyZipCode && this.validation.data.Tier1Flg;

    this.entity.Detail.WindExclusion = this.showWindExclusion && this.validation.data.Tier1Flg;

    if (this.entity.Detail.WindExclusion) {
      this.entity.Detail.WindDeductible = 0;
    }

    if(this.validation.data.Tier2Flg) {
      this.showWindDeductible = true;
      this.entity.Detail.WindDeductible = 2; 
      this.windDeductibleOpt[0].disabled = true; 
    }

    this.setShowNamedStorm();
  }

  onHwyChange() {
    if(this.entity.Detail.WindSideHwy == "East"){
      if(this.validation.data.Tier1Flg){
        this.showWindExclusion = true; 
        this.entity.Detail.WindExclusion = true; 
        this.entity.Detail.WindDeductible = 0; 
      }
    }
    else{
      this.showWindExclusion = false;
      this.entity.Detail.WindExclusion = null;
      
      if (this.validation.data.Tier1Flg) {
        this.showWindDeductible = true;
        this.entity.Detail.WindDeductible = 2;
        this.windDeductibleOpt[0].disabled = true;
      } else if (!this.validation.data.Tier2Flg) {
        this.entity.Detail.WindDeductible = null; 
      }
    }
    
    this.setShowNamedStorm();
  }

  onWindExclusionChange() {
    if(this.entity.Detail.WindExclusion){
      this.entity.Detail.WindDeductible = 0;
      this.showWindDeductible = false;
      this.windDeductibleOpt[0].disabled = false; 
    }
    else{
      this.showWindDeductible = true; 
      if(this.validation.data.Tier1Flg || this.validation.data.Tier2Flg)
      {
          this.entity.Detail.WindDeductible = 2; 
          this.windDeductibleOpt[0].disabled = true; 
      }
      else
      {
        this.windDeductibleOpt[0].disabled = false; 
        this.entity.Detail.WindDeductible = 1;
      }      
    }
    this.setShowNamedStorm();
  }

  protected setShowNamedStorm() { 
    this.showNamedStorm = this.validation.data.Tier1Flg && this.showWindExclusion && this.entity.Detail.WindExclusion != true;

    if(this.showNamedStorm) this.entity.Detail.StormDeductible = 3
    else this.entity.Detail.StormDeductible = null
  }

  get showExtendedLiabilityAmt() {
    return this.entity.Detail.ExtendedLiability ?? false;
  }

  get isDwellingValueInvalid() {
    return !(this.entity.Detail.Dwelling >= this.dwellingMinValue && this.entity.Detail.Dwelling <= this.dwellingMaxValue);
  }

  get isAOPDeductibleInvalid() {
    return this.entity.Detail.WindDeductible > 0 && this.entity.Detail.AopDeductible > this.entity.Detail.WindDeductible;
  }

  get structuresAmt() {
    this.entity.Detail.StructuresAmt = this.entity.Detail.Dwelling * (this.entity.Detail.Structures / 100);
    return this.entity.Detail.StructuresAmt;
  }

  get personalPropertyAmt() {
    this.entity.Detail.PersonalPropertyAmt = this.entity.Detail.Dwelling * (this.entity.Detail.PersonalProperty / 100);
    return this.entity.Detail.PersonalPropertyAmt;
  }

  get lossAmt() {
    this.entity.Detail.LossAmt = this.entity.Detail.Dwelling * (this.entity.Detail.LossUse / 100);    
    return this.entity.Detail.LossAmt;
  }

  onPrevious() {
    localStorage.setItem("coverageprev", JSON.stringify(this.entity.Detail));
    this.router.navigate(['property-detail']);
  }

  get isFormInvalid(): boolean {
    return this.entity.Detail.Dwelling == undefined || this.isDwellingValueInvalid || this.isAOPDeductibleInvalid;
  }

  formatLabel(value: number) {
    if (value >= 1000) {
      return Math.round(value / 1000) + 'k';
    }

    return value;
  }

}
