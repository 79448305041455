import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CreditsService } from '../_helpers/credits.service';
import { QuoteService } from '../_helpers/quote.service';
import { SessionStorageUtils } from '../util/session-storage';
import { QuoteUpdate } from '../_shared/classes/QuoteUpdate';
import { Validation } from '../_shared/classes/Validation';
import { CreditsModel } from './credits';
import { UserService } from '../user/services/user.service';
import { FormBaseComponent } from '../components/shared/base-components/form-base.component';


@Component({
  selector: 'app-credits',
  templateUrl: './credits.component.html',
  styleUrls: ['./credits.component.css']
})
export class CreditsComponent extends FormBaseComponent implements OnInit, AfterViewInit {

  @ViewChildren('Underwriter') inputRef : ElementRef[];
  entity = new CreditsModel;
  today: Date = new Date();
  continue: boolean = true;
  validation: Validation = SessionStorageUtils.getValidation() ?? new Validation();
  quoteId: number = + (sessionStorage.getItem('quoteId') ?? 0);
  dwelling: number = + (sessionStorage.getItem('dwelling') ?? 0);
  quoteNumber: string = sessionStorage.getItem('quoteNumber');
  alreadyCreated = false;
  riskAddressHeader: string;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    private service: CreditsService,
    private quoteService: QuoteService,
    private titleService: Title,
    private userService: UserService
  ) {
    super();
    console.log(this.today);
  }
  ngAfterViewInit(): void {
    super.configureCheckForChange(this.inputRef);
  }

  ngOnInit(): void {
    var test = this.validation;
    this.userService.refreshToken();
    this.setRiskAddressHeader();
    this.titleService.setTitle("Hiscox High Value - Credits/Discounts");
    if (this.quoteId != 0 && this.quoteId != null && this.quoteId != undefined) {
      this.service.getByQuote(this.quoteId).subscribe(item => {
        let data = item.Data;
        let check = item.Success;
        if (this.dwelling >= 1000000)
          this.entity.Detail.Alarms = true;
        //console.log(data)
        if (check && data != null) {
          this.alreadyCreated = true;
          this.entity.Detail = data;
        }
      })
    }else if(localStorage.getItem("creditsprev")){
      this.entity.Detail = JSON.parse(localStorage.getItem("creditsprev"));
      localStorage.removeItem("creditsprev");
    }
    this.mouseHandlers();

  }

  get isHVACRepairBeforeRequiredYear() {
    return this.validation?.data?.YearBuilt <= 1984 && this.entity.Detail.HvacUpdate < 1985;
  }

  get isHVACRepairInTheFuture() {
    return this.validation?.data?.YearBuilt <= 1984 && this.entity.Detail.HvacUpdate > this.today.getFullYear();
  }

  get isHVACRepairYearInvalid() {
    return this.isHVACRepairBeforeRequiredYear || this.isHVACRepairInTheFuture;
  }

  get isWiringRepairBeforeRequiredYear() {
    return this.validation?.data?.YearBuilt <= 1984 && this.entity.Detail.WiringUpdate < 1985;
  }

  get isWiringRepairInTheFuture() {
    return this.validation?.data?.YearBuilt <= 1984 && this.entity.Detail.WiringUpdate > this.today.getFullYear();
  }

  get isWiringRepairYearInvalid() {
    return this.isWiringRepairBeforeRequiredYear || this.isWiringRepairInTheFuture;
  }

  get isPlumbingRepairBeforeRequiredYear() {
    return this.validation?.data?.YearBuilt <= 1984 && this.entity.Detail.PlumbingUpdate < 1985;
  }

  get isPlumbingRepairInTheFuture() {
    return this.validation?.data?.YearBuilt <= 1984 && this.entity.Detail.PlumbingUpdate > this.today.getFullYear();
  }

  get isPlumbingRepairYearInvalid() {
    return this.isPlumbingRepairBeforeRequiredYear || this.isPlumbingRepairInTheFuture;
  }

  get isRepairYearInvalid() {
    return this.isHVACRepairYearInvalid || this.isWiringRepairYearInvalid || this.isPlumbingRepairYearInvalid;
  }
  
  credits() {
    if(this.validate()){
      this.service.save(this.entity.Detail).subscribe(item => {
        let data = item.Data;
        let check = item.Success;
        if (check) {
          if (!this.alreadyCreated) {
            let value = new QuoteUpdate;
            value.data.CreditsId = data;
            value.data.CreditsUpdate = true;
            value.data.QuoteId = this.quoteId;
            this.quoteService.update(value.data).subscribe(result => {
              if (result.Success) {
                if (this.validation.data.Occupancy == "Secondary" || this.validation.data.Occupancy == "Seasonal" || this.validation.data.Occupancy == "Tenanted") {
                  this.router.navigate(['loss-history']);
                }
                else {
                  this.router.navigate(['loss-history']);
                }
              }
            })
          }
          else {
            if (this.validation.data.Occupancy == "Secondary" || this.validation.data.Occupancy == "Seasonal" || this.validation.data.Occupancy == "Tenanted") {
              this.router.navigate(['loss-history']);
            }
            else {
              this.router.navigate(['loss-history']);
            }
          }
        }
      })
    }
  }

  validate(): boolean {
    // With the changes in US #24816, the comparisons treat the string values of the update variables as numbers, so there is no string -> int conversion needed
    if ((this.validation.data.YearBuilt <= 1984 && this.entity.Detail.HvacUpdate < 1985) ||
      (this.validation.data.YearBuilt <= 1984 && this.entity.Detail.PlumbingUpdate < 1985) ||
      (this.validation.data.YearBuilt <= 1984 && this.entity.Detail.WiringUpdate < 1985) ||
      (this.validation.data.HomeValue >= 1000000 && !this.entity.Detail.HvacUpdate)){
        return false; 
      }
    else{
      return true; 
    }
  }

  onPrevious() {
    localStorage.setItem("creditsprev", JSON.stringify(this.entity.Detail));
    this.router.navigate(['coverage']);
  }
}
