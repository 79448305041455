import { ILossHistory, ILossItem } from "../_shared/interfaces/ILossHistory"

export class LossHistoryModel {
  Detail: ILossHistory = {
    Id: null,
    PriorLossFlg: false,
    LossHistoryItems: []
  }
}

export class LossItemModel {
  Item: ILossItem = {
    Id: null,
    IsEligible: null,
    LossDate: null,
    Value: null,
    Peril: "",
    Description: "",
    Status: "",
    LossHistory: {},
    LossHistoryId: null
  }
}
