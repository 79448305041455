import { IInsuredInfo } from "../_shared/interfaces/IInsuredInfo";

export class InformationModel {
  //this will instantiate a blank form when you set "model = new InformationModel," in the component
  data: IInsuredInfo = {
    Id: null,
    InsuredTypeId: 0,
    FirstName: "",
    TrustName: "",
    CorpName: "",
    Email: "",
    LastName: "",
    MiddleName: "",
    DOB: null,
    Phone: "",
    TrustBeneficiary: "",
    BeneficiaryLastName: "",
    OccupationFlg: false,
    BankruptcyFlg: false,
    ArsonFlg: false,
    Occupation: "",
    CustomOccupation: "",
    CoApplicantFlg: false,
    CoApplicantDOB: null,
    FirstDecliningCarrier: "",
    SecondDecliningCarrier: "",
    DecliningReason: "",
    CustomReason: "",
    TaxPurpose: false,
    DeclinedCarrierFlg: true,
    OccupationStatus:"",
    PriorCarrier:""
  }
}
