import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-quote-document-upload-modal',
  templateUrl: './quote-document-upload-modal.component.html',
  styleUrls: ['./quote-document-upload-modal.component.css']
})
export class QuoteDocumentUploadModalComponent implements OnInit {

  private quoteId: number = 0;
  private documentId: number = 0;
  private files: File[] = [];

  constructor(
    public dialogRef: MatDialogRef<QuoteDocumentUploadModalComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: any,
    private notificationService: NotificationService) { 
      this.quoteId = data.QuoteId;
      this.documentId = data.DocumentId;
  }

  ngOnInit(): void {
  }

  onFileChange(files: Array<File>){
    this.files = files;
  }

  uploadFile(): void{
    if(this.files.length <= 0){
      this.notificationService.showErrorMessage('Invalid Action', 'At least one file is required to be submitted');
      return;
    }

    this.dialogRef.close({
      DocumentId: this.documentId,
      QuoteId: this.quoteId,
      Files: this.files
    });
  }
}
