import { AfterViewInit, Component, ElementRef, OnInit, ViewChildren } from '@angular/core';
import { FormBaseComponent } from '../components/shared/base-components/form-base.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { QuoteService } from '../_helpers/quote.service';
import { UserService } from '../user/services/user.service';
import { VeriskService } from '../services/verisk/verisk.service';
import { SessionStorageUtils } from '../util/session-storage';
import { FormatService } from '../_helpers/format.service';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { UnderwriterModificationModel } from './underwriter-modification.class';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { UnderwrtierModificationService } from '../_helpers/underwriter-modifcation.service';

@Component({
    selector: 'app-underwriter-modifications',
    templateUrl: './underwriter-modification.component.html',
    styleUrls: ['./underwriter-modification.component.css']
})

export class UnderwriterModificationComponent extends FormBaseComponent implements OnInit, AfterViewInit {
  
  @ViewChildren('Underwriter') inputRef : ElementRef[];
  public entity: UnderwriterModificationModel = new UnderwriterModificationModel();
  public quote;
  private quoteId: number;

  constructor (
      private router: Router,
      private activatedRoute: ActivatedRoute,
      private quoteService: QuoteService, 
      private titleService: Title,
      private userService: UserService,
      private veriskService: VeriskService,
      private formatService: FormatService,
      private http: HttpClient,
      private underwriterModificationService: UnderwrtierModificationService
    ) {
        super()
        this.activatedRoute.params.subscribe((param) => {
          this.quoteId = param['quoteId'];
        })
  }

  ngAfterViewInit(): void {
    super.configureCheckForChange(this.inputRef);
  }

  ngOnInit(): void {
      this.userService.refreshToken();
      this.titleService.setTitle("Hiscox High Value - Underwriter Modification Field");
      this.quote = this.quoteService.get(this.quoteId);
  }

  calculateManualPremiumField() {
    this.entity.Detail.ManualPremiumField = this.entity.Detail.WaterDamageDeductible + this.entity.Detail.WaterDamageSublimit;
    
  }

  acceptQuote() {

  }

  modifyQuote() {
    this.underwriterModificationService.updateModifyQuoteFlg(this.quote);
  }

  rejectQuote() {

  }

  redirectToCoverage(){
    this.router.navigate(['coverage']);
  }

  onPrevious() {
    this.router.navigate(['upload-documents']);
  }

  get isUnderwriterModificationsInvalid() {
  
    return (this.entity.Detail.ManualPremiumField == 0 || this.entity.Detail.ManualPremiumField == null || this.entity.Detail.WaterDamageDeductible == null || this.entity.Detail.WaterDamageDeductible == 0 || this.entity.Detail.WaterDamageSublimit == 0 || this.entity.Detail.WaterDamageSublimit == null );
  }
}