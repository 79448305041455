import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/services/base-service.service';

import { RejectQuoteData } from '../model/reject-quote-data';
import { CustomResponse } from 'src/app/_shared/classes/CustomResponse';
import { RejectionQuoteReason } from '../model/rejection-quote-reason';

@Injectable({
  providedIn: 'root'
})
export class RejectQuoteService extends BaseService{

  constructor(private httpClient: HttpClient) { 
    super();
  }

  public getRejectionReasons(){
    return this.httpClient.get<CustomResponse<Array<RejectionQuoteReason>>>(this.buildXenithEndpoint(`/HighValueQuote/RejectionReasons`), this.GetAuthHeaderJson());
  }

  public rejectQuote(rejectQuoteData: RejectQuoteData){
    return this.httpClient.put<CustomResponse<boolean>>(this.buildXenithEndpoint(`/HighValueQuote/Submitted/Reject`), rejectQuoteData, this.GetAuthHeaderJson());
  }

}
