export class IIdentifier {
  AccessToken: string;
  Agency: string;
  AgentId: number;
  AgentLicenseExists: boolean;
  ExpirationDate: string;
  FullName: string;
  RefreshToken: string;
  Roles: string[];
  Permissions: string[];
  TempPassword: boolean;
  UserId: number;
  ClientInitiated: boolean;
  AgencyStatusId?: number;
  AgencyId: number;
  StateAccess: string[];
}
