import { IOption } from "../_shared/interfaces/IOption"



export class OptionsModel {
  Detail: IOption = {

  }

  Validation: any = {

  }
}
