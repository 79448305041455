import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FileUtilService } from 'src/app/services/file-util.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-drag-and-drop-fragment',
  templateUrl: './drag-and-drop-fragment.component.html',
  styleUrls: ['./drag-and-drop-fragment.component.scss']
})
export class DragAndDropFragmentComponent{

  @ViewChild("fileDropRef", { static: false }) fileDropEl: ElementRef;
  @Input() singleFileOnly: boolean = false;
  @Output() onFileChange = new EventEmitter<File[]>();

  files: any[] = [];
  
  constructor(
    private fileUtilService: FileUtilService,
    private notificationService: NotificationService){

  }

  onFileDropped(files: Array<any>) {
    this.handleFiles(files);
  }

  fileBrowseHandler(files) {
    this.handleFiles(files);
  }

  handleFiles(files: Array<any>){
    if(this.singleFileOnly && (files.length > 1 || this.files.length >= 1)){
      this.notificationService.showErrorMessage('Invalid amount of files', 'Only one file is permitted');
      return;
    }
    this.prepareFilesList(files);
  }

  deleteFile(index: number) {
    if (this.files[index].progress < 100) {
      return;
    }
    this.files.splice(index, 1);
    this.onFileChange.emit(this.files);
  }

  uploadFilesSimulator(index: number) {
    setTimeout(() => {
      if (index === this.files.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.files[index].progress === 100) {
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1);
          } else {
            this.files[index].progress += 5;
          }
        }, 10);
      }
    }, 200);
  }

  prepareFilesList(files: Array<any>) {
    for (const item of files) {
      item.progress = 0;
      this.files.push(item);
    }
    this.onFileChange.emit(this.files);
    this.fileDropEl.nativeElement.value = "";
    this.uploadFilesSimulator(0);
  }

  formatBytes(bytes, decimals = 2) {
    return this.fileUtilService.formatBytes(bytes, decimals);
  }

}
