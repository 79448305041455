import { Role } from "./Role";

export const AGENT_ROLE: string = 'High Value Agent';
export const UNDERWRITER_ROLE: string = 'High Value UnderWriter';
export const HOMEOWNERS_ROLE: string = 'Hiscox High Value Homeowners'; 

export const QUOTE_CREATE_PERMISSION: string = 'Quote.Create';
export const QUOTE_SEARCH_PERMISSION: string = 'Quote.Search';
export const QUOTE_VIEW_PERMISSION: string = 'Quote.View';
export const QUOTE_SUBMITTED_PERMISSION: string = 'Quote.Submitted';
export const POLICY_SEARCH_PERMISSION: string = 'Policy.Search';
export const REJECTED_QUOTES_PERMISSION: string = 'HVH.RejectedQuotes';
export const APPROVED_QUOTES_PERMISSION: string = 'HVH.ApprovedQuotes';
export const MODIFIED_QUOTE_PERMISSION: string = 'HVH.ModifiedQuote';
export const PRODUCT_INFORMATION_PERMISSION: string = 'HVH.ProductInformation';


export const AGENT_PERMISSIONS: string[] = [
    QUOTE_CREATE_PERMISSION, 
    QUOTE_SEARCH_PERMISSION, 
    QUOTE_VIEW_PERMISSION, 
    POLICY_SEARCH_PERMISSION, 
    REJECTED_QUOTES_PERMISSION, 
    APPROVED_QUOTES_PERMISSION, 
    MODIFIED_QUOTE_PERMISSION, 
    PRODUCT_INFORMATION_PERMISSION
];

export const UNDERWRITER_PERMISSIONS: string[] =  [
    QUOTE_SEARCH_PERMISSION, 
    QUOTE_VIEW_PERMISSION, 
    QUOTE_SUBMITTED_PERMISSION,
    POLICY_SEARCH_PERMISSION, 
    APPROVED_QUOTES_PERMISSION, 
    MODIFIED_QUOTE_PERMISSION, 
    PRODUCT_INFORMATION_PERMISSION
];

export const HOMEOWNERS_PERMISSIONS: string[] = []; 

export const AGENT_ROLE_GROUP: Role = {
    Name: AGENT_ROLE,
    Permissions: AGENT_PERMISSIONS
} as Role;

export const UNDERWRITER_ROLE_GROUP: Role = {
    Name: UNDERWRITER_ROLE,
    Permissions: UNDERWRITER_PERMISSIONS
} as Role;

export const HOMEOWNERS_ROLE_GROUP: Role = {
    Name: HOMEOWNERS_ROLE,
    Permissions: HOMEOWNERS_PERMISSIONS
} as Role;