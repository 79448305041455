import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NotificationModalComponent } from '../components/modals/notification-modal/notification-modal.component';
import { INotificationData, INotificationOptions } from '../_shared/interfaces/notifications/INotificationData';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(public dialog: MatDialog) { }

  public showSuccessMessage(title: string, message: string, options?: INotificationOptions){
    return this.showMessage(title, message, 'success', options);
  }

  public showErrorMessage(title: string, message: string, options?: INotificationOptions){
    return this.showMessage(title, message, 'error', options);
  }

  public showInformationMessage(title: string, message: string, options?: INotificationOptions){
    return this.showMessage(title, message, 'information', options);
  }

  public showWarningMessage(title: string, message: string, options?: INotificationOptions){
    return this.showMessage(title, message, 'warning', options);
  }

  public showQuestionMessage(title: string, question: string, options?: INotificationOptions){
    var questionOptions = {
      ConfirmButtonText: options?.ConfirmButtonText ?? 'Confirm',
      CancelButtonText: options?.CancelButtonText ?? 'Cancel',
      Questionaire: true,
      HTMLContent: options?.HTMLContent ?? false
    } as INotificationOptions;

    return this.showMessage(title, question, 'question', questionOptions);
  }

  public showMessage(title:string, message:string, icon:string, options?: INotificationOptions){
    return this.dialog.open<NotificationModalComponent, INotificationData>(NotificationModalComponent, { 
      data:
      { 
        Message: message, 
        Title: title, 
        Icon: icon,
        Options: options
      } as INotificationData
    });
  }
}
