import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InsuredInfoComponent } from './insured-info/insured-info.component';
import { RiskAddressComponent } from './risk-address/risk-address.component';
import { PropertyDetailComponent } from './property-detail/property-detail.component';
import { CreditsComponent } from './credits/credits.component';
import { CoverageComponent } from './coverage/coverage.component';
import { PersonalCoverageComponent } from './personal-coverage/personal-coverage.component';
import { LossHistoryComponent } from './loss-history/loss-history.component';
import { CustomizeComponent } from './customize/customize.component';
import { EndComponent } from './end/end.component';
import { UploadDocumentsComponent } from './components/upload-documents/upload-documents.component';
import { OptionsComponent } from './options/options.component';
import { AuthGuard } from './services/auth.guard';
import { UploadDocumentResolver } from './components/upload-documents/resolver/upload-document.resolver';
import { RoleGroup } from './user/models/RoleGroup';
import { ForbiddenComponent } from './forbidden/forbidden.component';
import { AGENT_ROLE, HOMEOWNERS_PERMISSIONS, HOMEOWNERS_ROLE, MODIFIED_QUOTE_PERMISSION, QUOTE_CREATE_PERMISSION, QUOTE_SUBMITTED_PERMISSION, QUOTE_VIEW_PERMISSION, UNDERWRITER_ROLE } from './user/models/RolesAndPermissions';
import { PaymentComponent } from './payment/payment.component';
import { UnderwriterModificationComponent } from './underwriter-modification/underwriter-modification.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'address',
    pathMatch: 'full'
  },
  {
    path: 'address',
    component: RiskAddressComponent,
    canActivate: [AuthGuard],
    data: [
      {
          Roles: [
              { 
                Name: AGENT_ROLE, 
                Permissions: [
                  QUOTE_CREATE_PERMISSION, 
                  QUOTE_VIEW_PERMISSION, 
                  MODIFIED_QUOTE_PERMISSION
                ]
              },
              { 
                Name: UNDERWRITER_ROLE, 
                Permissions: [
                  QUOTE_VIEW_PERMISSION, 
                  QUOTE_SUBMITTED_PERMISSION
                ] 
              },
              { Name: HOMEOWNERS_ROLE, Permissions: [] }
          ]
      } as RoleGroup
    ]
  },
  {
    path: 'property-detail',
    component: PropertyDetailComponent,
    canActivate: [AuthGuard],
    data: [
      {
          Roles: [
              { 
                Name: AGENT_ROLE, 
                Permissions: [
                  QUOTE_CREATE_PERMISSION, 
                  QUOTE_VIEW_PERMISSION, 
                  MODIFIED_QUOTE_PERMISSION
                ]
              },
              { 
                Name: UNDERWRITER_ROLE, 
                Permissions: [
                  QUOTE_VIEW_PERMISSION, 
                  QUOTE_SUBMITTED_PERMISSION
                ] 
              },
              { Name: HOMEOWNERS_ROLE, Permissions: [] }
          ]
      } as RoleGroup
    ]
  },
  {
    path: 'insured-info',
    component: InsuredInfoComponent,
    canActivate: [AuthGuard],
    data: [
      {
          Roles: [
              { 
                Name: AGENT_ROLE, 
                Permissions: [
                  QUOTE_CREATE_PERMISSION, 
                  QUOTE_VIEW_PERMISSION, 
                  MODIFIED_QUOTE_PERMISSION
                ]
              },
              { 
                Name: UNDERWRITER_ROLE, 
                Permissions: [
                  QUOTE_VIEW_PERMISSION, 
                  QUOTE_SUBMITTED_PERMISSION
                ] 
              },
              { Name: HOMEOWNERS_ROLE, Permissions: [] }
          ]
      } as RoleGroup
    ]
  },
  {
    path: 'credits',
    component: CreditsComponent,
    canActivate: [AuthGuard],
    data: [
      {
          Roles: [
              { 
                Name: AGENT_ROLE, 
                Permissions: [
                  QUOTE_CREATE_PERMISSION, 
                  QUOTE_VIEW_PERMISSION, 
                  MODIFIED_QUOTE_PERMISSION
                ]
              },
              { 
                Name: UNDERWRITER_ROLE, 
                Permissions: [
                  QUOTE_VIEW_PERMISSION, 
                  QUOTE_SUBMITTED_PERMISSION
                ] 
              },
              { Name: HOMEOWNERS_ROLE, Permissions: [] }
          ]
      } as RoleGroup
    ]
  },
  {
    path: 'coverage',
    component: CoverageComponent,
    canActivate: [AuthGuard],
    data: [
      {
          Roles: [
              { 
                Name: AGENT_ROLE, 
                Permissions: [
                  QUOTE_CREATE_PERMISSION, 
                  QUOTE_VIEW_PERMISSION, 
                  MODIFIED_QUOTE_PERMISSION
                ]
              },
              { 
                Name: UNDERWRITER_ROLE, 
                Permissions: [
                  QUOTE_VIEW_PERMISSION, 
                  QUOTE_SUBMITTED_PERMISSION
                ] 
              },
              { Name: HOMEOWNERS_ROLE, Permissions: [] }
          ]
      } as RoleGroup
    ]
  },
  {
    path: 'personal-coverage',
    component: PersonalCoverageComponent,
    canActivate: [AuthGuard],
    data: [
      {
          Roles: [
              { 
                Name: AGENT_ROLE, 
                Permissions: [
                  QUOTE_CREATE_PERMISSION, 
                  QUOTE_VIEW_PERMISSION, 
                  MODIFIED_QUOTE_PERMISSION
                ]
              },
              { 
                Name: UNDERWRITER_ROLE, 
                Permissions: [
                  QUOTE_VIEW_PERMISSION, 
                  QUOTE_SUBMITTED_PERMISSION
                ] 
              },
              { Name: HOMEOWNERS_ROLE, Permissions: [] }
          ]
      } as RoleGroup
    ]
  },
  {
    path: 'loss-history',
    component: LossHistoryComponent,
    canActivate: [AuthGuard],
    data: [
      {
          Roles: [
              { 
                Name: AGENT_ROLE, 
                Permissions: [
                  QUOTE_CREATE_PERMISSION, 
                  QUOTE_VIEW_PERMISSION, 
                  MODIFIED_QUOTE_PERMISSION
                ]
              },
              { 
                Name: UNDERWRITER_ROLE, 
                Permissions: [
                  QUOTE_VIEW_PERMISSION, 
                  QUOTE_SUBMITTED_PERMISSION
                ] 
              },
              { Name: HOMEOWNERS_ROLE, Permissions: [] }
          ]
      } as RoleGroup
    ]
  },
  {
    path: 'customize',
    component: CustomizeComponent,
    canActivate: [AuthGuard],
    data: [
      {
          Roles: [
              { 
                Name: AGENT_ROLE, 
                Permissions: [
                  QUOTE_CREATE_PERMISSION, 
                  QUOTE_VIEW_PERMISSION, 
                  MODIFIED_QUOTE_PERMISSION
                ]
              },
              { 
                Name: UNDERWRITER_ROLE, 
                Permissions: [
                  QUOTE_VIEW_PERMISSION, 
                  QUOTE_SUBMITTED_PERMISSION
                ] 
              },
              { Name: HOMEOWNERS_ROLE, Permissions: [] }
          ]
      } as RoleGroup
    ]
  },
  {
    path: 'end',
    component: EndComponent,
    canActivate: [AuthGuard],
    data: [
      {
          Roles: [
              { 
                Name: AGENT_ROLE, 
                Permissions: [
                  QUOTE_CREATE_PERMISSION, 
                  QUOTE_VIEW_PERMISSION, 
                  MODIFIED_QUOTE_PERMISSION
                ]
              },
              { 
                Name: UNDERWRITER_ROLE, 
                Permissions: [
                  QUOTE_VIEW_PERMISSION, 
                  QUOTE_SUBMITTED_PERMISSION
                ] 
              },
              { Name: HOMEOWNERS_ROLE, Permissions: [] }
          ]
      } as RoleGroup
    ]
  },
  {
    path: 'upload-documents',
    component: UploadDocumentsComponent,
    canActivate: [AuthGuard],
    resolve: { UploadDocumentResolver },
    data: [
      {
          Roles: [
              { 
                Name: AGENT_ROLE, 
                Permissions: [
                  QUOTE_CREATE_PERMISSION, 
                  QUOTE_VIEW_PERMISSION, 
                  MODIFIED_QUOTE_PERMISSION
                ]
              },
              { 
                Name: UNDERWRITER_ROLE, 
                Permissions: [
                  QUOTE_VIEW_PERMISSION, 
                  QUOTE_SUBMITTED_PERMISSION
                ] 
              },
              { Name: HOMEOWNERS_ROLE, Permissions: [] }
          ]
      } as RoleGroup
    ]
  },
  {
    path: 'user',
    loadChildren: () => import('./user/module/user.module').then(m => m.UserModule)
  },
  {
    path: 'options',
    component: OptionsComponent,
    canActivate: [AuthGuard],
    data: [
      {
          Roles: [
              { 
                Name: AGENT_ROLE, 
                Permissions: [
                  QUOTE_CREATE_PERMISSION, 
                  QUOTE_VIEW_PERMISSION, 
                  MODIFIED_QUOTE_PERMISSION
                ]
              },
              { 
                Name: UNDERWRITER_ROLE, 
                Permissions: [
                  QUOTE_VIEW_PERMISSION, 
                  QUOTE_SUBMITTED_PERMISSION
                ] 
              },
              { Name: HOMEOWNERS_ROLE, Permissions: [] }
          ]
      } as RoleGroup
    ]
  },
  {
    path: 'payment',
    component: PaymentComponent,
    canActivate: [AuthGuard],
    data: [
      {
          Roles: [
              { 
                Name: AGENT_ROLE, 
                Permissions: [
                  QUOTE_CREATE_PERMISSION, 
                  QUOTE_VIEW_PERMISSION, 
                  MODIFIED_QUOTE_PERMISSION
                ]
              },
              { 
                Name: UNDERWRITER_ROLE, 
                Permissions: [
                  QUOTE_VIEW_PERMISSION, 
                  QUOTE_SUBMITTED_PERMISSION
                ] 
              },
              { Name: HOMEOWNERS_ROLE, Permissions: [] }
          ]
      } as RoleGroup
    ]
  },
  {
    path: 'underwriter-modifications',
    component: UnderwriterModificationComponent,
    canActivate: [AuthGuard],
    data: [
      {
          Roles: [
              { 
                Name: AGENT_ROLE, 
                Permissions: [
                  QUOTE_CREATE_PERMISSION, 
                  QUOTE_VIEW_PERMISSION, 
                  MODIFIED_QUOTE_PERMISSION
                ]
              },
              { 
                Name: UNDERWRITER_ROLE, 
                Permissions: [
                  QUOTE_VIEW_PERMISSION, 
                  QUOTE_SUBMITTED_PERMISSION
                ] 
              },
              { Name: HOMEOWNERS_ROLE, Permissions: [] }
          ]
      } as RoleGroup
    ]
  },
  {
    path: 'forbidden',
    component: ForbiddenComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
