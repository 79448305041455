import { Injectable } from '@angular/core';
import { BaseService } from '../base-service.service';
import { IAddress } from 'src/app/_shared/interfaces/IAddress';
import { HttpClient } from '@angular/common/http';
import { IVeriskPPCResult } from 'src/app/_shared/interfaces/verisk/IVeriskPPCResult';
import { Observable } from 'rxjs';
import { CustomResponse } from 'src/app/_shared/classes/CustomResponse';
import { CalculateValuationRequest } from 'src/app/_shared/interfaces/verisk/valuation/ICalculateValuationRequest';
import { CalculateValuationResult } from 'src/app/_shared/interfaces/verisk/valuation/ICalculateValuationResult';
import { ValuationInformation } from 'src/app/_shared/interfaces/verisk/valuation/IValuationInformation';
import { ValuationPopulatedCredit } from 'src/app/_shared/interfaces/verisk/valuation/IValuationPopulatedCredit';
import { Valuation } from 'src/app/_shared/interfaces/verisk/valuation/IValuation';
import { ValuationAnswer } from 'src/app/_shared/interfaces/verisk/valuation/IValuationAnswers';

@Injectable({
  providedIn: 'root'
})
export class VeriskService extends BaseService{

  constructor(private httpClient: HttpClient) { 
    super();
  }

  public ratePPC(address: IAddress, riskAddressId: number) : Observable<CustomResponse<IVeriskPPCResult>>{
    const model = {
      RiskAddress: address,
      RiskAddressId: riskAddressId
    };

    return this.httpClient.post<CustomResponse<IVeriskPPCResult>>(this.buildXenithEndpoint("/HighValueVerisk/PPC/Rate"), model, this.GetAuthHeaderJson());
  }

  public updateFireHydrant(ppcValueId: number, distanceToHydrant: number) : Observable<CustomResponse<IVeriskPPCResult>>{
    const model = {
      PPCId: ppcValueId,
      DistanceToFireHydrant: distanceToHydrant
    };

    return this.httpClient.put<CustomResponse<IVeriskPPCResult>>(this.buildXenithEndpoint("/HighValueVerisk/PPC/DistanceToFireHydrant"), model, this.GetAuthHeaderJson());
  }

  public calculateValuation(request: CalculateValuationRequest) : Observable<CustomResponse<CalculateValuationResult>>{
    return this.httpClient.post<CustomResponse<CalculateValuationResult>>(this.buildXenithEndpoint("/HighValueVerisk/Valuation/Calculate"), request, this.GetAuthHeaderJson());
  }

  public getInformationByValuationId(valuationId: number) : Observable<CustomResponse<ValuationInformation>>{
    return this.httpClient.get<CustomResponse<ValuationInformation>>(this.buildXenithEndpoint(`/HighValueVerisk/Valuation/Information/${valuationId}`), this.GetAuthHeaderJson());
  }

  public getPopulatedCreditByValuationId(valuationId: number) : Observable<CustomResponse<ValuationPopulatedCredit>>{
    return this.httpClient.get<CustomResponse<ValuationPopulatedCredit>>(this.buildXenithEndpoint(`/HighValueVerisk/Valuation/PopulatedCredit/${valuationId}`), this.GetAuthHeaderJson());
  }

  public getValuationByFullAddress(fullAddress: string) : Observable<CustomResponse<Valuation>>{
    return this.httpClient.get<CustomResponse<Valuation>>(this.buildXenithEndpoint(`/HighValueVerisk/Valuation/Address/${fullAddress}`), this.GetAuthHeaderJson());
  }

  public getValuationAnswersById(valuationId: number) : Observable<CustomResponse<Array<ValuationAnswer>>>{
    return this.httpClient.get<CustomResponse<Array<ValuationAnswer>>>(this.buildXenithEndpoint(`/HighValueVerisk/Valuation/Answers/${valuationId}`), this.GetAuthHeaderJson());
  }
}
