import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { CounterComponent } from './counter/counter.component';
import { FetchDataComponent } from './fetch-data/fetch-data.component';
import { NavMenuComponent } from './_shared/templates/nav-menu/nav-menu.component';
import { LayoutModule, BreakpointObserver, MediaMatcher } from '@angular/cdk/layout';
import { Platform } from '@angular/cdk/platform';
import { Title } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import { NgbDateAdapter, NgbDateNativeAdapter, NgbModule,NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { InsuredInfoComponent } from './insured-info/insured-info.component';
import { RiskAddressComponent } from './risk-address/risk-address.component';
import { PropertyDetailComponent } from './property-detail/property-detail.component';
import { AppRoutingModule } from './app-routing.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CreditsComponent } from './credits/credits.component';
import { CoverageComponent } from './coverage/coverage.component';
import { PersonalCoverageComponent } from './personal-coverage/personal-coverage.component';
import { LossHistoryComponent } from './loss-history/loss-history.component';
import { CustomizeComponent } from './customize/customize.component';
import { EndComponent } from './end/end.component';
import { UploadDocumentsComponent } from './components/upload-documents/upload-documents.component';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ProgressComponent } from './progress/progress.component';
import { DragAndDropDirective } from './directives/drag-and-drop.directive';
import { DragAndDropFragmentComponent } from './components/shared/drag-and-drop-fragment/drag-and-drop-fragment.component';
import { QuoteDocumentUploadModalComponent } from './components/modals/quote-document-upload-modal/quote-document-upload-modal.component';
import { NotificationModalComponent } from './components/modals/notification-modal/notification-modal.component';
import { OptionsComponent } from './options/options.component';
import { RejectQuoteComponent } from './components/modals/reject-quote/reject-quote.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
import {MatSliderModule } from '@angular/material/slider';
import { ForbiddenComponent } from './forbidden/forbidden.component';
import { MainMenuComponent } from './main-menu/main-menu.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIcon, MatIconModule} from '@angular/material/icon';
import { UserService } from './user/services/user.service';
import { NgbDateCustomParserFormatter } from './components/NgbDateCustomParserFormatter';
import { PaymentComponent } from './payment/payment.component';
import { NgxMaskModule } from 'ngx-mask';
import {SliderModule} from 'primeng/slider';
import { UnderwriterModificationComponent } from './underwriter-modification/underwriter-modification.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    CounterComponent,
    FetchDataComponent,
    NavMenuComponent,
    InsuredInfoComponent,
    RiskAddressComponent,
    PropertyDetailComponent,
    CreditsComponent,
    CoverageComponent,
    PersonalCoverageComponent,
    LossHistoryComponent,
    CustomizeComponent,
    EndComponent,
    UploadDocumentsComponent,
    ProgressComponent,
    DragAndDropDirective,
    DragAndDropFragmentComponent,
    QuoteDocumentUploadModalComponent,
    NotificationModalComponent,
    EndComponent,
    PaymentComponent,
    OptionsComponent,
    RejectQuoteComponent,
    ForbiddenComponent,
    MainMenuComponent,
    UnderwriterModificationComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    LayoutModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    FlexLayoutModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatTableModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    FormsModule,
    MatSelectModule,
    MatInputModule,
    MatToolbarModule,
    MatIconModule,
    BrowserAnimationsModule, 
    RouterModule,
    NgxMaskModule.forRoot(),
    MatSliderModule,
    SliderModule
  ],
  providers: [
    BreakpointObserver,
    MediaMatcher,
    Platform,
    Title,
    DatePipe,   
    { provide: NgbDateParserFormatter, useFactory: () => { return new NgbDateCustomParserFormatter() } },    
    UserService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
