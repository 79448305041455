import { Injectable } from '@angular/core';
import { Inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { IQuote } from '../_shared/interfaces/IQuote';
import { IQuoteUpdate } from '../_shared/interfaces/IQuoteUpdate';
import { SessionStorageUtils } from '../util/session-storage';
import { IUser } from '../user/models/IUser';
import { CustomResponse } from '../_shared/classes/CustomResponse';
import { BaseService } from '../services/base-service.service';

@Injectable({
  providedIn: 'root',
})
export class QuoteService extends BaseService{

  fortunaApiBaseUrl;
  private url: string = "api/quote/";

  constructor(private http: HttpClient, @Inject('FORTUNA_BASE_URL') fortunaApiBaseUrl: string) {
    super();
    this.fortunaApiBaseUrl = fortunaApiBaseUrl;
  }

  update(quote: IQuoteUpdate) {
    quote.ModifiedByUserId = this.user.Id ?? 1;
    return this.http.put<CustomResponse<any>>(this.buildXenithEndpoint("/HighValueQuote"), quote, this.GetAuthHeaderJson());
  }

  create(quote: IQuote) {
    quote.CreatedByUserId = this.user.Id ?? 1;
    return this.http.post<CustomResponse<any>>(this.buildXenithEndpoint("/HighValueQuote"), quote, this.GetAuthHeaderJson());
  }

  rate(id: number){
    return this.http.get<CustomResponse<any>>(this.fortunaApiBaseUrl + this.url + "rate/" + id);
  }

  get(id: number) {
    return this.http.get<CustomResponse<any>>(this.buildXenithEndpoint(`/HighValueQuote/${id}`), this.GetAuthHeaderJson());
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.log("error in service");
      console.error(error); 
      return of(result as T);
    };
  }
}
