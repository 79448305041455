import { Component, OnInit } from '@angular/core';
import { IsUserLogged } from 'src/main';
import { SessionStorageUtils } from '../util/session-storage';
import { IUser } from '../user/models/IUser';
import { UserService } from '../user/services/user.service';
import { NotificationService } from '../services/notification.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.css']
})
export class MainMenuComponent implements OnInit {

  constructor(private userService: UserService,
    private notificaitonService: NotificationService,
    private router: Router) {
    
  }

  ngOnInit(): void {
  }

  logoutUser(){
    this.notificaitonService.showQuestionMessage("Logout", "Are you sure you want to logout?")
        .afterClosed()
        .subscribe((result) => {
          if(result){
            this.userService.logout();
          }
        });    
  }

  redirectToHome(){ 

    var n = sessionStorage.length;
while(n--) {
  let key = sessionStorage.key(n);
  // if(/foo/.test(key)) {
    if(key!="identifier")
    {
      if(key!="fortuna.token")
        if(key!='fortuna.user')
        {        
          sessionStorage.removeItem(key);
        }

    }    
    } 
    var urladd=window.location.href.split('/')[3];
    if(urladd=='address')    
        window.location.reload();
    this.router.navigate(['']);
   
    
    
  }

  get user() : IUser{
    return SessionStorageUtils.getUser();
  }

  get isUserLogged(){
    return IsUserLogged();
  }
}
