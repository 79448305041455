import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { QuoteDocumentService } from '../service/quote-document.service';
import { CustomResponse } from 'src/app/_shared/classes/CustomResponse';
import { IQuoteDocument } from '../model/IQuoteDocument';

@Injectable({
  providedIn: 'root'
})
export class UploadDocumentResolver implements Resolve<CustomResponse<IQuoteDocument[]>> {
  
  constructor(private quoteDocumentService: QuoteDocumentService){}

  resolve(route: ActivatedRouteSnapshot): Observable<CustomResponse<IQuoteDocument[]>> {
    if(!route.queryParamMap.has('quoteId')){
      return of({
        Success: false,
        Data: [],
        Notifications: ["Quote Id was not provided"] 
      }as CustomResponse<IQuoteDocument[]>)
    }

    const quoteId = route.queryParams['quoteId'] as number;
    
    return this.quoteDocumentService.getDocuments(quoteId).pipe(map(response => response), catchError(error => {
      return of({
        Success: false,
        Data: [],
        Notifications: [`An error occcured getting the documents for this Quote`] 
      }as CustomResponse<IQuoteDocument[]>)
    }));
  }

}
