import { Injectable } from '@angular/core';
import { Inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { IAddress } from "../_shared/interfaces/IAddress";
import { CustomResponse } from '../_shared/classes/CustomResponse';

@Injectable({
  providedIn: 'root',
})
export class HomeService {

  arcApiBaseUrl;
  fortunaApiBaseUrl;
  programId;

  //constructor(private http: HttpClient, @Inject('ARC_API_URL') arcApiBaseUrl: string, @Inject('PROGRAM_ID') programId: string) 
  constructor(private http: HttpClient, @Inject('FORTUNA_BASE_URL') fortunaApiBaseUrl: string,@Inject('ARC_API_URL') arcApiBaseUrl: string)
  {
    /*;
    this.programId = programId;*/
    this.arcApiBaseUrl = arcApiBaseUrl;
    this.fortunaApiBaseUrl=fortunaApiBaseUrl;
  }

  printBaseUrl() {
    console.log(this.arcApiBaseUrl);
  }

  geocode(address: string): Observable<CustomResponse<any>> {
    return this.http.get<CustomResponse<any>>(this.fortunaApiBaseUrl + '/api/google/geocode?address=' + address);
  }

  getQuoteCount(): Observable<any> {
    return this.http.get<number>(this.arcApiBaseUrl + "/api/quote/count");
  }

  validateAddress(address: IAddress): Observable<any> {
    return this.http.post(this.arcApiBaseUrl + "/api/address/validate", address);
  }

  createAddress(address: IAddress): Observable<CustomResponse<any>> {
    return this.http.post<CustomResponse<any>>(this.fortunaApiBaseUrl + "/api/address", address);
  }

  swissReRate(riskAddressId: number): Observable<any> {
    return this.http.post(this.arcApiBaseUrl + "/api/SwissRe/Rate", riskAddressId);
  }

  updateEffectiveDate(preQuoteId: number, effectiveDate: any): Observable<any> {
    const model = {
      PreQuoteId: preQuoteId,
      EffectiveDate: effectiveDate
    };

    return this.http.post(this.arcApiBaseUrl + "/api/quote/UpdateEffDate", model);
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.log("error in service");
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}

