import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { GoogleService } from './google.service';
import Swal from 'sweetalert2'
import { RiskAddressData, RiskAddressInput, RiskVisible } from './riskAddress.class';
import { IAddress } from '../_shared/interfaces/IAddress';
import { StateService } from '../_helpers/state.service';
import { RiskAddressService } from './risk-address.service';
import { MoratoriumService } from '../_helpers/moratorium.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Validation } from '../_shared/classes/Validation';
import { UserService } from '../user/services/user.service';
import { FormatService } from "../_helpers/format.service";
import { NgbDateCustomParserFormatter } from '../components/NgbDateCustomParserFormatter';
import { VeriskService } from '../services/verisk/verisk.service';
import { ValidateService } from '../_helpers/validate.service';
import { SessionStorageUtils } from '../util/session-storage';
import { FormBaseComponent } from '../components/shared/base-components/form-base.component';
import { NgForm } from '@angular/forms';
import { IMoratorium } from '../_shared/interfaces/IMoratorium';


@Component({
  selector: 'app-risk-address',
  templateUrl: './risk-address.component.html',
  styleUrls: ['./risk-address.component.css']
})
export class RiskAddressComponent extends FormBaseComponent implements OnInit, AfterViewInit {

  @ViewChildren('Underwriter') inputRef : ElementRef[];
  input = new RiskAddressInput;
  data = new RiskAddressData;
  visible = new RiskVisible;
  @ViewChild('propertyNotFoundModal', {}) propertyNotFoundModal: ElementRef;
  propertyNotFoundModalReference: any;
  @ViewChild('addresstext', { static: false }) addresstext: any;
  validation: Validation = JSON.parse(sessionStorage.getItem('validation')) ?? new Validation();
  effDateSet: boolean = false;
  addressFound: boolean = false;
  @ViewChild('propNotFoundForm') propNotFoundForm: NgForm;

  isManuallyFireHydrantDistanceRequired : boolean = false;

  constructor (
    private router: Router,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    private modalService: NgbModal,
    private titleService: Title,
    private googleService: GoogleService,
    private stateService: StateService,
    private riskAddressService: RiskAddressService,
    private moratoriumService: MoratoriumService,
    private userService: UserService,
    private formatService: FormatService,
    private ngbDateCustomParserFormatter:NgbDateCustomParserFormatter,
    private veriskService: VeriskService,
    private validateService: ValidateService
  ) 
  { 
    super()
  }

  ngOnInit(): void {
    this.titleService.setTitle("Hiscox High Value - Risk Address");
    this.getStates();
    this.getCounties();
    this.getInactiveCounties();
    this.userService.refreshToken();
    //sessionStorage.clear();
    this.route.params.subscribe((params) => {
      if(params['quoteId'] != null && params['quoteId'] != undefined && params['quoteId'] != '0')
          sessionStorage.setItem('quoteId', params['quoteId']);
    })
    this.mouseHandlers();
  }

  ngAfterViewInit() {
    const autocomplete = new google.maps.places.Autocomplete(this.addresstext.nativeElement,
      {
        componentRestrictions: { country: 'US' },
        types: ["geocode"]  // 'establishment' / 'address' / 'geocode'
      });
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      this.input.AutoCompletePlace = autocomplete.getPlace();
    });
    super.configureCheckForChange(this.inputRef);
  }

  setEffDate() {
    //console.log(this.input.EffectiveDate)
     sessionStorage.setItem("effectiveDate",this.ngbDateCustomParserFormatter.format(this.input.EffectiveDate));
    this.effDateSet = true;
  }

  getStates() {
    this.stateService.quotableStates().subscribe(items => {
      ////console.log(items)
      if (items.Success) {
        this.data.QuoteableStates = items.Data;
        //console.log(this.data.QuoteableStates);
      }
      
    })
  }

  getCounties() {
    this.moratoriumService.getCounties().subscribe(items => {
      
      if (items.Success) {
        this.data.Counties = items.Data;
      }
      
    })
  }

  getInactiveCounties() {
    this.moratoriumService.getInactiveCounties().subscribe(items => {
      
      if (items.Success) {
        this.data.InactiveCounties = items.Data as IMoratorium[];
      }
      
    })
  }

  get isRiskAddressValid(){
    return this.effDateSet && this.addressFound && !this.isManuallyFireHydrantDistanceRequired;
  }

  redirectToInsuredInfo(){
    if(this.isRiskAddressValid){
      this.router.navigate(['insured-info']);
    }
  }

  findAddress() {
    let addressAsString: string;

    if (this.input.AutoCompletePlace !== undefined &&
     this.input.AutoCompletePlace !== null &&
     this.input.AutoCompletePlace.address_components !== undefined) {
     addressAsString = this.getComponentShortName(this.input.AutoCompletePlace.address_components, "street_number") +
       " " +
       this.getComponentShortName(this.input.AutoCompletePlace.address_components, "route") +
       " " +
       this.getComponentCityName(this.input.AutoCompletePlace.address_components, true) +
       " " +
       this.getComponentShortName(this.input.AutoCompletePlace.address_components, "administrative_area_level_1") +
       " " +
       this.getComponentShortName(this.input.AutoCompletePlace.address_components, "postal_code");
    } else {
     addressAsString = this.input.RawAddressInput;
    }

    this.googleService.geocode(addressAsString).subscribe(geocodeResult => {


      if (geocodeResult.Success == false || geocodeResult.Data == null || geocodeResult.Data.status != "OK" || geocodeResult.Data.results[0].address_components.length < 8) {
       this.showPropertyNotFound();
       this.visible.Spinner = false;
       return;
     }

      this.stateService.stateByName(this.getComponentLongName(geocodeResult.Data.results[0].address_components, "administrative_area_level_1")).subscribe(item => {
        if (item.Success) {
          const address: IAddress = {
            Address1: this.getComponentLongName(geocodeResult.Data.results[0].address_components, "street_number") + " " + this.getComponentLongName(geocodeResult.Data.results[0].address_components, "route"),
            Address2: this.getComponentLongName(geocodeResult.Data.results[0].address_components, "subpremise"),
            City: this.getComponentCityName(geocodeResult.Data.results[0].address_components),
            State: item.Data,
            Zip: this.getComponentLongName(geocodeResult.Data.results[0].address_components, "postal_code"),
            County: this.getComponentLongName(geocodeResult.Data.results[0].address_components, "administrative_area_level_2"),
            Longitude: geocodeResult.Data.results[0].geometry.location.lng,
            Latitude: geocodeResult.Data.results[0].geometry.location.lat,
            Elevation: geocodeResult.Data.elevation
          };

          this.riskAddressService.validateAddress(address).subscribe(validateResult => {
            let resultData = validateResult.Data;
            if (resultData == null || address.County === undefined || address.County === '') {
              this.visible.Spinner = false;
              this.showPropertyNotFound();
            } else {
              if (resultData.DefaultAddress === false) {
                this.data.SessionModel.riskData = address;
                this.createAddressAndSubmit(address, true);
              } else {
                this.visible.Spinner = false;
                this.showPropertyNotFound();
              }
            }
          }, error => {
            this.visible.Spinner = false;
            this.visible.EffectiveDate = false;
            if (error.status !== 401) {
              Swal.fire({
                icon: 'error',
                title: 'Something went wrong',
                text: 'Please try again later'
              });
            }

          });
        }
      });
    }, error => {
     this.visible.Spinner = false;
     this.visible.EffectiveDate = false;
     if (error.status !== 401) {
       Swal.fire({
         icon: 'error',
         title: 'Something went wrong',
         text: 'Please try again later'
       });
     }
    });
    
  }

  getComponentShortName(components, typeName: string) {
    return (components.filter(component => component.types[0] == typeName)[0])
      ? (components.filter(component => component.types[0] == typeName)[0]).short_name
      : "";
  }

  getComponentLongName(components, typeName: string) {
    return (components.filter(component => component.types[0] == typeName)[0])
      ? (components.filter(component => component.types[0] == typeName)[0]).long_name
      : "";
  }

  getComponentCityName(components, isShortName = false) {
    let component = null;

    if (components.filter(component => component.types[0] == 'locality')[0]) {
      component = components.filter(component => component.types[0] == 'locality')[0];
    }
    else if (components.filter(component => component.types[0] == 'neighborhood')[0]) {
      component = components.filter(component => component.types[0] == 'neighborhood')[0];
    }

    if (component == null) {
      return "";
    }

    return isShortName ? component.short_name : component.long_name;
  }

  showPropertyNotFound() {
    this.propertyNotFoundModalReference = this.modalService.open(this.propertyNotFoundModal, { ariaLabelledBy: 'modal-basic-title', size: 'xl', backdrop: false });
    this.visible.Spinner = false;
  }

  validateAddress(address: IAddress){
    this.riskAddressService.validateAddress(address).subscribe(validateResult => {
      let resultData = validateResult.Data;
      if (resultData == null || address.County === undefined || address.County === '') {
        this.visible.Spinner = false;
      } else {
        if (resultData.DefaultAddress === false) {
          this.data.SessionModel.riskData = address;
          this.createAddressAndSubmit(address, true);
        } else {
          this.visible.Spinner = false;
        }
      }
    }, error => {
      this.visible.Spinner = false;
      this.visible.EffectiveDate = false;
      if (error.status !== 401) {
        Swal.fire({
          icon: 'error',
          title: 'Something went wrong',
          text: 'Please try again later'
        });
      }

    });
  }

  createAddressAndSubmit(address: IAddress, flgAddressfound: boolean) {
    this.visible.Spinner = true;
    this.riskAddressService.createAddress(address).subscribe(createAddressResult => {
      let strippedCounty = address.County.replace('County', '').trim();
      let resultAddress = createAddressResult.Data;
      if (this.data.InactiveCounties == undefined || this.data.QuoteableStates == undefined) {
        //console.log("Inactive counties or quoteable states error"); //todo remove after using this to verify the issue is what we thought it was in dev
        this.visible.Spinner = false;
        Swal.fire({
          icon: 'error',
          title: 'Something went wrong',
          text: 'Please try again later'
        });
        return;
      }

      if (this.data.InactiveCounties.length > 0) {

        if (this.data.InactiveCounties.filter(x => x.Name.toLowerCase() === strippedCounty.toLowerCase()).length > 0) {
          this.visible.Spinner = false;
          let message = "You cannot bind in this county due to a Moratorium in effect.";
            Swal.fire({
              icon: 'error',
              title: 'Moratorium',
              text: message
            });

          return;
        }
      }

      if (this.data.QuoteableStates.filter(x => x.Id === address.State.Id).length === 0) {
        this.visible.Spinner = false;
        Swal.fire({
          icon: 'error',
          title: 'State Error',
          text: `Thank you for your interest! Hiscox Highvalue is not currently writing in ${address.State.Name}.`
        });
        return;
      }

      SessionStorageUtils.saveRiskAddressId(resultAddress.Id);
      SessionStorageUtils.saveAddress(address);

      this.validation.data.ZipCode = Number(address.Zip);
      
      this.veriskService.ratePPC(address, resultAddress.Id).subscribe(veriskPpcResult => {
        let ppcData = veriskPpcResult.Data;
        this.visible.Spinner = false;
        if (ppcData.Error == false) {
          this.addressFound = true;
          this.validation.data.Tier1Flg = ppcData.FlagTier1;
          this.validation.data.Tier2Flg = ppcData.FlagTier2;
          this.validation.data.Tier3Flg = ppcData.FlagTier3;
          this.validation.data.PPC = ppcData.PPC;
          this.validation.data.SplitPPC = ppcData.SplitPPC;
          this.validation.data.PPCId = ppcData.PPCId;
          this.visible.EffectiveDate = true;
          this.validateFireHydrantDistance();
          SessionStorageUtils.saveValidation(this.validation);

          if (this.propertyNotFoundModalReference !== undefined) {
            this.propertyNotFoundModalReference.close();
          }
        }
        else {
          this.visible.Spinner = false;
          Swal.fire({
            icon: 'error',
            title: 'PPC Error',
            text: ppcData.StatusMsg
          });
        }
      }, error => {
        this.visible.Spinner = false;
        Swal.fire({
          icon: 'error',
          title: 'Something went wrong',
          text: 'Please try again later'
        });
      });

    }, error => {
      this.visible.Spinner = false;
      Swal.fire({
        icon: 'error',
        title: 'Something went wrong',
        text: 'Please try again later'
      });
    });
    this.titleService.setTitle(address.Address1);
  }

  public updateFireHydrantDistance(){
    const validation = SessionStorageUtils.getValidation();

    if(!validation || !validation.data){
      return;
    }

    this.visible.Spinner = true;
    this.veriskService.updateFireHydrant(this.validation.data.PPCId, this.input.HydrantDistance).subscribe((updateDistanceResult) => {
      this.visible.Spinner = false;
      if(updateDistanceResult.Success && updateDistanceResult.Data.Error == false){
        validation.data.PPC = updateDistanceResult.Data.PPC;
        validation.data.PPCId = updateDistanceResult.Data.PPCId;
        validation.data.SplitPPC = updateDistanceResult.Data.SplitPPC;
        validation.data.Tier1Flg = updateDistanceResult.Data.FlagTier1;
        validation.data.Tier2Flg = updateDistanceResult.Data.FlagTier2;
        validation.data.Tier3Flg = updateDistanceResult.Data.FlagTier3;
        SessionStorageUtils.saveValidation(validation);
        this.visible.EffectiveDate = true;   
        this.isManuallyFireHydrantDistanceRequired = false;
      }
    },
    () => this.visible.Spinner = false);
   
  }

  private validateFireHydrantDistance(){
    const validation = SessionStorageUtils.getValidation();

    if(!validation || !validation.data){
      return;
    }
    else if(validation.data.SplitPPC){
      this.isManuallyFireHydrantDistanceRequired = validation.data.SplitPPC;
      this.visible.EffectiveDate
      return;
    }

    this.visible.EffectiveDate = true;    
  }
}
