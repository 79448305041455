import { Injectable } from '@angular/core';
import { Inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { IOption } from '../_shared/interfaces/IOption';
import { CustomResponse } from '../_shared/classes/CustomResponse';
import { IQuoteUpdate } from '../_shared/interfaces/IQuoteUpdate';

@Injectable({
  providedIn: 'root',
})
export class OptionsService {
  arcApiBaseUrl;
  fortunaApiBaseUrl;
  private url: string = "api/Options/";

  constructor(private http: HttpClient, @Inject('FORTUNA_BASE_URL') fortunaApiBaseUrl: string) {
    this.fortunaApiBaseUrl = fortunaApiBaseUrl;
  }

  get(id: number): Observable<CustomResponse<any>> {
    return this.http.get<CustomResponse<any>>(this.fortunaApiBaseUrl + this.url + id);
  }

  getByQuote(quote: number): Observable<CustomResponse<any>> {
    return this.http.get<CustomResponse<any>>(this.fortunaApiBaseUrl + this.url + "quote/" + quote);
  }

  sendEmail(data: any): any {
    return this.http.post(this.fortunaApiBaseUrl + this.url + 'email', data)
  }

  update(quote: IQuoteUpdate) {
    quote.ModifiedByUserId = 1
    return this.http.put<CustomResponse<any>>(this.fortunaApiBaseUrl + "api/Quote/", quote);
  }


  downloadPDF(data: any): any {
    const identifier = JSON.parse(sessionStorage.getItem('identifier'));
    data.AgentId = identifier.AgentId;
    return this.http.post(this.fortunaApiBaseUrl + this.url + 'pdf',data, { responseType: 'blob' })
      .subscribe((res: any) => {
        console.log(res)
        const blob = new Blob([res], { type: 'application/pdf' });

        // Crear un enlace para descargar
        const downloadURL = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadURL;
        link.download = sessionStorage.getItem('quoteNumber') + "-Options.pdf";
        link.click();
      });
  }

  save(item: IOption) {
    return this.http.post<CustomResponse<any>>(this.fortunaApiBaseUrl + this.url + "save", item);
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.log("error in service");
      // TODO: sOptions the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
