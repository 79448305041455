import { ICoverage } from "../_shared/interfaces/ICoverage"

export class CoverageModel {
  Detail: ICoverage = {
    Id: null,
    Dwelling: null,
    Structures: null,
    StructuresAmt: 0,
    PersonalProperty: null,
    PersonalPropertyAmt: 0,
    LossUse: null,
    LossAmt: 0,
    Liability: 500000,
    ExtendedLiability: null,
    ExtendedLiabilityAmt: 0,
    MedicalPaymentAmt: null,
    WindExclusion: null,
    WindDeductible: null,
    WindSideHwy: null,
    StormDeductible: null,
    AopDeductible: null,
    TivValue: 0
  }

  Validation: any = {

  }
}
