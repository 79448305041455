import { ICustomize } from "../_shared/interfaces/ICustomize"


export class CustomizeModel {
  Detail: ICustomize = {
    Id: null,
    ReplacemntCostFlg: true,
    SpecialLimitsCovCFlg: false,
    InflationGuard: 0.04,
    IncreasedOrdinanceFlg: false,
    IncreasedOrdinance: null,
    IncreasedLimitBusinessFlg: false,
    IncreasedLimitBusiness: null,
    WaterBackupFlg: false,
    WaterBackup: null,
    SupplementalLossFlg: false,
    SupplementalLoss: null,
    MoldCoverageFlg: false,
    MoldCoverage: null,
    AdditionalCoversFlg: false,
    AdditionalCovers: null,
    ExtendedReplacementCovAFlg: false,
    ExtendedReplacementCovA: null,
    LockReplacementFlg: false,
    LockReplacement: null,
    ServiceLineFlg: false,
    ServiceLine: null,
    AopDeductibleWaiverFlg: false,
    GolfCartLossFlg: false,
    GolfCartLoss: null,
    PollutionFlg: false,
    Pollution: null,
    MechanicalBreakdownFlg: false,
    MechanicalBreakdown: null,
    MortgageReplacementExpenseFlg: false,
    MortgageReplacementExpense: null,
    PersonalInjuryFlg: false,
    IdentityFraudFlg: false,
    IdentityFraud: null,
    PollutionLiabilityFlg: false,
    PollutionLiability: null,
    RecreationalMVLiabilityFlg: false,
    RecreationalMVLiability: null,
    HomeOfficeCoverageFlg: false,
    HomeOfficeCoverage: null,
  }

  Validation: any = {

  }
}
