import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { EndService } from '../_helpers/end.service';
import { MoratoriumService } from '../_helpers/moratorium.service';
import { QuoteService } from '../_helpers/quote.service';
import { StateService } from '../_helpers/state.service';
import { QuoteUpdate } from '../_shared/classes/QuoteUpdate';
import { Validation } from '../_shared/classes/Validation';
import { IState } from '../_shared/interfaces/IState';
import { EndModel } from './end.class';
import { UserService } from '../user/services/user.service';
import { FormBaseComponent } from '../components/shared/base-components/form-base.component';
import { NgForm } from '@angular/forms';
import { IMailingAddress } from '../_shared/interfaces/IMailingAddress';
import { IMortgagee } from '../_shared/interfaces/IMortgagee';
import { IAdditionalInsured } from '../_shared/interfaces/IAdditionalInsured';


@Component({
  selector: 'app-end',
  templateUrl: './end.component.html',
  styleUrls: ['./end.component.css']
})
export class EndComponent extends FormBaseComponent implements OnInit, AfterViewInit {

  @ViewChildren('Underwriter') inputRef : ElementRef[];
  entity = new EndModel;
  today: Date = new Date();
  continue: boolean = true;
  mortgageeFlg: boolean = false;
  secondaryMortgageeFlg: boolean = false;
  secondaryMortPrimaryFlg: boolean = false;
  addInsuredFlg: boolean = false;
  validation: Validation = JSON.parse(sessionStorage.getItem('validation')) ?? new Validation();
  quoteId: number = + (sessionStorage.getItem('quoteId') ?? 0);
  alreadyCreated = false;
  states: IState[] = [];
  counties: string[] = [];
  riskAddressHeader: string;
  quoteNumber: string = sessionStorage.getItem('quoteNumber').replace(/"/g, '');

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    private service: EndService,
    private quoteService: QuoteService,
    private morService: MoratoriumService,
    private stateService: StateService,
    private titleService: Title,
    private userService: UserService
  ) {
    super();
    console.log(this.today);
    this.getStates();
    // this.getCounties();
  }
  ngAfterViewInit(): void {
    super.configureCheckForChange(this.inputRef);
  }

  ngOnInit(): void {
    this.userService.refreshToken();
    this.setRiskAddressHeader();
    this.titleService.setTitle("Hiscox High Value - Finish Quote Section");
    if (this.quoteId != 0 && this.quoteId != null && this.quoteId != undefined) {
      this.service.getByQuote(this.quoteId).subscribe(item => {
        let data = item.Data;
        let check = item.Success;
        if (check && data != null) {
          this.alreadyCreated = true;

          this.entity.Detail.QuoteId = this.quoteId;

          if (data.MailingAddress != null) {
            this.entity.Detail.MailingAddress = data.MailingAddress as IMailingAddress;
          }
          if (data.PrimaryMortgagee != null) {
            this.mortgageeFlg = true;
            this.entity.Detail.PrimaryMortgagee = data.PrimaryMortgagee as IMortgagee;

            if (data.SecondaryMortgagee != null) {
              this.secondaryMortgageeFlg = true;
              this.entity.Detail.SecondaryMortgagee = data.SecondaryMortgagee as IMortgagee;
            }
          }
          
          if (data.AdditionalInsured != null) {
            this.addInsuredFlg = true;
            this.entity.Detail.AdditionalInsured = data.AdditionalInsured as IAdditionalInsured;
          }
        }
      })
    }
    else if (localStorage.getItem("endprev")) {
      this.entity.Detail = JSON.parse(localStorage.getItem("endprev"));
      localStorage.removeItem("endprev");
    }
  }
  
  endQuote() {
    if (this.entity.Detail.QuoteId == 0) {
      this.entity.Detail.QuoteId = this.quoteId;
    }

    if (this.mortgageeFlg === false) {
      this.entity.Detail.PrimaryMortgagee.Name = "";
      this.entity.Detail.SecondaryMortgagee.Name = "";
    } else if (this.secondaryMortgageeFlg === false) {
      this.entity.Detail.SecondaryMortgagee.Name = "";
    }

    if (this.entity.Detail.PrimaryMortgagee.Name != "") {
      this.entity.Detail.PrimaryMortgagee.QuoteId = this.quoteId;
    } else {
      this.entity.Detail.PrimaryMortgagee.FlgVoid = true;
    }

    if (this.entity.Detail.SecondaryMortgagee.Name != "") {
      this.entity.Detail.SecondaryMortgagee.QuoteId = this.quoteId;
    } else {
      this.entity.Detail.SecondaryMortgagee.FlgVoid = true;
    }

    if (this.addInsuredFlg === false) {
      this.entity.Detail.AdditionalInsured.Name = "";
    } else {
      if (this.entity.Detail.AdditionalInsured.Name != "") {
        this.entity.Detail.AdditionalInsured.QuoteId = this.quoteId;
      }
    }

    this.service.save(this.entity.Detail).subscribe(item => {
      let data = item.Data;
      let check = item.Success;
      if (check) {
        this.router.navigate(['payment']);
      }
    })
  }

  getStates() {
    this.stateService.allStates().subscribe(item => {
      if (item.Success) {
        this.states = item.Data;
      }
      
    });
  }

  get isEndDataInvalid() {
    return this.mortgageeFlg && (this.entity.Detail.PrimaryMortgagee.FlgPrimary == false && (this.secondaryMortgageeFlg && this.entity.Detail.SecondaryMortgagee.FlgPrimary == false));
  }

  onPrevious() {
    this.showLoadingSpinner();

    localStorage.setItem("endprev", JSON.stringify(this.entity.Detail));
    this.router.navigate(['options']);
  }
}
