import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomizeService } from '../_helpers/customize.service';
import { QuoteService } from '../_helpers/quote.service';
import { QuoteUpdate } from '../_shared/classes/QuoteUpdate';
import { Validation } from '../_shared/classes/Validation';
import { CustomizeModel } from './customize.class';
import { UserService } from '../user/services/user.service';
import { FormBaseComponent } from '../components/shared/base-components/form-base.component';


@Component({
  selector: 'app-customize',
  templateUrl: './customize.component.html',
  styleUrls: ['./customize.component.css']
})
export class CustomizeComponent extends FormBaseComponent implements OnInit, AfterViewInit {

  @ViewChildren('Underwriter') inputRef : ElementRef[];
  entity = new CustomizeModel;
  today: Date = new Date();
  continue: boolean = true;
  validation: Validation = JSON.parse(sessionStorage.getItem('validation')) ?? new Validation();
  quoteId: number = + (sessionStorage.getItem('quoteId') ?? 0);
  alreadyCreated = false;
  ordinanceOpt: any[] = [{ value: 10, text: "10%" }, { value: 15, text: "15%" }, { value: 20, text: "20%" }];
  businessOpt: any[] = [{ value: 5000, text: "$5,000" }, { value: 10000, text: "$10,000" }, { value: 25000, text: "$25000" }];
  lossOpt: any[] = [{ value: 5000, text: "$5,000" }, { value: 25000, text: "$25,000" }, { value: 100000, text: "$100,000" }];
  waterOpt: any[] = [{ value: 10000, text: "$10,000" }, { value: 50000, text: "$50,000" }, { value: 100000, text: "$100,000" }];
  moldOpt: any[] = [{ value: 25000, text: "$25,000" }, { value: 50000, text: "$50,000" }, { value: 1000000, text: "$1,000,000" }];
  coversOpt: string[] = ["Platinium", "Platinium Plus"];
  covAOpt: any[] = [{ value: 25, text: "25%" }, { value: 50, text: "50%" }];
  lockOpt: any[] = [{ value: 2500, text: "$2.500" }, { value: 5000, text: "$5,000" }];
  serviceOpt: any[] = [{ value: 10000, text: "$10,000" }, { value: 25000, text: "$25,000" }];
  mechOpt: any[] = [{ value: 25000, text: "$25,000" }, { value: 50000, text: "$50,000" }];
  mortgageOpt: any[] = [{ value: 25000, text: "$25,000" }];
  fraudOpt: any[] = [{ value: 15000, text: "$15,000" }, { value: 25000, text: "$25,000" }];
  homeOpt: any[] = [{ value: 0, text: "$0" }, { value: 5000, text: "$5,000" }, { value: 10000, text: "$10,000" }, { value: 25000, text: "$25,000" }];
  riskAddressHeader: string;
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    private service: CustomizeService,
    private quoteService: QuoteService,
    private titleService: Title,
    private userService: UserService
  ) {
    super();
    console.log(this.today)
  }
  ngAfterViewInit(): void {
    super.configureCheckForChange(this.inputRef);
  }

  ngOnInit(): void {
    this.userService.refreshToken();
    this.setRiskAddressHeader();
    this.titleService.setTitle("Hiscox High Value - Customize");
    if (this.quoteId != 0 && this.quoteId != null && this.quoteId != undefined) {
      this.service.getByQuote(this.quoteId).subscribe(item => {
        let data = item.Data;
        let check = item.Success;
        if (check) {
          this.alreadyCreated = true;
          this.entity.Detail = data;
        }
      })
    }
  }

  customize() {
    this.service.save(this.entity.Detail).subscribe(item => {
      let data = item.Data;
      let check = item.Success;
      if (check) {
        if (!this.alreadyCreated) {
          let value = new QuoteUpdate;
          value.data.CustomizeId = data;
          value.data.CustomizeUpdate = true;
          value.data.QuoteId = this.quoteId;
          this.quoteService.update(value.data).subscribe(result => {
            check = result.Success;
            if (check) {
              this.router.navigate(['coverage']);
              
            }
          })
        }
        else {
          this.router.navigate(['coverage']);
        }
      }
    })
  }


  onPrevious() {
    if (this.validation.data.Occupancy == "Secondary" || this.validation.data.Occupancy == "Seasonal" || this.validation.data.Occupancy == "Tenanted") {
      this.router.navigate(['credits']);
    }
    else {
      this.router.navigate(['loss-history']);
    }
    this.router.navigate(['personal-coverage']);
  }
}
