import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentService } from '../_helpers/payment.service';
import { QuoteService } from '../_helpers/quote.service';
import { QuoteUpdate } from '../_shared/classes/QuoteUpdate';
import { Validation } from '../_shared/classes/Validation';
import { PaymentModel } from './payment.class';
import { UserService } from '../user/services/user.service';
import { FormBaseComponent } from '../components/shared/base-components/form-base.component';
import { StateService } from '../_helpers/state.service';



@Component({
 selector: 'app-payment',
 templateUrl: './payment.component.html',
 styleUrls: ['./payment.component.css']
})
export class PaymentComponent extends FormBaseComponent implements OnInit {

  entity = new PaymentModel;
  today: Date = new Date();
  continue: boolean = true;
  validation: Validation = JSON.parse(sessionStorage.getItem('validation')) ?? new Validation();
  quoteId: number = + (sessionStorage.getItem('quoteId') ?? 0);
  alreadyCreated = false;
  accountTypes: string[] = ["Savings", "Checkings"]
  riskAddressHeader: string;
  flgBillInsured: boolean = false;
  flgBillSomeoneELse: boolean = false;
  mortgageeState: string;

 constructor(
   private router: Router,
   private formBuilder: FormBuilder,
   private route: ActivatedRoute,
   private cdRef: ChangeDetectorRef,
   private service: PaymentService,
   private quoteService: QuoteService,
   private titleService: Title,
   private userService: UserService,
   private stateService: StateService
 ) {
  super();
   console.log(this.today)
 }

 ngOnInit(): void {
   this.userService.refreshToken();
   this.setRiskAddressHeader();
   this.titleService.setTitle("Hiscox High Value - Payment");
   if (this.quoteId != 0 && this.quoteId != null && this.quoteId != undefined) {
     this.service.getByQuote(this.quoteId).subscribe(item => {
       let data = item.Data;
       let check = item.Success;
       if (data != null && check) {
         this.alreadyCreated = true;

         if (data.Ach != null) {
           this.entity.Ach = data.Ach;
         }

         if (data.PrimaryMortgagee != null) {
           this.entity.PrimaryMortgagee = data.PrimaryMortgagee;

           this.stateService.stateById(this.entity.PrimaryMortgagee.StateId).subscribe(state => {
             if (state.Success) {
               this.mortgageeState = state.Data.Code;
             }
           });
         }
       }
     })

   }
   else if(localStorage.getItem("payprev")){
    this.entity.Ach = JSON.parse(localStorage.getItem("payprev"));
    localStorage.removeItem("payprev");
  }
 }

  paymentAch() {
    if (this.entity.Ach != null) {
      this.entity.Ach.QuoteId = this.quoteId;
    }
   
   this.service.save(this.entity.Ach).subscribe(item => {
     let data = item.Data;
     let check = item.Success;
     if (check) {
       if (!this.alreadyCreated) {
         let value = new QuoteUpdate;
         value.data.PaymentAchId = data;
         value.data.PaymentUpdate = true;
         value.data.QuoteId = this.quoteId;
         this.quoteService.update(value.data).subscribe(result => {
           this.router.navigate(['upload-documents']);
         })
       }
       else {
         this.router.navigate(['upload-documents']);
       }
     }
   })
 }

 onPrevious() {
  localStorage.setItem("payprev", JSON.stringify(this.entity.Ach));
   this.router.navigate(['end']);
 }
}
