import { Component, OnInit } from '@angular/core';
import { QuoteDocumentService } from './service/quote-document.service';
import { IQuoteDocument } from './model/IQuoteDocument';
import { ActivatedRoute, Router } from '@angular/router';
import { QuoteDocumentFillingTypeEnum } from './enum/QuoteDocumentFillingTypeEnum';
import { FileUtilService } from '../../services/file-util.service';
import { MatDialog } from '@angular/material/dialog';
import { QuoteDocumentUploadModalComponent } from '../modals/quote-document-upload-modal/quote-document-upload-modal.component';
import { NotificationService } from 'src/app/services/notification.service';
import { RejectQuoteComponent } from '../modals/reject-quote/reject-quote.component';
import { RejectQuoteModalData } from '../modals/reject-quote/model/reject-quote-modal-data';
import { UserService } from 'src/app/user/services/user.service';
import { CustomResponse } from 'src/app/_shared/classes/CustomResponse';
import { SessionStorageUtils } from 'src/app/util/session-storage';
import { Title } from '@angular/platform-browser';
import { QuoteService } from 'src/app/_helpers/quote.service';
import { UnderwrtierModificationService } from 'src/app/_helpers/underwriter-modifcation.service';

@Component({
  selector: 'app-upload-documents',
  templateUrl: './upload-documents.component.html',
  styleUrls: ['./upload-documents.component.css']
})
export class UploadDocumentsComponent implements OnInit {
  
  public quote;
  private quoteId: number;
  public quoteDocuments: Array<IQuoteDocument> = [];
  public displayedColumns = ['Name', 'RequiredWhen', 'UploadedFile', 'Download', 'Upload'];
  public isLoading = false;
  public isQuoteModified = false;
  
  constructor(
    private router: Router,
    private quoteDocumentService: QuoteDocumentService,
    private activatedRoute: ActivatedRoute,
    private fileUtilService: FileUtilService,
    private dialog: MatDialog,
    private notificatorService: NotificationService,
    private userService: UserService,
    private titleService: Title,
    private quoteService: QuoteService,
    private underwriterModificationService: UnderwrtierModificationService) {

    this.activatedRoute.params.subscribe((param) => {
      this.quoteId = param['quoteId'];
    });

    const pageData = this.activatedRoute.snapshot.data['UploadDocumentResolver'] as CustomResponse<IQuoteDocument[]>;

    if(pageData.Success){
      this.quoteDocuments = pageData.Data;
    }
  }

  ngOnInit(): void {
    this.titleService.setTitle("Hiscox High Value - Upload Documents");
    this.userService.refreshToken();
    this.getQuoteDocumentsByQuoteId();
    this.quote = this.quoteService.get(this.quoteId);
    console.log(this.quote);
    this.isQuoteModified = this.quote.ModifyQuoteFlg;
  }

  public getQuoteDocumentsByQuoteId(){
    const storedQuoteId = SessionStorageUtils.getQuoteId();
    if((!this.quoteId || this.quoteId == 0) && storedQuoteId > 0){
        this.quoteId = storedQuoteId;
        this.getQuoteDocuments();
    }
  }

  public submitBindRequest(){
    this.notificatorService.showSuccessMessage(
      "Bind Request", 
      "Your bind request has been submitted to Underwriting for review! <br> Please contact Underwriting@twfg.com with any questions.", 
      { HTMLContent: true });
  }

  public resubmitQuote() {
    this.notificatorService.showSuccessMessage(
      "Bind Request", 
      "Your bind request has been re-submitted to Underwriting for review! <br> Please contact Underwriting@twfg.com with any questions.", 
      { HTMLContent: true });
    this.underwriterModificationService.updateModifyQuoteFlg(this.quote);
  }

  public redirectToPaymentPage(){

  }

  public retractQuote() {
    this.underwriterModificationService.updateModifyQuoteFlg(this.quote);
  }

  public chooseRedirectPath() {
    let user = SessionStorageUtils.getUser();
    if (user.Permissions.includes('High Value Underwriter')) {
      this.router.navigate(['underwriter-modifications']);
    } else if (user.Permissions.includes('High Value Agent')) {
      this.submitBindRequest();
    }
  }

  public openUploadModal(documentId: number){
    const dialogRef = this.dialog.open(QuoteDocumentUploadModalComponent,{ data:{
      QuoteId: this.quoteId,
      DocumentId: documentId
    }});

    dialogRef.afterClosed().subscribe(result => {
      if(!result || !result.QuoteId)
        return;
      this.isLoading = true;
      this.quoteDocumentService.uploadQuoteDocumentFile(result)
      .toPromise()
      .then((result) => {
        this.notificatorService.showSuccessMessage(
          "File Uploaded", 
          "Your file was uploaded successfully.");
      })
      .catch((error) =>{   
        this.notificatorService.showErrorMessage(
          "File Upload", 
          "An error occured uploading the file.");     
      })
      .finally(() => {
        this.getQuoteDocuments();
        this.isLoading = false;
      });
    });
  }
  
  public downloadSubmittedDocument(documentId: number){
    let quoteDocument = this.quoteDocuments.find(x => x.Id == documentId);
    this.isLoading = true;
    this.quoteDocumentService.downloadSubmittedQuoteDocument(quoteDocument.UploadedFileId)
    .toPromise()
    .then((file) => {
      this.fileUtilService.downloadFile(file, quoteDocument.NormalizedFileName);
    })
    .catch((error) => {      
      console.log(error);
    })
    .finally(() => {
      this.isLoading = false;
    });
  }

  public downloadDocument(documentId: number){
    let quoteDocument = this.quoteDocuments.find(x => x.Id == documentId);
    switch(quoteDocument.FillingType){
      case QuoteDocumentFillingTypeEnum.Automatically:
        this.isLoading = true;
        this.quoteDocumentService.generateQuoteDocument(quoteDocument, this.quoteId)
        .toPromise()
        .then((file) => {
          console.log(file);
          //this.utilService.downloadFile(file, quoteDocument.Name);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
        break;
        case QuoteDocumentFillingTypeEnum.Manually:
          this.isLoading = true;
          this.quoteDocumentService.downloadQuoteDocument(quoteDocument)
          .toPromise()
          .then((file) => {
            this.fileUtilService.downloadFile(file, quoteDocument.Name);
          })
          .catch((error) => {
            console.log(error);
          })        
          .finally(() => {
            this.isLoading = false;
          });
          break;
    }
  }

  private getQuoteDocuments(): void{
    this.isLoading = true;
    this.quoteDocumentService
    .getDocuments(this.quoteId)
    .toPromise()
    .then((result) => {      
      if(result && result.Success){
        this.quoteDocuments = result.Data;
      }
    })
    .catch((error) => {
    })        
    .finally(() => {
      this.isLoading = false;
    });
  }

  rejectQuote(){
    this.dialog.open<RejectQuoteComponent, RejectQuoteModalData>(RejectQuoteComponent, {
      data: {
        QuoteId: this.quoteId
      }
    });
  }
}
