import { ILogin } from "../_shared/interfaces/ILogin";
import { IAgencySelector } from "../_shared/interfaces/IAgencySelector";
import { IAddress } from "../_shared/interfaces/IAddress";
import { IMoratorium } from "../_shared/interfaces/IMoratorium";
import { IState } from "../_shared/interfaces/IState";
import { ISession } from "../_shared/interfaces/ISession";
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

export class RiskAddressInput {
  
  EffectiveDate: NgbDateStruct = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate()
  };
  AutoCompletePlace: any;
  RawAddressInput: string = '';
  NotFoundAddress: IAddress = {
    Address1: '',
    Address2: '',
    City: '',
    State: null,
    Zip: null,
    County: null
  };
  HydrantDistance: number = null;
}

export class RiskVisible {
  Spinner: boolean = false;
  FindAddress: boolean = true;
  EffectiveDate: boolean = false;
}

export class RiskAddressData {
  Progress: number = 0;
  InactiveCounties: IMoratorium[];
  QuoteableStates: IState[];
  Counties: string[];
  SessionModel: ISession = sessionStorage.getItem('model') != null ? (JSON.parse(sessionStorage.getItem('model')) as ISession) : {};
  Today: NgbDateStruct = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate()
  };
  MaxEffDate: NgbDateStruct = {
    year: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 44).getFullYear(),
    month: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 44).getMonth() + 1,
    day: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 44).getDate()
  };
  WorkingAddress: IAddress;
  WorkingVeriskPpcId: number;
}
