import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { BaseGuard } from './base.guard';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard extends BaseGuard implements CanActivate {

  constructor(protected router: Router){
    super(router)
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return super.validatePermissions(route);
  }
  
}
