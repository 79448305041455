import { Injectable } from '@angular/core';
import { Inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { CustomResponse } from '../_shared/classes/CustomResponse';
import { BaseService } from '../services/base-service.service';

@Injectable({
  providedIn: 'root',
})
export class UnderwrtierModificationService extends BaseService{

  fortunaApiBaseUrl: string;

  constructor(private http: HttpClient, @Inject('FORTUNA_BASE_URL') fortunaApiBaseUrl: string) {
    super();
  }

  create(item, quoteId) {
    return this.http.post<CustomResponse<any>>(this.fortunaApiBaseUrl + `api/UnderwriterModifications/CreateQuoteManualPremium`, {item, quoteId}, this.GetAuthHeaderJson());
  }

  update(item) {
    return this.http.put<CustomResponse<any>>(this.fortunaApiBaseUrl + `api/UnderwriterModifications/UpdateQuoteManualPremium`, item, this.GetAuthHeaderJson());
  }

  updateModifyQuoteFlg(item) {
    return this.http.put<CustomResponse<any>>(this.fortunaApiBaseUrl + `api/Quote/UpdateModifyQuoteFlg`, item, this.GetAuthHeaderJson());
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.log("error in service");
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}

