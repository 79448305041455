import { IEnd } from "../_shared/interfaces/IEnd"
export class EndModel {
  Detail: IEnd = {
    QuoteId: 0,
    MailingAddress: {
      MailingStreet1: '',
      MailingStreet2: '',
      MailingCity: '',
      MailingStateId: null,
      MailingZip: null
    },
    PrimaryMortgagee: {
      Id: null,
      QuoteId: 0,
      Name: '',
      LoanNumber: null,
      FlgPrimary: true,
      FlgVoid: false,
      Street1: '',
      Street2: '',
      City: '',
      StateId: null,
      Zip: null,
      CreatedByUserId: null,
      UpdatedByUserId: null,
    },
    SecondaryMortgagee: {
      Id: null,
      QuoteId: 0,
      Name: '',
      LoanNumber: null,
      FlgPrimary: false,
      FlgVoid: false,
      Street1: '',
      Street2: '',
      City: '',
      StateId: null,
      Zip: null,
      CreatedByUserId: null,
      UpdatedByUserId: null,
    },
    AdditionalInsured: {
      Id: null,
      QuoteId: 0,
      FirstName: '',
      LastName: '',
      Name: '',
      TrusteeName: '',
      MailingStreet1: '',
      MailingStreet2: '',
      MailingCity: '',
      MailingStateId: null,
      MailingZip: null,
      CreatedByUserId: null,
      UpdatedByUserId: null,
      CreatedDate: null,
      UpdatedDate: null
    }
  }

  Validation: any = {

  }
}
