import { IQuoteUpdate } from "../interfaces/IQuoteUpdate";

export class QuoteUpdate {
  data: IQuoteUpdate = {
    QuoteId: 0,
    PropertyDetailId: null,
    PropertyDetailUpdate: false,
    CreditsId: null,
    CreditsUpdate: false,
    CoverageId: null,
    CoverageUpdate: false,
    PersonalCoverageId: null, 
    PersonalCoverageUpdate: false,
    LossHistoryId: null,
    LossHistoryUpdate: false,
    OptionId: null,
    OptionUpdate: false,
    CustomizeId: null,
    CustomizeUpdate: false,
    PaymentAchId: null,
    PaymentUpdate: false,
    EndQuoteUpdate: false,
    EndQuoteId: null
  }
}
