import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { PropertyDetailService } from '../_helpers/property-detail.service';
import { Validation } from '../_shared/classes/Validation';
import { QuoteUpdate } from '../_shared/classes/QuoteUpdate';
import { PropertyDetailModel } from './property-detail.class';
import { QuoteService } from '../_helpers/quote.service';
import { UserService } from '../user/services/user.service';
import { IAddress } from "../_shared/interfaces/IAddress";
import { InformationModel } from '../insured-info/information.class';
import { FormBaseComponent } from '../components/shared/base-components/form-base.component';
import { VeriskService } from '../services/verisk/verisk.service';
import { SessionStorageUtils } from '../util/session-storage';
import { FormatService } from '../_helpers/format.service';
import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateCustomParserFormatter } from '../components/NgbDateCustomParserFormatter';
import { CalculateValuationRequest } from '../_shared/interfaces/verisk/valuation/ICalculateValuationRequest';
import { RecalculateValuationRequest } from '../_shared/interfaces/verisk/valuation/IRecalculateValuationRequest';
import { CustomResponse } from '../_shared/classes/CustomResponse';
import { ValuationInformation } from '../_shared/interfaces/verisk/valuation/IValuationInformation';
import { of, Observable } from 'rxjs';
import { CalculateValuationResult } from '../_shared/interfaces/verisk/valuation/ICalculateValuationResult';
import { RoofCoverageService } from '../_helpers/roof-calculation.service';

@Component({
  selector: 'app-property-detail',
  templateUrl: './property-detail.component.html',
  styleUrls: ['./property-detail.component.css']
})
export class PropertyDetailComponent extends FormBaseComponent implements OnInit, AfterViewInit {

  @ViewChildren('Underwriter') inputRef : ElementRef[];
  public entity: PropertyDetailModel = new PropertyDetailModel();
  leaseDurations = [{ value: 1, text: "1 year or greater" }, { value: 2, text: "Less than 1 year" }];
  occupancyTypes = [{Id:1, Name:"Primary"},
  {Id:2, Name: "Secondary"},
  {Id:3, Name: "Seasonal"},
  {Id:4, Name: "Tenanted"},
  {Id:5, Name: "Vacant"},
  {Id:6, Name: "COC"},
  {Id:7, Name: "Vacant Renovation"}];
  numberOfFamilies = [{Id:1, Name:"1"},
  {Id:2, Name: "2"},
  {Id:3, Name: "3"},
  {Id:4, Name: "4"},
  {Id:5, Name: "5+"}];
  dwellingTypes: string[] = ["Single-Family", "Condo", "Townhome", "Duplex"];
  protections: string[] = ["All", "Some", "None", "Other or Unknown"];
  firedptresponsetimeoptions: string[] = ["0 - 5 mins", "5 - 10 mins", "10 - 15 mins", "or 15 + mins"];
  fireProtectionClases: string[] = ["Class 1", "Class 2", "Class 3", "Class 4", "Class 5", "Class 6", "Class 7", "Class 8", "Class 9", "Class 10", "Class 10W"]
  roofGeometries: string[] = ["Hip", "Gable", "Flat", "Built Up", "Other or Unknown"];
  constructionTypes = [
  { Id: 1, Name: "EIFS" },
  { Id: 2, Name: "Log" },
  { Id: 3, Name: "Frame" },
  { Id: 4, Name: "Brick Veneer" },
  { Id: 5, Name: "Brick" },
  { Id: 6, Name: "Hardiplank/CementFiber" },
  { Id: 7, Name: "Stucco" },
  { Id: 8, Name: "Superior or MNC" },
  { Id: 9, Name: "Asbestos" },
  { Id: 10, Name: "Mobile Home" },
  { Id: 11, Name: "Other" }
];
  roofCoverings: string[] = 
  [
    "Asbestos", 
    "Composition (3-Tab Shingle)", 
    "Composition (Architectural Shingle)", 
    "Composition (Impact Resistive)", 
    "Composition (Rolled Roofing)", 
    "Concrete", 
    "Flat (Built-Up tar & Gravel)", 
    "Flat (Rubber/EDPM/Other)", 
    "Metal (Corrugated)", 
    "Metal (Exposed Fasteners)", 
    "Metal (Standing-Seam)", 
    "Metal Shake or Aluminum Shake", 
    "Slate", 
    "Tile (Clay)", 
    "Tile (Concrete)",
    "Tile (Metal)", 
    "Wood Shake", 
    "Other or Unknown"
  ]
  femaZones: string[] = ["Unknown", "Zone X", "Zone A", "Zone A1", "Zone A2", "Zone A3", "Zone A4", "Zone A5", "Zone A6", "Zone A7", "Zone A8", "Zone A9", "Zone A10", "Zone A11", "Zone A12", "Zone A13", "Zone A14", "Zone A15", "Zone A16", "Zone A17", "Zone A18", "Zone A19", "Zone A20", "Zone A21", "Zone A22", "Zone A23", "Zone A24", "Zone A25", "Zone A26", "Zone A27", "Zone A28", "Zone A29", "Zone A30", "Zone A99", "Zone AE", "Zone AH", "Zone AO", "Zone AR", "Zone B", "Zone C", "Zone D", "Zone M", "Zone V", "Zone VE"]
  hwyZipCodes: number[] = [77571, 77586, 77507];
  today: Date = new Date();
  todayNgbDate: NgbDate;
  validation: Validation = SessionStorageUtils.getValidation() ?? new Validation();
  quoteId: number = + (sessionStorage.getItem('quoteId') ?? 0);
  quoteNumber: string = sessionStorage.getItem('quoteNumber').replace(/"/g, '');
  showRentedAnyTimeMessage: boolean = false;
  year: number;
  roofDateFormat: Date;
  alreadyCreated = false;
  riskAddressHeader: string;
  currentYear: number;
  isInvalid: boolean = false;
  roofCoverageType: string;
  structureQuality: string;
  replacementCost: number;
  isPPCDataFromVerisk: boolean = false;
  effectiveDateInception: NgbDateStruct;
  roofAge: number;
  isRoofRequirementInvalid: boolean;

  constructor (
    private router: Router,
    private service: PropertyDetailService,
    private quoteService: QuoteService, 
    private titleService: Title,
    private userService: UserService,
    private veriskService: VeriskService,
    private ngbDateCustomParserFormatter: NgbDateCustomParserFormatter,
    private formatService: FormatService,
    private roofCoverageService: RoofCoverageService
  ) {
    super();
  }
  ngAfterViewInit(): void {
    super.configureCheckForChange(this.inputRef);
  }

  ngOnInit(): void {
    this.userService.refreshToken();
    this.titleService.setTitle("Hiscox High Value - Property Details");
    this.currentYear = new Date().getFullYear();
    this.effectiveDateInception = this.formatService.toNgbDateStruct(this.today);
    this.todayNgbDate = new NgbDate(this.today.getFullYear(), this.today.getMonth()+1, this.today.getDay()); 
    this.setRiskAddressHeader();
    this.setPPCValue();
    this.getDataByQuote();
    this.mouseHandlers();
    this.roofCoverageService.parseReferenceTable();
  }

  private setPPCValue(){   
    const validation = SessionStorageUtils.getValidation();

    if(validation == null || 
       validation.data == null || 
       validation.data.PPC == "" || 
       this.entity.Detail.FireProtectionClass){
      return;
    }

    this.entity.Detail.FireProtectionClass = validation.data.PPC; 
    this.isPPCDataFromVerisk = true;  
  }

  private getDataByQuote(){    
    if (this.quoteId != 0 && this.quoteId != null && this.quoteId != undefined) {
     // this.showLoadingSpinner();
      this.service.getByQuote(this.quoteId).subscribe(item => {             
        let data = item.Data;
        if (item.Success && data != null && data.DweelingType!=null) {         
          this.alreadyCreated = true;
          this.entity.Detail = data;         
          this.setPPCValue();
          this.roofYearSetUp();
        }
        else
        this.getValuationInformation().subscribe();
        this.hideLoadingSpinner();
        
      },
      () => this.hideLoadingSpinner());
      return;
    }
    else if(localStorage.getItem("propprev")){
      this.entity.Detail = JSON.parse(localStorage.getItem("propprev"));
      localStorage.removeItem("propprev");
    }
    else
       this.getValuationInformation().subscribe();
  }

  private getValuationInformation(){
    const valuation = SessionStorageUtils.getValuation();

    if(valuation == null || valuation.Id <= 0){
      return of({
        Data: null,
        Success: false
      } as CustomResponse<ValuationInformation>);
    }
    
    this.showLoadingSpinner();
    return this.veriskService.getInformationByValuationId(valuation.Id).pipe((interceptedResult) => {
      interceptedResult.subscribe(
        (result) => this.processValuationInformationResult(result),
        () => this.hideLoadingSpinner()
      );
      return interceptedResult;
    });
  }

  private processValuationInformationResult(result: CustomResponse<ValuationInformation>){
    if(result.Success){
      const data = result.Data;
      SessionStorageUtils.saveValuationInformation(data);
      if(data.HasData){
        this.entity.Detail.NumberStories = Number.parseInt(data.NumberOfStories);
        this.entity.Detail.YearBuilt = data.YearBuilt;
        this.entity.Detail.SquareFootage = data.SquareFeet;
        this.entity.Detail.ConstructionType = data.ConstructionType;

        var construct = this.constructionTypes.find(x => x.Name == data.ConstructionType); 
        this.entity.Detail.ConstructionTypeId = construct != null ? construct.Id : 11;
        this.entity.Detail.PoolExclusionFlg = data.SwimmingPool && (data.PoolDivingBoardSlide || !data.PoolHasFence);
        this.entity.Detail.PoolFenceFlg = data.PoolHasFence;
        this.entity.Detail.PoolFlg = data.SwimmingPool;
        this.entity.Detail.PoolDivingFlg = data.PoolDivingBoardSlide;
        this.entity.Detail.RoofCovering = data.RoofMaterial;
        this.structureQuality = data.StructureQuality;
        this.replacementCost = data.ReplacementCost;
      }
    }
    this.hideLoadingSpinner();
  }

  private recalculateValuation(){
    const valuationInfo = SessionStorageUtils.getValuationInformation();
    const valuation = SessionStorageUtils.getValuation();

    if(!valuationInfo || valuationInfo.HasData){
      return of({
        Data: valuation,
        Success: true
      } as CustomResponse<CalculateValuationResult>);
    }

    const riskAddressId = SessionStorageUtils.getRiskAddressId();    

    return this.veriskService.calculateValuation({
      RiskAddressId: riskAddressId,
      ClientName: this.insuredNameLine,
      RecalculateRequest: {
        ConstructionType: this.entity.Detail.ConstructionType,
        YearBuilt: this.entity.Detail.YearBuilt,
        SquareFeet: this.entity.Detail.SquareFootage,
        ValuationId: valuation.Id,
        HasPool: this.entity.Detail.PoolFlg,
        NumberOfStories: this.entity.Detail.NumberStories.toString(),
        RoofMaterial: this.entity.Detail.RoofCovering
      } as RecalculateValuationRequest
    } as CalculateValuationRequest);
  }

  propertyDetail() {
    this.showLoadingSpinner();
    this.entity.Detail.ConstructionType = this.constructionTypes.find(i=> i.Id == this.entity.Detail.ConstructionTypeId)?.Name ?? "";
    this.entity.Detail.EffectiveDateInception = this.ngbDateCustomParserFormatter.format(this.effectiveDateInception);

    if (!this.entity.Detail.PoolFlg) {
      this.entity.Detail.PoolFenceFlg = false;
      this.entity.Detail.PoolDivingFlg = false;
    }

    if (!this.ifWPIWaiverNeeded) {
      this.entity.Detail.WpiWaiverFlg = false;
    }

    this.entity.Detail.PoolExclusionFlg = this.entity.Detail.PoolFlg && (this.entity.Detail.PoolDivingFlg || !this.entity.Detail.PoolFenceFlg);
 
    this.service.save(this.entity.Detail).subscribe(item => {
      let data = item.Data;
      if (item.Success) {       
        this.validation.data.YearBuilt = this.entity.Detail.YearBuilt;
        this.validation.data.Occupancy = this.occupancyTypes.find(i=> i.Id == this.entity.Detail.OccupancyTypeId).Name;
        
        SessionStorageUtils.saveValidation(this.validation);
        
        this.entity.Detail.ConstructionType = this.constructionTypes.find(x=> x.Id == this.entity.Detail.ConstructionTypeId)?.Name

        this.recalculateValuation().subscribe((recalculateResult) => {         
          this.hideLoadingSpinner();
          if(recalculateResult.Success && !recalculateResult.Data.Recalculate){
            SessionStorageUtils.saveValuation(recalculateResult.Data);
            this.getValuationInformation().subscribe((valuationResult) => {
              if(valuationResult.Success && valuationResult.Data.HasData){
                this.updateQuote(data);
              }
            },
            () => this.hideLoadingSpinner());           
          }
        },
        () => this.hideLoadingSpinner());

      }
    },
    () => this.hideLoadingSpinner())
  }

  private updateQuote(data: any){
    if (!this.alreadyCreated) {
      let value = new QuoteUpdate;
      value.data.PropertyDetailId = data;
      value.data.PropertyDetailUpdate = true;
      value.data.QuoteId = this.quoteId;
     
      this.quoteService.update(value.data).subscribe(result => {
        if (result.Success) {
          this.hideLoadingSpinner();
          this.redirectToCoverage();
        }
        this.hideLoadingSpinner();
      }, 
      () => this.hideLoadingSpinner());
      return;
    }
    this.hideLoadingSpinner();      
    this.redirectToCoverage();
  }

  redirectToCoverage(){
    this.router.navigate(['coverage']);
  }

  onPrevious() {
    localStorage.setItem("propprev", JSON.stringify(this.entity.Detail));
    this.router.navigate(['insured-info']);
  }

  rentedAnyTime() {
    if (this.entity.Detail.RentedAnyTimeFlg) {
      if (this.entity.Detail.OccupancyTypeId == 2 || this.entity.Detail.OccupancyTypeId == 3)
        this.showRentedAnyTimeMessage = true;
      else
        this.showRentedAnyTimeMessage = false;

      this.entity.Detail.OccupancyTypeId = 4;
      this.entity.Detail.RentedAnyTimeFlg = false;
    }
  }

  changeOccupancyType() {
    if ((this.entity.Detail.OccupancyTypeId == 2 || this.entity.Detail.OccupancyTypeId == 3) && this.entity.Detail.RentedAnyTimeFlg)
      this.showRentedAnyTimeMessage = true;
    else
      this.showRentedAnyTimeMessage = false;
    
      localStorage.setItem("occupancyTypeSelected", this.entity.Detail.OccupancyTypeId.toString());
  }
  
  validatStoriesInput(stories) {
    const decimalPlaces = (stories.value.toString().split('.')[1] || '').length;
    this.isInvalid = decimalPlaces > 1;
  }

  roofYearSetUp() {
    if (this.entity.Detail.RoofReplacementYear == null || this.entity.Detail.RoofReplacementYear == 0 || this.entity.Detail.RoofReplacementYear == undefined) {
      this.entity.Detail.RoofReplacementYear = this.entity.Detail.YearBuilt;
    }
  }

  onRoofMaterialSelect(): void {
    if (this.entity.Detail.RoofReplacementYear == null) {
      this.isRoofRequirementInvalid = false;
      return;
    }
    const roofMaterial = this.entity.Detail.RoofCovering;
    this.getRoofAge().subscribe(age => { 
      this.roofCoverageService.getRoofCoverageType(roofMaterial, age).subscribe(coverageType => { // Subscribe to get the coverage type
        if (coverageType === 'ACV') { 
          this.entity.Detail.IsACV = true;
          this.isRoofRequirementInvalid = false;
        } else if (coverageType === 'RCV') {
          this.entity.Detail.IsACV = false;
          this.isRoofRequirementInvalid = false;
        } else {
          this.entity.Detail.IsACV = false;
          this.isRoofRequirementInvalid = true;
        }
      });
    });
  }
  
  getRoofAge(): Observable<number> {
    const effectiveDateStr = sessionStorage.getItem('effectiveDate');
    if (effectiveDateStr) {
      const effectiveDate = new Date(effectiveDateStr);
      const effectiveYear = effectiveDate.getFullYear();
      const roofAge =  effectiveYear - this.entity.Detail.RoofReplacementYear;
      console.log(roofAge)
      return of(roofAge); 
    } else {
      console.error('Effective date not found in session storage.');
      return of(0); 
    }
  }

  get ifWPIFormsNeeded(): boolean {
    return this.validation.data.Tier1Flg === true || this.hwyZipCodes.includes(this.validation.data.ZipCode);
  }

  get ifWPIWaiverNeeded(): boolean {
    return !(this.entity.Detail.ReRoofWPIFlg === true || this.entity.Detail.EntireBuildingWPIFlg === true);
  }

  get isPropertyDetailEntityInvalid() {
   
    return (this.isOcupancyTypeInvalid || this.entity.Detail.OccupancyTypeId == 0 || this.isDwellingTypeInvalid || this.isConstructionTypeInvalid ||
       this.entity.Detail.ConstructionTypeId == 0 || this.isRoofCoveringInvalid || this.entity.Detail.RoofCovering == null ||
      this.isRoofReplacementInvalid || this.isNumberOfFamiliesInvalid || this.isComercialFlagInvalid
      || this.isFireAccessFlgInvalid || this.isOpeningProtectionsInvalid || this.isRoofReplacementYearInvalid || this.isRoofRequirementInvalid
      || this.isRoofGeometryInvalid || this.isFireProtectionClassInvalid || this.isFemaZoneInvalid
      || this.isNumberStoriesInvalid || this.isSquareFootageInvalid || this.isYearPurchasedInvalid || this.isWPIRequirementInvalid || this.entity.Detail.FireDeptResponseTime == null 
      || this.isYearBuiltInvalid || (this.entity.Detail.YearPurchased < this.entity.Detail.YearBuilt || (this.entity.Detail.FireDeptResponseTime == 'or 15 + mins' && (this.entity.Detail.FireProtectionClass == '9' || this.entity.Detail.FireProtectionClass == '10' || this.entity.Detail.FireProtectionClass == '10W'))));
  }

  get isYearBuiltInvalid() {
    return (this.entity.Detail.YearBuilt == null || this.entity.Detail.YearBuilt == 0)
  }

  get isYearPurchasedInvalid() {
    return (this.entity.Detail.YearPurchased == null)
  }
  get isSquareFootageInvalid() {
    return (this.entity.Detail.SquareFootage == null || this.entity.Detail.SquareFootage == 0)
  }
  
  get isNumberStoriesInvalid() {
    return (this.entity.Detail.NumberStories == null)
  }
  get isFemaZoneInvalid() {
    return (this.entity.Detail.FemaZone == null);
  }
  get isFireProtectionClassInvalid() {
    return (this.entity.Detail.FireProtectionClass == null)
  }
 
  get isRoofGeometryInvalid() {
    return (this.entity.Detail.RoofGeometry == null)
  }
  get isRoofReplacementYearInvalid() {
    return (this.entity.Detail.RoofReplacementYear == null ||
      this.entity.Detail.RoofReplacementYear == 0) 
  }
  get isOpeningProtectionsInvalid() {
    return (this.entity.Detail.OpeningProtections == null ||
      this.entity.Detail.OpeningProtections == "") 
  }
  get isFireAccessFlgInvalid() {
    return (this.entity.Detail.FireAccessFlg == false)
  }

  get isComercialFlagInvalid() {
    return (this.entity.Detail.ComercialFlg == true)
  }

  get isRoofReplacementInvalid(): boolean{
    return (this.entity.Detail.RoofReplacementYear < this.entity.Detail.YearBuilt || this.entity.Detail.RoofReplacementYear > this.currentYear);
  }

  get isRoofCoveringInvalid(): boolean{
    const notAllowedRoofCovering = [
      'Asbestos',
      'Composition (Rolled Roofing)',
      'Flat (Built-Up tar & Gravel)',
      'Flat (Rubber/EDPM/Other)',
      'Metal (Corrugated)',
      'Metal Shake or Aluminum Shake',
      'Tile (Metal)',
      'Wood Shake',
      'Other or Unknown' 
    ];
    return (notAllowedRoofCovering.includes(this.entity.Detail.RoofCovering)); 
  }

  get isTrampolineFenceInvalid(): boolean{
    return (!this.entity.Detail.TrampolineFenceFlg && this.entity.Detail.OccupancyTypeId == 4 && this.entity.Detail.LeaseDuration == 2)
  }

  get isPoolFenceInvalid(): boolean{
    return (!this.entity.Detail.PoolFenceFlg && this.entity.Detail.OccupancyTypeId == 4 && this.entity.Detail.LeaseDuration == 2)
  }

  get isConstructionTypeInvalid(): boolean{
    const notAllowedConstructionTypes = [9, 10, 11];
    return ((this.entity.Detail.ConstructionTypeId == 1 && this.entity.Detail.YearBuilt <= 1997) || notAllowedConstructionTypes.includes(this.entity.Detail.ConstructionTypeId))
  }

  get isDwellingTypeInvalid(): boolean{
    return (this.entity.Detail.DweelingType == 'Condo' || this.entity.Detail.DweelingType == null || this.entity.Detail.DweelingType == "")
  }

  get isOcupancyTypeInvalid(): boolean{
    const notAllowedOccupancyTypes = [5, 6, 7]
    return (notAllowedOccupancyTypes.includes(this.entity.Detail.OccupancyTypeId))  
  }

  get isNumberOfFamiliesInvalid(): boolean{
    return (this.entity.Detail.FamilyMembers == 5);
  }

  get isWPIRequirementInvalid(): boolean {
    return this.ifWPIFormsNeeded && (this.entity.Detail.WpiWaiverFlg == false && this.entity.Detail.ReRoofWPIFlg == false && this.entity.Detail.EntireBuildingWPIFlg == false);
  }
}
