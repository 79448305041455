import { ILogin } from "../../interfaces/ILogin";
import { IAgencySelector } from "../../interfaces/IAgencySelector";
import { IIdentifier } from "../../interfaces/IIdentifier";

export class NavMenuModel {
  Login: ILogin = {
    email: '',
    password: ''
  };
  AgencySelector: IAgencySelector = {
    agencies: null,
    selected: null
  };
  LoggedInAs: string = null;
  Spinner: boolean = false;
  BusinessHours: boolean = false;
  topBarBehaviorTransition: number = 125;
  Identifier: IIdentifier = JSON.parse(sessionStorage.getItem('identifier'));
  ShowForgotPassword: boolean = false;
  resetEmail: string = null;
  InvalidItems: string[] = [];
  InvalidItemsText: string[] = [];
  FlgEzLynxInitialValues: boolean = false;
  HamburgerMenu: boolean = false;
  HamburgerOpen: boolean = false;
}
