import { ActivatedRouteSnapshot, Router } from "@angular/router";
import { SessionStorageUtils } from "../util/session-storage";
import { RoleGroup } from "../user/models/RoleGroup";

export abstract class BaseGuard {

    constructor(protected router: Router){}
    
    protected validatePermissions(routeAc: ActivatedRouteSnapshot) : boolean {

        const token = SessionStorageUtils.getUserToken();

        if(!token || Date.now() >= new Date(token.ExpirationDate).getTime()){
            SessionStorageUtils.wipeStorage();
            console.log(this.router.url);
            this.router.navigate(['/user/login/'], { queryParams: { returnUrl: routeAc.url[0] }});
        }  

        const user = SessionStorageUtils.getUser();
        const roleGroup = routeAc.data[0] as RoleGroup;

        if(roleGroup && roleGroup.Roles && roleGroup.Roles.length > 0){
            if(!this.isUserInRoles(user.Roles, roleGroup.Roles.map(x => x.Name))){
                this.redirectToForbidden();
                return false;
            }

            var permissions: string[] = [].concat(...roleGroup.Roles.map(x => x.Permissions));

            if(!permissions || permissions.length <= 0){
                return true;
            }
            else if(!this.userHasPermissions(user.Permissions, permissions)){
                this.redirectToForbidden();
                return false;
            }            
        }

        return true;  
    }

    protected redirectToForbidden() {
        this.router.navigate(['/forbidden']);
    }   

    protected userHasPermissions(userPermissions: string[], requiredPermissions: string[]): boolean{
        for(var permissions of requiredPermissions){
          if(userPermissions.includes(permissions)){
            return true;
          }
        }
        return false;
    }

    protected isUserInRoles(userRoles: string[], roles: string[]): boolean{
        for(var role of roles){
          if(userRoles.includes(role)){
            return true;
          }
        }
        return false;
    }
}