import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})

export class ScrollService {

  scrollTo(id: string, toTop?: boolean) {

    if (!toTop || toTop == null) {
      setTimeout(() => {
          var targetElement = document.getElementById(id);
          var topBar = document.getElementById('topBar');
          window.scroll(0, this.getOffset(targetElement).top - topBar.getBoundingClientRect().height - 30);
        },
        100); //100 ms delay to allow angular to digest recent changes that show divs
    } else {
      setTimeout(() => {
          window.scroll(0, 0);
        },
        100);
    }
    
  }

  private getOffset(el) {
    var _x = 0;
    var _y = 0;
    while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
      _x += el.offsetLeft - el.scrollLeft;
      _y += el.offsetTop - el.scrollTop;
      el = el.offsetParent;
    }
    return { top: _y, left: _x };
  }
}
