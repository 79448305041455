///<reference types="@types/googlemaps" />
import { Component, ViewChild, ElementRef, AfterViewInit, ViewChildren } from '@angular/core';
import { HomeService } from "./home.service";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HomeInput, HomeVisible, HomeData } from './home.class';
import Swal from 'sweetalert2'
import { Router } from '@angular/router';
import { StateService } from "../_helpers/state.service";
import { MoratoriumService } from "../_helpers/moratorium.service";
import { IMoratorium } from "../_shared/interfaces/IMoratorium";
import { AuthService } from "../_helpers/auth.service";
import { ScrollService } from "../_helpers/scroll.service";
import { IIdentifier } from "../_shared/interfaces/IIdentifier";
import { IState } from "../_shared/interfaces/IState";
import { IAddress } from "../_shared/interfaces/IAddress";
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { FormatService } from "../_helpers/format.service";
import { Title } from '@angular/platform-browser';
import { ValidateService } from '../_helpers/validate.service';
import { ViewSize, ViewSizeDefinition } from '../_helpers/view-size.service';
import { UserService } from '../user/services/user.service';
import { VeriskService } from '../services/verisk/verisk.service';
import { FormBaseComponent } from '../components/shared/base-components/form-base.component';


@Component({ selector: 'app-home', templateUrl: './home.component.html', styleUrls: ['./home.css'] })

export class HomeComponent extends FormBaseComponent implements AfterViewInit {

  input = new HomeInput;
  visible = new HomeVisible;
  data = new HomeData;

  @ViewChildren('Underwriter') inputRef : ElementRef[];
  @ViewChild('declinedModal', {}) declinedModal: ElementRef;
  @ViewChild('declinedEffDateModal', {}) declinedEffDateModal: ElementRef;
  @ViewChild('propertyNotFoundModal', {}) propertyNotFoundModal: ElementRef;
  propertyNotFoundModalReference: any;
  @ViewChild('distanceFromHydrantModal', {}) distanceFromHydrantModal: ElementRef;
  distanceFromHydrantModalReference: any;
  @ViewChild('addresstext', { static: false }) addresstext: any;
  @ViewChild('invalidItemsModal', {}) invalidItemsModal: ElementRef;
  invalidItemsModalReference: any;

  constructor(private homeService: HomeService, private modalService: NgbModal, private router: Router, private stateService: StateService,
    private moratoriumService: MoratoriumService, private authService: AuthService, private breakpointObserver: BreakpointObserver,
    private scrollService: ScrollService, private formateService: FormatService, private titleService: Title, private validateService: ValidateService,
    private userService: UserService,
    private veriskService: VeriskService) { 
      super()
    }

  ngOnInit() {
    this.userService.refreshToken();
    this.titleService.setTitle("Hiscox High Value - New Aplication");
    //this.homeService.printBaseUrl();
    this.data = new HomeData();

    
    // Template for in controller live detection of window size.
    this.breakpointObserver.observe([
      ViewSizeDefinition.ExtraSmallWidth,
      ViewSizeDefinition.ExtraSmallHeight,
      ViewSizeDefinition.SmallWidth,
      ViewSizeDefinition.SmallHeight,
      ViewSizeDefinition.MediumWidth,
      ViewSizeDefinition.MediumHeight,
      ViewSizeDefinition.Large
    ]).subscribe(result => {
      if (result.breakpoints[ViewSizeDefinition.ExtraSmallWidth] || result.breakpoints[ViewSizeDefinition.ExtraSmallHeight]) {
        this.visible.ViewSize = ViewSize.ExtraSmall;
      }
      else if (result.breakpoints[ViewSizeDefinition.SmallWidth] || result.breakpoints[ViewSizeDefinition.SmallHeight]) {
        this.visible.ViewSize = ViewSize.Small;
      }
      else if (result.breakpoints[ViewSizeDefinition.MediumWidth] || result.breakpoints[ViewSizeDefinition.MediumHeight]) {
        this.visible.ViewSize = ViewSize.Medium;
      }
      else {
        this.visible.ViewSize = ViewSize.Large;
      }
    });



    if (this.isEmpty(this.data.SessionModel) === false) {
      this.input.RawAddressInput = this.data.SessionModel.riskData.Address1;
      if (this.data.SessionModel.riskData.Address2 !== '')
        this.input.RawAddressInput += ` ${this.data.SessionModel.riskData.Address2}`;

      this.input.RawAddressInput += `, ${this.data.SessionModel.riskData.City}, ${this.data.SessionModel.riskData.State.Code} ${this.data.SessionModel.riskData.Zip}`;

      this.visible.EffectiveDate = true;
      this.data.Progress = 10;

      if (this.data.SessionModel.effectiveDate != undefined) {
        this.input.EffectiveDate = this.data.SessionModel.effectiveDate;
        this.visible.Declinations = true;
        this.data.Progress = 20;
      }

      this.input.Declinations.flooded = this.data.SessionModel.priorFloodDamage || false;
      if (this.data.SessionModel.priorFloodDamageFixed != undefined) {
        this.input.Declinations.floodDamageFixed = this.data.SessionModel.priorFloodDamageFixed;
      }

    }

    this.getClientToken();
  }

  getClientToken() {
    let token: IIdentifier = this.authService.getAccessToken();
    if (token == null) {
      /*this.authService.clientLogin().subscribe(clientLoginResult => {
        let identifier: IIdentifier = <any>clientLoginResult[0] as IIdentifier;

        identifier.AgentId = 100;
        identifier.Agency = "T0000";
        identifier.ClientInitiated = true;

        sessionStorage.setItem('identifier', JSON.stringify(identifier));

        const agency = this.router.url.replace(/^\/|\/$/g, '');
        if (agency != null && agency !== '') {
          this.authService.getAgency(agency).subscribe(agencyResult => {

            if (agencyResult != null) {
              identifier = this.authService.getAccessToken();
              identifier.Agency = (agencyResult as any).Number;
              identifier.AgentId = (agencyResult as any).PrimaryAgentId;
              sessionStorage.setItem('identifier', JSON.stringify(identifier));
            }

            this.getData();
          });
        } else {
          this.getData();
        }
      });*/
    } else {
      this.getData();
    }
  }

  ngAfterViewInit() {
    const autocomplete = new google.maps.places.Autocomplete(this.addresstext.nativeElement,
      {
        componentRestrictions: { country: 'US' },
        types: ["geocode"]  // 'establishment' / 'address' / 'geocode'
      });
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      this.input.AutoCompletePlace = autocomplete.getPlace();
    });
    super.configureCheckForChange(this.inputRef);
  }

  getData() {
   /* this.moratoriumService.getInactiveCounties().subscribe(result => {
      this.data.InactiveCounties = result as IMoratorium[];
    });

    this.stateService.quotableStates().subscribe(result => {
      this.data.QuoteableStates = result as IState[];
    });

    this.moratoriumService.getCounties().subscribe(result => {
      this.data.Counties = result;
    });*/
  }

  showPropertyNotFound() {
    this.propertyNotFoundModalReference = this.modalService.open(this.propertyNotFoundModal, { ariaLabelledBy: 'modal-basic-title', size: 'xl' });
  }

  showDeclined() {
    this.modalService.open(this.declinedModal, { ariaLabelledBy: 'modal-basic-title', size: 'lg' });
  }
  showDeclinedEffDate() {
    this.modalService.open(this.declinedEffDateModal, { ariaLabelledBy: 'modal-basic-title', size: 'lg' });
  }

  showDistanceToHydrant() {
    this.distanceFromHydrantModalReference = this.modalService.open(this.distanceFromHydrantModal, { ariaLabelledBy: 'modal-basic-title', size: 'lg' });
  }

  updateVeriskPpcAndSetAddress() {

    this.visible.InvalidItemsText = this.getInvalidItemsText('hydrantForm');

    if (this.visible.InvalidItemsText.length > 0) {
      this.openInvalidItemsModal();
      return;
    }

    this.veriskService.updateFireHydrant(this.data.WorkingVeriskPpcId, this.input.HydrantDistance).subscribe(updateVeriskPPcResult => {
      if(updateVeriskPPcResult.Success){
        const ppcResultData = updateVeriskPPcResult.Data;
        this.data.SessionModel.excludeWind = this.excludeWind(ppcResultData.FlagTier1, this.data.WorkingAddress.County, String(this.data.WorkingAddress.Zip));
        this.data.SessionModel.ppc = ppcResultData.PPC;

        sessionStorage.setItem("model", JSON.stringify(this.data.SessionModel));

        this.distanceFromHydrantModalReference.close();
        this.visible.EffectiveDate = true;
        this.data.Progress = 10;
      }
    });
  }

  setEffDate() {

    this.visible.InvalidItemsText = this.getInvalidItemsText('effDateform');

    if (this.visible.InvalidItemsText.length > 0) {
      this.openInvalidItemsModal();
      return;
    }

    var effDateAsDate = this.formateService.toDate(this.input.EffectiveDate);

    if (this.data.SessionModel.effectiveDate != this.input.EffectiveDate && this.data.SessionModel.preQuoteId != null) {
      this.homeService.updateEffectiveDate(this.data.SessionModel.preQuoteId, effDateAsDate).subscribe(result => {
        this.data.SessionModel.effectiveDate = this.input.EffectiveDate;
        sessionStorage.setItem("model", JSON.stringify(this.data.SessionModel));
      });
    }
    else {
      this.data.SessionModel.effectiveDate = this.input.EffectiveDate;
      sessionStorage.setItem("model", JSON.stringify(this.data.SessionModel));
    }

    sessionStorage.setItem("model", JSON.stringify(this.data.SessionModel));

    if (this.moratoriumService.declineTier3(this.data.SessionModel.riskData.County, this.data.SessionModel.effectiveDate)) {
      this.showDeclinedEffDate();
    } else {
      this.data.Progress = 20;
      this.visible.Declinations = true;
      this.scrollService.scrollTo('declinationQuestionsHeader');
    }
  }

  next() {
    var effDateAsDate = this.formateService.toDate(this.input.EffectiveDate);
    var minDateAsDate = this.formateService.toDate(this.data.Today);
    var maxDateAsDate = this.formateService.toDate(this.data.MaxEffDate);

    this.visible.InvalidItemsText = this.getInvalidItemsText('effDateform');

    this.visible.InvalidItemsText = this.visible.InvalidItemsText.concat(this.getInvalidItemsText('addressForm'));

    if (this.visible.InvalidItemsText.length > 0) {
      this.openInvalidItemsModal();
      return;
    }

    if (effDateAsDate == null || (effDateAsDate as unknown) == "Invalid Date" || effDateAsDate < minDateAsDate || effDateAsDate > maxDateAsDate) {
      return;
    }

    if (this.data.SessionModel.effectiveDate !== this.input.EffectiveDate && this.data.SessionModel.preQuoteId != null) {
      this.homeService.updateEffectiveDate(this.data.SessionModel.preQuoteId, effDateAsDate).subscribe(result => {
        this.data.SessionModel.effectiveDate = this.input.EffectiveDate;
        this.data.SessionModel.priorFloodDamage = this.input.Declinations.flooded;
        this.data.SessionModel.priorFloodDamageFixed = this.input.Declinations.floodDamageFixed || false;
        sessionStorage.setItem("model", JSON.stringify(this.data.SessionModel));
      });
    }
    else {
      this.data.SessionModel.effectiveDate = this.input.EffectiveDate;
      this.data.SessionModel.priorFloodDamage = this.input.Declinations.flooded;
      this.data.SessionModel.priorFloodDamageFixed = this.input.Declinations.floodDamageFixed || false;
      sessionStorage.setItem("model", JSON.stringify(this.data.SessionModel));
    }

    if (this.input.Declinations.rented ||
      this.input.Declinations.damaged ||
      this.input.Declinations.construction ||
      this.input.Declinations.barred ||
      this.input.Declinations.solar ||
      this.input.Declinations.large ||
      (this.input.Declinations.flooded && !this.input.Declinations.floodDamageFixed)) {
      this.showDeclined();
    } else if (this.moratoriumService.declineTier3(this.data.SessionModel.riskData.County, this.input.EffectiveDate)) {
      this.showDeclinedEffDate();
    }
    else {
      this.router.navigate(['information']);
    }
  }

  findAddress() {

    this.visible.InvalidItemsText = this.getInvalidItemsText('addressForm');

    if (this.visible.InvalidItemsText.length > 0) {
      this.openInvalidItemsModal();
      return;
    }

    if (this.input.RawAddressInput === '') {
      this.visible.FindAddressValidation = true;
      return;
    }

    this.visible.FindAddressValidation = false;

    this.visible.Spinner = true;

    let addressAsString: string;

    if (this.input.AutoCompletePlace !== undefined &&
      this.input.AutoCompletePlace !== null &&
      this.input.AutoCompletePlace.address_components !== undefined) {
      addressAsString = this.getComponentShortName(this.input.AutoCompletePlace.address_components, "street_number") +
        " " +
        this.getComponentShortName(this.input.AutoCompletePlace.address_components, "route") +
        " " +
        this.getComponentCityName(this.input.AutoCompletePlace.address_components, true) +
        " " +
        this.getComponentShortName(this.input.AutoCompletePlace.address_components, "administrative_area_level_1") +
        " " +
        this.getComponentShortName(this.input.AutoCompletePlace.address_components, "postal_code");
    } else {
      addressAsString = this.input.RawAddressInput;
    }

    this.homeService.geocode(addressAsString).subscribe(geocodeResult => {

      console.log(geocodeResult);
      if (geocodeResult === null) {
        this.showPropertyNotFound();
        this.visible.Spinner = false;
        return;
      }
      var apiResult = geocodeResult.Data;
      console.log(geocodeResult.Data);

      this.stateService.stateByName(this.getComponentLongName(apiResult.results[0].address_components, "administrative_area_level_1")).subscribe(item => {
        if (item.Success) {
          const address: IAddress = {
            Address1: this.getComponentLongName(apiResult.results[0].address_components, "street_number") + " " + this.getComponentLongName(apiResult.results[0].address_components, "route"),
            Address2: this.getComponentLongName(apiResult.results[0].address_components, "subpremise"),
            City: this.getComponentCityName(apiResult.results[0].address_components),
            State: item.Data,
            Zip: this.getComponentLongName(apiResult.results[0].address_components, "postal_code"),
            County: this.getComponentLongName(apiResult.results[0].address_components, "administrative_area_level_2"),
            Longitude: apiResult.results[0].geometry.location.lng,
            Latitude: apiResult.results[0].geometry.location.lat,
            Elevation: apiResult.elevation
          };

          this.homeService.validateAddress(address).subscribe(validateResult => {
            if (validateResult == null || address.County === undefined || address.County === '') {
              this.visible.Spinner = false;
              this.showPropertyNotFound();
            } else {
              if (validateResult.DefaultAddress === false) {
                this.data.SessionModel.riskData = address;
                this.createAddressAndSubmit(address, true);
              } else {
                this.visible.Spinner = false;
                this.showPropertyNotFound();
              }
            }
          }, error => {
            this.visible.Spinner = false;
            if (error.status !== 401) {
              Swal.fire({
                icon: 'error',
                title: 'Something went wrong',
                text: 'Please try again later'
              });
            }

          });
        }
      })
    }, error => {
      this.visible.Spinner = false;
      if (error.status !== 401) {
        Swal.fire({
          icon: 'error',
          title: 'Something went wrong',
          text: 'Please try again later'
        });
      }
    });
  }

  createAddressAndSubmit(address: IAddress, flgAddressfound: boolean) {
    if (!flgAddressfound) {
      this.visible.InvalidItemsText = this.getInvalidItemsText('propNotFoundForm');

      if (this.visible.InvalidItemsText.length > 0) {
        this.openInvalidItemsModal();
        return;
      }
    }

    this.visible.Spinner = true;
    this.homeService.createAddress(address).subscribe(createAddressResult => {

      let strippedCounty = address.County.replace('County', '').trim();

      if (this.data.InactiveCounties == undefined || this.data.QuoteableStates == undefined) {
         this.visible.Spinner = false;
        Swal.fire({
          icon: 'error',
          title: 'Something went wrong',
          text: 'Please try again later'
        });
        return;
      }

      if (this.data.InactiveCounties.length > 0) {

        if (this.data.InactiveCounties.filter(x => x.Name.toLowerCase() === strippedCounty.toLowerCase()).length > 0) {
          this.visible.Spinner = false;
          this.moratoriumService.getMoratoriumMessage().subscribe(result => {
            var message = result.Message as string;
             message == "" ? "You cannot bind in this county due to a Moratorium in effect." : message;
            Swal.fire({
              icon: 'error',
              title: 'Moratorium',
              text: message
            });
          });

          return;
        }
      }

      if (this.data.QuoteableStates.filter(x => x.Id === address.State.Id).length === 0) {
        this.visible.Spinner = false;
        Swal.fire({
          icon: 'error',
          title: 'State Error',
          text: `Thank you for your interest! Twico is not currently writing in ${address.State.Name}.`
        });
        return;
      }

      this.data.SessionModel.riskAddressId = createAddressResult.Data.Id;
      this.data.SessionModel.riskData = address;

      /*this.homeService.swissReRate(this.data.SessionModel.riskAddressId).subscribe(res => {

      });*/
      this.veriskService.ratePPC(address, createAddressResult.Data.Id).subscribe(veriskPpcResult => {
        const ppcResult = veriskPpcResult.Data;
        this.visible.Spinner = false;

        if (ppcResult.Error === false) {
          this.data.SessionModel.flgTier1 = ppcResult.FlagTier1;
          this.data.SessionModel.flgTier2 = ppcResult.FlagTier2;
          this.data.SessionModel.flgTier3 = ppcResult.FlagTier3;
          this.data.SessionModel.excludeWind = this.excludeWind(ppcResult.FlagTier1, strippedCounty, String(address.Zip));
          this.data.SessionModel.ppc = ppcResult.PPC;

          if (this.propertyNotFoundModalReference !== undefined) {
            this.propertyNotFoundModalReference.close();
          }

          if (ppcResult.SplitPPC === false) {
            sessionStorage.setItem("model", JSON.stringify(this.data.SessionModel));

            this.visible.EffectiveDate = true;
            this.data.Progress = 10;
          } else {
            this.showDistanceToHydrant();

            this.data.WorkingVeriskPpcId = ppcResult.PPCId;
            this.data.WorkingAddress = address;
          }

        } else {
          this.visible.Spinner = false;
          Swal.fire({
            icon: 'error',
            title: 'PPC Error',
            text: ppcResult.StatusMsg
          });
        }
      }, error => {
        this.visible.Spinner = false;
        Swal.fire({
          icon: 'error',
          title: 'Something went wrong',
          text: 'Please try again later'
        });
      });

    }, error => {
      this.visible.Spinner = false;
      Swal.fire({
        icon: 'error',
        title: 'Something went wrong',
        text: 'Please try again later'
      });
    });
    this.titleService.setTitle(address.Address1);
  }

  excludeWind(flgTier1: boolean, county: string, zip: string): boolean {

    const optionalCounties = ['Aransas', 'Brazoria', 'Calhoun', 'Chambers', 'Jefferson', 'Kennedy', 'Kleberg', 'Matagorda', 'Nueces', 'Refugio', 'San Patricio'];
    const tierOneZips = ['77571', '77507', '77586'];
    const tierOneCounties = ['Galveston', 'Willacy'];

    if (tierOneCounties.indexOf(county) >= 0) {
      return true;
    }

    if (optionalCounties.indexOf(county) < 0 && tierOneZips.indexOf(zip) >= 0 && flgTier1) {
      return true;
    }

    if (tierOneZips.indexOf(zip) >= 0 && flgTier1) {
      return true;
    }

    return false;
  }

  getComponentShortName(components, typeName: string) {
    return (components.filter(component => component.types[0] == typeName)[0])
      ? (components.filter(component => component.types[0] == typeName)[0]).short_name
      : "";
  }

  getComponentLongName(components, typeName: string) {
    return (components.filter(component => component.types[0] == typeName)[0])
      ? (components.filter(component => component.types[0] == typeName)[0]).long_name
      : "";
  }

  getComponentCityName(components, isShortName = false){
    let component = null;

    if(components.filter(component => component.types[0] == 'locality')[0]){
      component = components.filter(component => component.types[0] == 'locality')[0];
    }
    else if(components.filter(component => component.types[0] == 'neighborhood')[0]){
      component = components.filter(component => component.types[0] == 'neighborhood')[0];
    }

    if(component == null){
      return "";
    }

    return isShortName ? component.short_name : component.long_name;
  }

  reload() {
    this.input = new HomeInput;
    this.visible = new HomeVisible;
    this.data = new HomeData;
    this.getClientToken();
  }

  isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key))
        return false;
    }
    return true;
  }

  openInvalidItemsModal() {
    this.invalidItemsModalReference = this.modalService.open(this.invalidItemsModal, { ariaLabelledBy: 'modal-basic-title', size: 'lg' });
    this.invalidItemsModalReference.result.then(x => {

    }, () => {

    });
  }

  showInvalidItems() {
    this.invalidItemsModalReference.close();
  }

  getInvalidItemsText(formId: string): string[] {
    this.visible.InvalidItems = [];
    this.visible.InvalidItemsText = [];
    this.visible.InvalidItems = this.validateService.getInvalidFormElements(formId);

    if (this.visible.InvalidItems.length > 0) {
      this.visible.InvalidItemsText = this.displayInvalidItemsText(this.visible.InvalidItems);
    }

    return this.visible.InvalidItemsText;
  }

  displayInvalidItemsText(invalidItems: string[]): string[] {
    this.visible.InvalidItemsText = [];

    if (invalidItems.includes('addressInput')) {
      this.visible.InvalidItemsText.push('Address');
    }
    if (invalidItems.includes('EffectiveDate')) {
      this.visible.InvalidItemsText.push('Effective Date');
    }
    if (invalidItems.includes('hydrantDistance')) {
      this.visible.InvalidItemsText.push('Hydrant Distance');
    }
    if (invalidItems.includes('notFoundAddress1')) {
      this.visible.InvalidItemsText.push('Address');
    }
    if (invalidItems.includes('notFoundCity')) {
      this.visible.InvalidItemsText.push('City');
    }
    if (invalidItems.includes('notFoundState')) {
      this.visible.InvalidItemsText.push('State');
    }
    if (invalidItems.includes('notFoundZip')) {
      this.visible.InvalidItemsText.push('Zip');
    }
    if (invalidItems.includes('notFoundCounty')) {
      this.visible.InvalidItemsText.push('County');
    }

    return this.visible.InvalidItemsText;
  }

}
