import { Injectable, Inject } from '@angular/core';
import { IIdentifier } from '../_shared/interfaces/IIdentifier';
import { ILogin } from "../_shared/interfaces/ILogin";
import { Router, ActivatedRoute } from "@angular/router";
import { BehaviorSubject, Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { IPropertyDetail } from '../_shared/interfaces/IPropertyDetail';
import { CustomResponse } from '../_shared/classes/CustomResponse';
import { BaseService } from '../services/base-service.service';
import { map, switchMap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class RoofCoverageService extends BaseService {

  private roofMaterialMappingsSubject: BehaviorSubject<Map<string, { RCV: Map<string, number>, ACV: Map<string, number>, Ineligible: number }>> = new BehaviorSubject<Map<string, { RCV: Map<string, number>, ACV: Map<string, number>, Ineligible: number }>>(new Map());
  public roofMaterialMappings$: Observable<Map<string, { RCV: Map<string, number>, ACV: Map<string, number>, Ineligible: number }>> = this.roofMaterialMappingsSubject.asObservable();

  arcApiBaseUrl;
  fortunaApiBaseUrl: string;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private http: HttpClient, @Inject('FORTUNA_BASE_URL') fortunaApiBaseUrl: string) {
    super();
    // @Inject('ARC_API_URL') arcApiBaseUrl: string,
    //this.arcApiBaseUrl = arcApiBaseUrl;
    this.fortunaApiBaseUrl = fortunaApiBaseUrl;
    this.parseReferenceTable();
  }

  parseReferenceTable(): void {
    this.http.get<any[]>(this.fortunaApiBaseUrl + "api/RoofRequirement/GetRoofRequirementLogicTable").subscribe(
      (referenceTable: any) => {
        const roofMaterialMappings = this.parseTable(referenceTable.Data);
        this.roofMaterialMappingsSubject.next(roofMaterialMappings);
      },
      (error: any) => {
        console.error('Error fetching reference table:', error);
      }
    );
  }

  private parseTable(referenceTable: any[]): Map<string, { RCV: Map<string, number>, ACV: Map<string, number>, Ineligible: number }> {
    const roofMaterialMappings: Map<string, { RCV: Map<string, number>, ACV: Map<string, number>, Ineligible: number }> = new Map();

    referenceTable.forEach(row => {
      const roofMaterial = row['RoofMaterial'];
      const RCV = row['RCV'];
      const ACV = row['ACV'];
      const Ineligible = row['Ineligible'];
  
      if (RCV !== 'ineligible' || ACV !== 'ineligible' || Ineligible !== 'ineligible') {
        const RCVRange = RCV !== 'ineligible' ? this.parseAgeRange(RCV) : null;
        const ACVRange = ACV !== 'ineligible' ? this.parseAgeRange(ACV) : null;
        const IneligibleValue = Ineligible !== 'ineligible' ? parseInt(Ineligible) : null;
  
        roofMaterialMappings.set(roofMaterial, {
          RCV: RCVRange,
          ACV: ACVRange,
          Ineligible: IneligibleValue
        });
      } else {
        roofMaterialMappings.set(roofMaterial, {
          RCV: null,
          ACV: null,
          Ineligible: null
        });
      }
    });
    console.log(roofMaterialMappings);
    return roofMaterialMappings;
  }

  private parseAgeRange(range: string): Map<string, number> {
    const [min, max] = range.split('-').map(Number);
    const ageRangeMap = new Map<string, number>();
    ageRangeMap.set('min', min);
    ageRangeMap.set('max', max);
    return ageRangeMap;
  }

  public getRoofCoverageType(roofMaterial: string, age: number): Observable<string> {
    console.log(roofMaterial);
    return this.roofMaterialMappings$.pipe(
      take(1), 
      switchMap((roofMaterialMappings: Map<string, { RCV: Map<string, number>, ACV: Map<string, number>, Ineligible: number }>) => {
        const roofMapping = roofMaterialMappings.get(roofMaterial);
        console.log(roofMapping)
        if (!roofMapping) {
          return of('Ineligible');
        }       
        // Check RCV coverage type
        const rcvAgeMap = roofMapping['RCV'];
        if (rcvAgeMap && rcvAgeMap.has('min') && rcvAgeMap.has('max')) {
          const minAgeRCV = rcvAgeMap.get('min');
          const maxAgeRCV = rcvAgeMap.get('max');
          if (age >= minAgeRCV && age <= maxAgeRCV) {
            return of('RCV');
          }
        }
        // Check ACV coverage type
        const acvAgeMap = roofMapping['ACV'];
        if (acvAgeMap && acvAgeMap.has('min') && acvAgeMap.has('max')) {
          const minAgeACV = acvAgeMap.get('min');
          const maxAgeACV = acvAgeMap.get('max');
          if (age >= minAgeACV && age <= maxAgeACV) {
            return of('ACV');
          }
        }
  
        return of('Ineligible');
      })
    );
  }

  // populateRoofMaterialMappings(): void {
  //   this.http.get<any>(this.fortunaApiBaseUrl + "api/RoofRequirement/GetRoofRequirementLogicTable").subscribe(data => {
  //     this.roofMaterialMappings.clear();
  //     for (const row of data) {
  //       const roofMaterial = row[0]; 
  //       const rcvData = row[1]; 
  //       const acvData = row[2]; 
  //       const ineligibleData = row[3]; 
  
  //       const ageRangesMap = new Map<string, string>();
  
  //       this.populateAgeRanges(rcvData, ageRangesMap, 'RCV');
  //       this.populateAgeRanges(acvData, ageRangesMap, 'ACV');
  //       this.populateAgeRanges(ineligibleData, ageRangesMap, 'Ineligible');
  
  //       this.roofMaterialMappings.set(roofMaterial, ageRangesMap);
  //     }
  //   });
  // }

  // private populateAgeRanges(data: string, ageRangesMap: Map<string, string>, key: string): void {
  //   const ageRanges = data.split(','); // Assuming age ranges are comma-separated
  //   for (const range of ageRanges) {
  //     const [start, end] = range.split('-').map(Number);
  //     const ageRange = end ? `${start}-${end}` : `${start}+`;
  //     ageRangesMap.set(ageRange, key);
  //   }
  // }

  // getRoofCoverageType(roofMaterial: string, age: number): string {
  //   const ageRange = this.calculateAgeRange(age);
  //   const coverageMap = this.roofMaterialMappings.get(roofMaterial);
  //   if (coverageMap) {
  //     const coverageType = coverageMap.get(ageRange);
  //     return coverageType ? coverageType : 'Ineligible';
  //   }
  //   return 'Ineligible';
  // }

  // private calculateAgeRange(age: number): string {
  //   if (age <= 10) {
  //     return '0-10';
  //   } else if (age <= 20) {
  //     return '11-20';
  //   } else {
  //     return '21';
  //   }
  // }
}


