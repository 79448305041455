import { Injectable } from '@angular/core';
import { Inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { ILossHistory, ILossItem } from '../_shared/interfaces/ILossHistory';
import { CustomResponse } from '../_shared/classes/CustomResponse';
import { BaseService } from '../services/base-service.service';

@Injectable({
  providedIn: 'root',
})

export class LossHistoryService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }

  get(id: number): Observable<CustomResponse<any>> {
    return this.http.get<CustomResponse<any>>(this.buildXenithEndpoint(`/HighValueLossHistory/${id}`), this.GetAuthHeaderJson());
  }

  getByQuote(quoteId: number): Observable<CustomResponse<any>> {
    return this.http.get<CustomResponse<any>>(this.buildXenithEndpoint(`/HighValueLossHistory/getLossHistoryByQuoteId/${quoteId}`), this.GetAuthHeaderJson());
  }

  getEligibilityRequirements(quoteId: number): Observable<CustomResponse<any>> {
    return this.http.get<CustomResponse<any>>(this.buildXenithEndpoint(`/HighValueLossHistory/getEligibilityRequirements/${quoteId}`), this.GetAuthHeaderJson());
  }

  save(item: ILossHistory) {
    return this.http.post<CustomResponse<any>>(this.buildXenithEndpoint(`/HighValueLossHistory`), item, this.GetAuthHeaderJson());
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.log("error in service");
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
