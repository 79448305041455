import { ICredits } from "../_shared/interfaces/ICredits"


export class CreditsModel {
  Detail: ICredits = {
    Id: null,
    Alarms: false,
    Sprinklers: false,
    LeakDetection: false,
    HvacUpdate: null,
    WiringUpdate: null,
    PlumbingUpdate: null,
  }

  Validation: any = {
    
  }
}
