import { NgbDate, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class NgbDateCustomParserFormatter extends NgbDateParserFormatter {
  constructor() {
    super();
  }

  format(date: NgbDateStruct): string {   
    if (date === null || date === undefined || date.year === null || date.month === null || date.day === null) {
      return '';
    }

    let monthString = String(date.month);
    if (monthString.length < 2)
      monthString = "0" + monthString;

    let dayString = String(date.day);
    if (dayString.length < 2)
      dayString = "0" + dayString;

     return `${monthString}/${dayString}/${String(date.year)}`;
    //return `${dayString}/${monthString}/${String(date.year)}`;
  }

  parse(value: string): NgbDateStruct {    
    if (!value) {
      return null;
    }

    let result: NgbDateStruct = null;

    if (value.includes("/")) {
      result = {
        year: +value.split('/')[2],
        month: +value.split('/')[0],
        day: +value.split('/')[1]
      };
    } else {
      let day: number = 0;
      let month: number = 0;
      let year: number = 0;

      month = +value.slice(0, 2);
      day = +value.slice(2, 4);
      year = +value.slice(4);

      result = {
        year: year,
        month: month,
        day: day
      }
    }

    return result;
  }

  parseToNgbDate(value: string): NgbDate{
    if(!value || !value.includes('/')){
      return null;
    }
    const splittedDate = value.split('/');
    return new NgbDate(Number.parseInt(splittedDate[2]), Number.parseInt(splittedDate[1]), Number.parseInt(splittedDate[0]));
  }
}
