import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/services/base-service.service';
import { ILogin } from '../models/ILogin';
import { HttpClient } from '@angular/common/http';
import { ILoginResult } from '../models/ILoginResult';
import { Observable } from 'rxjs';
import { SessionStorageUtils } from 'src/app/util/session-storage';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/services/notification.service';
import { Router } from '@angular/router';
import { Agency } from '../components/select-agency-modal/model/IAgency';
import { SelectAgencyModalComponent } from '../components/select-agency-modal/select-agency-modal.component';
import { SelectAgencyData } from '../components/select-agency-modal/model/ISelectAgencyData';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Injectable()
export class UserService extends BaseService{

  constructor(
    private client: HttpClient,
    private notificationService: NotificationService,
    private router: Router,
    private dialog: MatDialog) { 
    super()
  }

  public login(login: ILogin): Observable<Array<ILoginResult>>{
    return this.client.get<Array<ILoginResult>>(this.buildXenithEndpoint(`/Login?username=${login.Username}&password=${login.Password}`));
  }

  public refreshToken(){
    if(this.isTokenExpired()){
      this.logout();
      return;
    }

    const user = SessionStorageUtils.getUser();

    return this.client.post<Array<ILoginResult>>(this.buildXenithEndpoint(`/Login?agentId=${user.AgentId}`),  JSON.stringify(user.Token.RefreshToken), this.GetAuthHeaderJson())
    .pipe((interceptResult) => {
      interceptResult.subscribe(
        (result) => this.processRefreshTokenSuccess(result),
        (error) => console.error(`Refresh Token Failed ${error.error.message}`)
      )
      return interceptResult;
    });
  }

  private processRefreshTokenSuccess(results: Array<ILoginResult>){
    if(!results || results.length <= 0){
      return;
    }

    if(results.length == 1){
      SessionStorageUtils.refreshUser(results[0]);
    }
    else if(results.length > 1){
      const currentUser = SessionStorageUtils.getUser();
      const refreshedUser = results.find(x => x.AgentId == currentUser.AgentId);
      if(refreshedUser){
        SessionStorageUtils.refreshUser(refreshedUser);
      }
    }
  }

  public logout(){
    SessionStorageUtils.wipeStorage();
    this.router.navigate(['/user/login']);
  }

  public isTokenExpired(): boolean {
    const token = SessionStorageUtils.getUserToken();
    return token == undefined || token == null || Date.now() >=  new Date(token.ExpirationDate).getTime();
  }

  public processAgentLogin(loginResult: ILoginResult, allowedRoles: string[]): boolean{
    if(loginResult.TempPassword){
      const expirationMessage = `<label>Please change your password in the <a href="${environment.xenithUrl}"> TWFG Product Platform</a> to continue. </label>`;
      this.notificationService.showInformationMessage("Password Expired", expirationMessage, {
        HTMLContent: true
      });      
      return false;
    }

    if(!this.isUserInRoles(loginResult.Roles, ...allowedRoles)){
      this.notificationService.showErrorMessage("Not Authorized", 'User not authorized to access Hiscox High Value system.');
      return false;
    }
    else if(!loginResult.AgentLicenseExists){
      this.notificationService.showErrorMessage("Not Authorized", 'Agent License is no longer valid.');
      return false;
    }
    SessionStorageUtils.saveUserIdentifier(loginResult);   
    return true;
  }

  public selectAgency(results: Array<ILoginResult>): MatDialogRef<SelectAgencyModalComponent, Agency>{
    var agencies = results.map((result) => {
      return {
        Id: result.AgentId,
        Name: result.Agency,
        AgentLicenseExists: result.AgentLicenseExists
      } as Agency
    });

    return this.dialog.open<SelectAgencyModalComponent, SelectAgencyData, Agency>(SelectAgencyModalComponent, {
      data: {
        Agencies: agencies
      } as SelectAgencyData
    });    
  }
  
  public isUserInRoles(userRoles: string[], ...roles: string[]): boolean{
    for(var role of roles){
      if(userRoles.includes(role)){
        return true;
      }
    }
    return false;
  }

  public isLoggedUserInRoles(...roles: string[]): boolean{
    var user = SessionStorageUtils.getUser();

    if(!user){
      return false;
    }

    return this.isUserInRoles(user.Roles, ...roles);
  }
}
