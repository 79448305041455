import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { PersonalCoverageService } from '../_helpers/personal-coverage.service';
import { QuoteService } from '../_helpers/quote.service';
import { QuoteUpdate } from '../_shared/classes/QuoteUpdate';
import { Validation } from '../_shared/classes/Validation';
import { PersonalCoverageItem, PersonalCoverageModel } from './personal-coverage.class';
import { UserService } from '../user/services/user.service';
import { FormBaseComponent } from '../components/shared/base-components/form-base.component';


@Component({
  selector: 'app-personal-coverage',
  templateUrl: './personal-coverage.component.html',
  styleUrls: ['./personal-coverage.component.css']
})
export class PersonalCoverageComponent extends FormBaseComponent implements OnInit, AfterViewInit {

  @ViewChildren('Underwriter') inputRef : ElementRef[];
  entity = new PersonalCoverageModel;
  itemType = new PersonalCoverageItem;
  itemsList: PersonalCoverageItem[] = [];
  today: Date = new Date();
  jewerlyOpt: number[] = [10000, 15000, 25000, 50000];
  fineArtOpt: number[] = [0, 10000, 15000, 25000, 50000, 75000];
  itemsOpt: string[] = ["Camaras", "Fine Arts", "Furs", "Golfer's Equipment", "Jewerly", "Musical Instrument", "Postage Stamps", "Rare and Currentr Coins", "Silverware", "Other"];
  index: number;
  continue: boolean = true;
  validation: Validation = JSON.parse(sessionStorage.getItem('validation')) ?? new Validation;
  quoteId: number = + (sessionStorage.getItem('quoteId') ?? 0);
  quoteNumber: string = sessionStorage.getItem('quoteNumber').replace(/"/g, '');
  alreadyCreated = false;
  itemClassError = false;
  descriptionError = false;
  valueError = false;
  riskAddressHeader: string;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    private service: PersonalCoverageService,
    private quoteService: QuoteService,
    private titleService: Title,
    private userService: UserService
  ){
    super();
  }
  ngAfterViewInit(): void {
    super.configureCheckForChange(this.inputRef);
  }


  ngOnInit(): void {
    this.userService.refreshToken();
    this.titleService.setTitle("Hiscox High Value - Personal Coverage Options");
    this.setRiskAddressHeader();
    if (this.quoteId != 0 && this.quoteId != null && this.quoteId != undefined) {
      this.service.getByQuote(this.quoteId).subscribe(item => {
        let data = item.Data;
        let check = item.Success;
        if (check && data != null) {
          this.alreadyCreated = true;
          this.entity.Detail = data;
          if(data.PersonalCoverageItems != null){
            data.PersonalCoverageItems.forEach(element => {
              let item = new PersonalCoverageItem;
              item.Item = element;
              console.log(item.Item, element)
              this.itemsList.push(item);
              console.log(item)
            });
            this.itemType = new PersonalCoverageItem;
            console.log(this.itemsList)
            this.entity.Validation.AddItems = true;
          }
          else{
            this.itemsList = [];
            this.entity.Validation.AddItems = false;
          }
        }
      })
    }
    this.mouseHandlers();
  }

  personalCoverage() {
    this.itemsList.forEach(item => {
      this.entity.Detail.PersonalCoverageItems.push(item.Item);
    })
    //console.log(this.itemsList, this.entity.Detail.PersonalCoverageItems)
    sessionStorage.setItem("PersonalCoverage", JSON.stringify(this.entity.Detail));
    this.service.save(this.entity.Detail).subscribe(item => {
      let data = item.Data;
      let check = item.Success;
      if (check) {
        if (!this.alreadyCreated) {
          let value = new QuoteUpdate;
          value.data.PersonalCoverageId = data;
          value.data.PersonalCoverageUpdate = true;
          value.data.QuoteId = this.quoteId;
          console.log('quoteId: ' + this.quoteId);
          this.quoteService.update(value.data).subscribe(result => {
            let check = result.Success;
            if (check) {
              this.router.navigate(['loss-history']);
            }
          })
        }
        else {
          this.router.navigate(['loss-history']);
        }
      }
    })
  }

  checkTotalValue(lastValue: number): number
  {
    let totalValue = lastValue;
    this.itemsList.forEach(item => {
      totalValue = totalValue + item.Item.Insurence;
    })

    return totalValue;
  }

  itemAdd() {
    console.log(this.itemType.Item)
    if (this.itemType.Item.Description == "" || this.itemType.Item.Description == null || this.itemType.Item.Description == undefined) { this.descriptionError = true } else { this.descriptionError = false }
    if (this.itemType.Item.Insurence == 0 || this.itemType.Item.Insurence == null || this.itemType.Item.Insurence == undefined) { this.valueError = true } else { this.valueError = false }
    if (this.itemType.Item.Class == "" || this.itemType.Item.Class == null || this.itemType.Item.Class == undefined) { this.itemClassError = true } else { this.itemClassError = false }
    var totalItemsValue = this.checkTotalValue(this.itemType.Item.Insurence);
   
    if (this.descriptionError || this.valueError || this.itemClassError) {
      return;
    }
    if (totalItemsValue > 250000) {
      Swal.fire({
        icon: 'error',
        title: 'Exceded Insure total amount',
        text: 'The total amount of items insure can not be bigger than $250.000'
      });
      return;
    }

    if (this.index != -1) {
      this.deleteItem(this.index);

      this.itemsList.push(this.itemType);
      this.itemType = new PersonalCoverageItem;
      this.index = -1;
    } else {

      this.itemsList.push(this.itemType);
      this.itemType = new PersonalCoverageItem;
    }
  }

  deleteItem(ind: number) {
    this.itemsList.splice(ind, 1);
  }

  editItem(ind: number) {
    let item = this.itemsList[ind];
    
    this.index = ind;
    this.itemType = item;
  }

  onPrevious() {
    this.router.navigate(['coverage']);
  }
}
