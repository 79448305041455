import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChildren } from '@angular/core';
import { FormBuilder, FormControlName, FormGroup, Validators } from '@angular/forms';
import { FormBaseComponent } from 'src/app/components/shared/base-components/form-base.component';
import { Agency } from './model/IAgency';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SelectAgencyData } from './model/ISelectAgencyData';
import Swal from 'sweetalert2';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-select-agency-modal',
  templateUrl: './select-agency-modal.component.html',
  styleUrls: ['./select-agency-modal.component.css']
})
export class SelectAgencyModalComponent extends FormBaseComponent implements OnInit, AfterViewInit {
  
  @ViewChildren(FormControlName, { read: ElementRef }) 
  formInputElements: ElementRef[];
  selectAgencyForm: FormGroup;
  agencies: Array<Agency>;

  constructor(private fb: FormBuilder,
    public dialogRef: MatDialogRef<SelectAgencyModalComponent>, 
    private notificationService: NotificationService,
    @Inject(MAT_DIALOG_DATA) public data: SelectAgencyData) { 
    super();
    
    this.validationMessages = {
      agencyId: {
        required: 'You must select an agency to continue',
      }
    };

    this.agencies = data.Agencies;

    super.configureValidationMessages(this.validationMessages);
  }
 

  ngOnInit(): void {
    this.selectAgencyForm = this.fb.group({
      agencyId: [null, Validators.required]
    })
  }

  ngAfterViewInit(): void {
    super.configureFormValidation(this.formInputElements, this.selectAgencyForm);
  }

  selectAgency(){
    if(this.selectAgencyForm.invalid){
      this.selectAgencyForm.markAllAsTouched();
      this.validateForm(this.selectAgencyForm);
      return;
    }

    var selectedAgency = this.agencies.find(x => x.Id == this.selectAgencyForm.controls['agencyId'].value as number);

    if(!selectedAgency.AgentLicenseExists){
      this.notificationService.showErrorMessage( 'Not Authorized', 'Agent License is no longer valid.')
      return;
    }

    this.dialogRef.close(selectedAgency);
  }

}
