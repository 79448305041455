import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IIdentifier } from '../_shared/interfaces/IIdentifier';
import { ILogin } from "../_shared/interfaces/ILogin";
import { Router, ActivatedRoute } from "@angular/router";

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  arcApiBaseUrl;

  constructor(private http: HttpClient, private router: Router, private activatedRoute: ActivatedRoute) {// @Inject('ARC_API_URL') arcApiBaseUrl: string,
    //this.arcApiBaseUrl = arcApiBaseUrl;
  }

  getAccessTokenFromUrl(): IIdentifier {
    const userParam = this.activatedRoute.snapshot.queryParamMap.get("u");

    if (userParam == null) return null;

    let rawIdentifier = JSON.parse(window.atob(userParam));

    const identifier: IIdentifier = {
      AccessToken: rawIdentifier.user_token,
      AgentId: rawIdentifier.agentId,
      Agency: rawIdentifier.agency,
      AgentLicenseExists: true, //todo where should we get this from?
      ClientInitiated: false, //todo where should we get this from?
      ExpirationDate: rawIdentifier.token_expiration,
      FullName: rawIdentifier.name,
      Permissions: rawIdentifier.user_permissions,
      RefreshToken: rawIdentifier.refreshToken,
      Roles: null, //todo where should we get this from?
      UserId: rawIdentifier.userId,
      TempPassword: false, //todo where should we get this from?
      StateAccess:null,
      AgencyId: null,
    };
    return identifier;
  }

  getAccessToken(): IIdentifier {
    const identifier = JSON.parse(sessionStorage.getItem('identifier'));

    if (identifier == null) return null;

    const result: IIdentifier =
    {
      AccessToken: identifier.AccessToken,
      Agency: identifier.Agency,
      AgentId: identifier.AgentId,
      AgentLicenseExists: identifier.AgentLicenseExists,
      ExpirationDate: identifier.ExpirationDate,
      FullName: identifier.FullName,
      RefreshToken: identifier.RefreshToken,
      Roles: identifier.Roles,
      Permissions: identifier.Permissions,
      UserId: identifier.UserId,
      TempPassword: identifier.TempPassword,
      ClientInitiated: identifier.ClientInitiated,
      StateAccess:identifier.StateAccess,
      AgencyId: null
    };

    return result;
  }

  authorized(): boolean {
    const token = this.getAccessToken();
    return token != null && new Date(token.ExpirationDate).getTime() > Date.now();
  }

  login(model: ILogin) {   
    return this.http.get<string>(this.arcApiBaseUrl + "/api/login?username=" + model.email + "&password=" + model.password);    
  }

  clientLogin() {
    return this.http.get<string>(this.arcApiBaseUrl + "/api/ClientLogin");
  }

  getAgency(agencyNumber) {
    return this.http.get(this.arcApiBaseUrl + "/api/agency/number/" + agencyNumber);
  }

  refreshLogin() {
    if (this.authorized()) {
      const token : IIdentifier = this.getAccessToken();
      const options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };

      this.http.post(this.arcApiBaseUrl + "/api/login?agentId=" + token.AgentId, JSON.stringify(token.RefreshToken), options).subscribe((loginResult: IIdentifier[]) => {
        if (Object.keys(loginResult).length === 1) {
          if (token.ClientInitiated) {
            
            token.AccessToken = loginResult[0].AccessToken;
            token.ExpirationDate = loginResult[0].ExpirationDate;
            token.RefreshToken = loginResult[0].RefreshToken;
            sessionStorage.setItem('identifier', JSON.stringify(token));
          } else {
            let identifier: IIdentifier = loginResult[0] as IIdentifier;
            identifier.ClientInitiated = false;
            sessionStorage.setItem('identifier', JSON.stringify(identifier));
          }
        } else {
          loginResult.forEach(x => {
            if (x.AgentId === token.AgentId) {
              let identifier: IIdentifier = x as IIdentifier;
              identifier.ClientInitiated = false;
              sessionStorage.setItem('identifier', JSON.stringify(identifier));
            }
          });
        }
      });
    }
  }

  resetPassword(email) {    
    return this.http.get<string>(this.arcApiBaseUrl + "/api/login/resetpassword?email=" + email);
  }

}

