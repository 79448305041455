import { AfterViewInit, Component, ElementRef, EventEmitter, Inject, OnInit, Output, ViewChildren } from '@angular/core';
import { FormBuilder, FormControlName, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RejectQuoteData } from './model/reject-quote-data';
import { RejectQuoteModalData } from './model/reject-quote-modal-data';
import { RejectQuoteService } from './service/reject-quote.service';
import { FormBaseComponent } from '../../shared/base-components/form-base.component';
import { RejectionQuoteReason } from './model/rejection-quote-reason';
import { CustomResponse } from 'src/app/_shared/classes/CustomResponse';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-reject-quote',
  templateUrl: './reject-quote.component.html',
  styleUrls: ['./reject-quote.component.css']
})
export class RejectQuoteComponent extends FormBaseComponent implements OnInit, AfterViewInit {

  @ViewChildren(FormControlName, { read: ElementRef }) 
  formInputElements: ElementRef[];

  @Output()
  loading = new EventEmitter<boolean>();

  rejectQuoteForm: FormGroup;
  rejectionReasons: RejectionQuoteReason[];

  constructor(
    private notificationService: NotificationService,
    private fb: FormBuilder,
    private service: RejectQuoteService,
    public dialogRef: MatDialogRef<RejectQuoteComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: RejectQuoteModalData) { 
      super()

      this.validationMessages = {
        RejectionReasonId: {
          required: 'Rejection Reason is mandatory',
        },
        RejectionDescription: {
          required: 'Rejection Description is mandatory',
        }
      };
  
      super.configureValidationMessages(this.validationMessages);
    }

  ngAfterViewInit(): void {
    super.configureFormValidation(this.formInputElements, this.rejectQuoteForm);
  }

  ngOnInit(): void {
    this.rejectQuoteForm = this.fb.group({
      QuoteId: [this.data.QuoteId, Validators.required],
      RejectionReasonId: [null, Validators.required],
      RejectionDescription: ['', Validators.required]
    });

    this.getRejectionReasons();
  }

  getRejectionReasons(){
    this.showLoadingSpinner();
    this.loading.emit(true);
    this.service
        .getRejectionReasons()
        .subscribe(
          success => {
            this.hideLoadingSpinner();
            this.loading.emit(false);
            if(success.Success)
              this.rejectionReasons = success.Data
          },
          error => {
            this.hideLoadingSpinner();
            this.loading.emit(false);
          }
        )
  }



  rejectQuote(){
    
    if(this.rejectQuoteForm.dirty && this.rejectQuoteForm.valid){
      this.showLoadingSpinner();
      this.loading.emit(true);
      let rejectQuoteData = {};
      rejectQuoteData = Object.assign({}, rejectQuoteData, this.rejectQuoteForm.value);
      this.service.rejectQuote(rejectQuoteData as RejectQuoteData)
        .subscribe(
          success => { this.processSuccess(success) },
          error => { this.processError(error) },          
        )
      return;
    }

    this.rejectQuoteForm.markAllAsTouched();
    this.validateForm(this.rejectQuoteForm);
  }

  processSuccess(result: CustomResponse<boolean>){
    this.hideLoadingSpinner();
    this.loading.emit(false);
    if(result && result.Success){
      this.notificationService.showSuccessMessage("Quote Rejected", `Quote was rejected successfully!`)
      .afterClosed()
      .subscribe(() => {
        this.dialogRef.close();
      });
    }
  }

  processError(error: any){
    this.hideLoadingSpinner();
    this.loading.emit(false);
    this.notificationService.showErrorMessage("Quote Rejected", `An error occurred rejecting the Quote`)
    .afterClosed()
    .subscribe(() => {
      this.dialogRef.close();
    });
  }
}
