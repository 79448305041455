import { Injectable } from '@angular/core';
import { Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { IState } from '../_shared/interfaces/IState';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { CustomResponse } from '../_shared/classes/CustomResponse';

@Injectable({
  providedIn: 'root'
})
export class StateService {
  arcApiBaseUrl;
  programId;
  fortunaApiBaseUrl;
  private url: string = "api/state/";

  constructor(private http: HttpClient, @Inject('ARC_API_URL') arcApiBaseUrl: string, @Inject('FORTUNA_BASE_URL') fortunaApiBaseUrl: string){//, @Inject('ARC_API_URL') arcApiBaseUrl: string, @Inject("PROGRAM_ID") programId: string) {
    this.arcApiBaseUrl = arcApiBaseUrl;
    this.programId = 1100;
    this.fortunaApiBaseUrl = fortunaApiBaseUrl;
  }

  stateByName(name: string): Observable<CustomResponse<any>> {
    if (name == null) return null;
    return this.http.get<CustomResponse<any>>(this.fortunaApiBaseUrl + this.url + 'name/' + name);
  }

  stateByCode(code: string): Observable<CustomResponse<any>> {
    if (code == null) return null;

    return this.http.get<CustomResponse<any>>(this.fortunaApiBaseUrl + this.url + 'code/' + code);
  }

  stateById(id: number): Observable<CustomResponse<any>> {
    if (id == null) return null;

    return this.http.get<CustomResponse<any>>(this.fortunaApiBaseUrl + this.url + 'id/' + id);
  }

  quotableStates(): Observable<CustomResponse<any>> {
    return this.http.get<CustomResponse<any>>(this.fortunaApiBaseUrl + this.url + 'quotable');
  }

  //not used right now, but useful as an example of how to pipe/filter an observable result.
  quotableStatesByFilter(): Observable<IState[]> {
    return this.http.get<IState[]>(this.arcApiBaseUrl + '/api/state').pipe(map(items => items.filter(x => x.FlgQuotable)));
  }

  compare(a, b) {
    
    const stateA = a.Code.toUpperCase();
    const stateB = b.Code.toUpperCase();

    let comparison = 0;
    if (stateA > stateB) {
      comparison = 1;
    } else if (stateA < stateB) {
      comparison = -1;
    }
    return comparison;
  }

  allStates(): Observable<CustomResponse<any>> {
    return this.http.get<CustomResponse<any>>(this.fortunaApiBaseUrl + this.url + 'all');
  };
}
