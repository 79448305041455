import { IPersonalCoverage, IPersonalCoverageItem } from "../_shared/interfaces/IPersonalCoverage"

export class PersonalCoverageModel {
  Detail: IPersonalCoverage = {
    Id: null,
    JewerlyFineArtCoverage: false,
    JewerlyBlanket: null,
    FineArtBlanket: null,
    PersonalCoverageItems: []
  }

  Validation: any = {
    AddItems: false
  }
}
export class PersonalCoverageItem {
  Item: IPersonalCoverageItem = {
    Id: null,
    Class: null,
    Description: null,
    Insurence: null,
    PersonalCoverageId: null,
    PersonalCoverage: {}
  }
}
