import { IUnderwriterModification } from "../_shared/interfaces/IUnderwriterModification";

export class UnderwriterModificationModel {
  Detail: IUnderwriterModification = {
    Id: null,
    ManualPremiumField: 0,
    WaterDamageDeductible: 0,
    WaterDamageSublimit: 0,  

  }
}
