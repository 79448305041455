import { IMortgagee } from "../_shared/interfaces/IMortgagee";
import { IPaymentAch } from "../_shared/interfaces/IPayment";

export class PaymentModel {
  Ach: IPaymentAch = {
    Id: null,
    FirstName: '',
    LastName: '',
    BankName: '',
    AccountNumber: '',
    RoutingNumber: '',
    AccountType: '',
    Recurring: false,
    Terms: false,
    QuoteId: 0
  };
  PrimaryMortgagee: IMortgagee = {
    Id: null,
    QuoteId: 0,
    Name: '',
    LoanNumber: null,
    FlgPrimary: true,
    FlgVoid: false,
    Street1: '',
    Street2: '',
    City: '',
    StateId: null,
    Zip: null,
    CreatedByUserId: null,
    UpdatedByUserId: null,
  };
  }
