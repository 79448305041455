import { IPropertyDetail } from "../_shared/interfaces/IPropertyDetail";

export class PropertyDetailModel {
  Detail: IPropertyDetail = {
    Id: null,
    OccupancyTypeId: 0,
    TenantScreenFlg: false,
    RentedServiceFlg: false,
    DweelingType: "",
    FamilyMembers: null,
    RoomersBoardersFlg: false,
    BussinessFlg: false,
    WoodStoveFlg: false,
    WoodStoveProfFlg: false,
    EffectiveDateInception: null,
    YearBuilt: null,
    YearPurchased: null,
    ConstructionTypeId: null,
    ConstructionType: "",
    SquareFootage: null,
    NumberStories: null,
    PoolExclusionFlg: false,
    FemaZone: "Unknown",
    RoofCovering: null,
    RoofGeometry: null,
    RoofReplacementYear: null,
    OpeningProtections: null,
    FireDeptResponseTime: null,
    FireProtectionClass: null,
    WalkingBussinessFlg: false,
    DayCareflg: false,
    UpToCodeflg: false,
    RenovationFlg: false,
    DamageFlg: false,
    ComercialFlg: false,
    UnconventionalFlg: false,
    WoodStoveMainSourceFlg: false,
    PortableDeviceFlg: false,
    ChineseDrywallFlg: false,
    FusesFlg: false,
    WiringFlg: false,
    CircuitBreakerFlg: false,
    GalPlumbingFlg: false,
    LeadPaintFlg: false,
    ForeclosureFlg: false,
    PoolFlg: false,
    PoolDivingFlg: false,
    PoolFenceFlg: true,
    TrampolineFlg: false,
    TrampolineFenceFlg: true,
    FireAccessFlg: true,
    RentedAnyTimeFlg: false,
    LeaseDuration: null,
    OverWaterFlg: false,
    ProperDrainageFlg: false,
    IsACV: false,
    ReRoofWPIFlg: false,
    EntireBuildingWPIFlg: false,
    WpiWaiverFlg: false
  }
}
