import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { LossHistoryService } from '../_helpers/loss-history.service';
import { QuoteService } from '../_helpers/quote.service';
import { QuoteUpdate } from '../_shared/classes/QuoteUpdate';
import { Validation } from '../_shared/classes/Validation';
import { LossHistoryModel, LossItemModel } from './loss-history.class';
import { UserService } from '../user/services/user.service';
import { FormBaseComponent } from '../components/shared/base-components/form-base.component';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-loss-history',
  templateUrl: './loss-history.component.html',
  styleUrls: ['./loss-history.component.css']
})
export class LossHistoryComponent extends FormBaseComponent implements OnInit, AfterViewInit {

  @ViewChildren('Underwriter') inputRef : ElementRef[];
  entity = new LossHistoryModel;
  itemType = new LossItemModel;
  itemsList: LossItemModel[] = [];
  statusOpt: string[] = ["Closed", "Open", "Subrogation"];
  lossesOpt: string[] = ["Earthquake", "Hurricane", "Winterstorm", "Fire", "Flood", "Hail", "Water", "Theft", "Vandalism/Malicious Mischief", "Liability", "Other"];
  today: Date = new Date();
  index: number = -1;
  continue: boolean = true;
  validation: Validation = JSON.parse(sessionStorage.getItem('validation')) ?? new Validation;
  quoteId: number = + (sessionStorage.getItem('quoteId') ?? 0);
  quoteNumber: string = sessionStorage.getItem('quoteNumber').replace(/"/g, '');
  windDeductible: number;
  roofReplacementYear: number;
  yearBuilt: Date;
  alreadyCreated = false;
  statusIn = false;
  statusError = false;
  riskAddressHeader: string;
  oneYearAgoToday: Date;
  fiveYearsAgoToday: Date;
  @ViewChild('coverageForm') coverageForm : NgForm;


  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    private service: LossHistoryService,
    private quoteService: QuoteService,
    private titleService: Title,
    private userService: UserService,
    private datePipe: DatePipe
  ) {
    super();

    console.log(this.today);
  }
  ngAfterViewInit(): void {
    super.configureCheckForChange(this.inputRef);
  }

  ngOnInit(): void {
    this.userService.refreshToken();
    this.setRiskAddressHeader();
    this.titleService.setTitle("Hiscox High Value - Loss History");

    this.service.getEligibilityRequirements(this.quoteId).subscribe(item => {
      this.windDeductible = item.Data.windDeductible;
      this.roofReplacementYear = item.Data.roofReplacementYear;
      this.yearBuilt = new Date(item.Data.yearBuilt, 1);
    });

    if (this.quoteId != 0 && this.quoteId != null && this.quoteId != undefined) {
      this.service.getByQuote(this.quoteId).subscribe(item => {
        let data = item.Data;
        let check = item.Success;
        if (check && data!= null) {
          this.alreadyCreated = true;
          this.entity.Detail = data;
          data.LossHistoryItems.forEach(element => {
            let itemList = new LossItemModel;
            itemList.Item.Id = element.Id;
            itemList.Item.IsEligible = element.IsEligible;
            itemList.Item.LossHistoryId = element.LossHistoryId;
            itemList.Item.LossDate = element.LossDate;
            itemList.Item.Peril = element.Peril;
            itemList.Item.Description = element.Description;
            itemList.Item.Status = element.Status;
            itemList.Item.Value = element.Value;
            this.itemsList.push(itemList);
          });
          
          console.log(this.itemsList);
        }
      })
    }
    else if(localStorage.getItem("lossprev")){
      this.entity.Detail = JSON.parse(localStorage.getItem("lossprev"));
      localStorage.removeItem("lossprev");
    }

    if (this.entity.Detail.PriorLossFlg === undefined) {
      this.entity.Detail.PriorLossFlg = false;
    }

    this.oneYearAgoToday = new Date(this.today.getFullYear() - 1, this.today.getMonth(), this.today.getDay());
    this.fiveYearsAgoToday = new Date(this.today.getFullYear() - 5, this.today.getMonth(), this.today.getDay());

    this.mouseHandlers();
  }

  lossHistory() {
    this.showLoadingSpinner();

    this.quoteService.rate(this.quoteId).subscribe(result => {
      let check = result.Success;
      this.hideLoadingSpinner();
      if (check) {
        this.router.navigate(['options']);
      }
    })
  }

  isLossWithinFiveYears(lossItem = this.itemType) {
    return lossItem.Item.LossDate != null && new Date(lossItem.Item.LossDate) > this.fiveYearsAgoToday;
  }

  isLossRecent(lossItem = this.itemType) {
    return lossItem.Item.LossDate != null && new Date(lossItem.Item.LossDate) > this.oneYearAgoToday;
  }

  isLossWaterAndRecent(lossItem = this.itemType) {
    return lossItem.Item.Peril == "Water" && this.isLossRecent(lossItem);
  }

  isLossHailClaimAfterRoofReplacement(lossItem = this.itemType) {
    return lossItem.Item.Peril == "Hail" && lossItem.Item.LossDate != null && this.roofReplacementYear < new Date(lossItem.Item.LossDate).getFullYear();
  }

  isLossIneligible(lossItem = this.itemType) {
    return this.isPerilIneligible(lossItem) || this.isStatusIneligible(lossItem);
  }

  get isLossDateNotSelected() {
    return this.itemType.Item.LossDate == null || this.itemType.Item.LossDate == undefined;
  }

  get isLossDateAfterToday() {
    return !this.isLossDateNotSelected && new Date(this.itemType.Item.LossDate) > this.today;
  }

  get isLossDateBeforeYearBuilt() {
    return !this.isLossDateNotSelected && new Date(this.itemType.Item.LossDate) < this.yearBuilt;
  }

  get isLossDateInvalid() {
    return this.isLossDateAfterToday || this.isLossDateBeforeYearBuilt;
  }

  get isValueEmpty() {
    return this.itemType.Item.Value == 0 || this.itemType.Item.Value == null || this.itemType.Item.Value == undefined;
  }

  isPerilNotSelected(lossItem = this.itemType) {
    return lossItem.Item.Peril == "" || lossItem.Item.Peril == null || lossItem.Item.Peril == undefined;
  }

  isPerilIneligible(lossItem = this.itemType) {
    return !this.isPerilNotSelected(lossItem) && lossItem.Item.Peril == "Liability";
  }

  get doesTotalNumOfClaimsRequireReferral() {
    let numOfClaimsInLastFiveYears = 0;

    this.itemsList.forEach(item => {
      if (this.isLossWithinFiveYears(item)) {
        numOfClaimsInLastFiveYears++;
      }
    })

    return numOfClaimsInLastFiveYears >= 3;
  }

  get doesTotalHailAmountRequireReferral() {
    let hailTotal = 0;

    if (this.windDeductible == 0)
      return false;

    this.itemsList.forEach(item => {
      if (this.isLossHailClaimAfterRoofReplacement(item)) {
        hailTotal += item.Item.Value;
      }
    })

    if (this.index == -1 && this.isLossHailClaimAfterRoofReplacement()) {
      hailTotal += this.itemType.Item.Value;
    }

    return this.windDeductible < hailTotal;
  }

  get doesTotalRecentLossRequireReferral() {
    let recentLossTotal = 0;

    this.itemsList.forEach(item => {
      if (this.isLossRecent(item)) {
        recentLossTotal += item.Item.Value;
      }
    })

    if (this.index == -1 && this.isLossRecent()) {
      recentLossTotal += this.itemType.Item.Value;
    }

    return recentLossTotal > 100000;
  }

  get doesTotalRecentWaterLossRequireReferral() {
    let pastWaterLossTotal = 0;

    this.itemsList.forEach(item => {
      if (this.isLossWaterAndRecent(item)) {
        pastWaterLossTotal += item.Item.Value;
      }
    })

    if (this.index == -1 && this.isLossWaterAndRecent()) {
      pastWaterLossTotal += this.itemType.Item.Value;
    }

    return pastWaterLossTotal > 50000;
  }

  get isAnyLossIneligible() {
    let doesIneligibleLossExist = false;

    this.itemsList.forEach(item => {
      if (this.isLossIneligible(item)) {
        doesIneligibleLossExist = true;
      }
    })

    if (this.index == -1 && this.isLossIneligible()) {
      doesIneligibleLossExist = true;
    }

    return doesIneligibleLossExist;
  }

  isStatusNotSelected(lossItem = this.itemType) {
    return lossItem.Item.Status == "" || lossItem.Item.Status == null || lossItem.Item.Status == undefined;
  }

  isStatusIneligible(lossItem = this.itemType) {
    return !this.isStatusNotSelected(lossItem) && (lossItem.Item.Status == 'Subrogation' || lossItem.Item.Status == 'Open');
  }

  get isUWReferralRequired() {
    return this.doesTotalNumOfClaimsRequireReferral || this.doesTotalHailAmountRequireReferral || this.doesTotalRecentLossRequireReferral || this.doesTotalRecentWaterLossRequireReferral;
  }

  get isLossHistoryIneligible() {
    return (this.entity.Detail.PriorLossFlg && this.itemsList.length == 0) || this.isAnyLossIneligible;
  }

  get isFormEmpty() {
    return this.isLossDateNotSelected || this.isValueEmpty || this.isPerilNotSelected() || this.isStatusNotSelected();
  }

  private saveLossItemList() {
    this.showLoadingSpinner();

    this.entity.Detail.LossHistoryItems = [];
    this.itemsList.forEach(item => {
      this.entity.Detail.LossHistoryItems.push(item.Item);
    });

    this.service.save(this.entity.Detail).subscribe(item => {
      let data = item.Data;
      let check = item.Success;

      if (check) {
        let value = new QuoteUpdate;
        this.entity.Detail.Id = data;
        value.data.LossHistoryId = data;
        value.data.LossHistoryUpdate = true;
        value.data.QuoteId = this.quoteId;
        this.quoteService.update(value.data).subscribe(result => {
          check = result.Success;
          if (check) {
            this.hideLoadingSpinner();
          }
        })
      }
    })
  }

  itemAdd() {
    if (this.isFormEmpty || this.isLossDateInvalid) {
      return;
    }

    this.itemType.Item.IsEligible = !this.isLossIneligible();

    // this code executes when an item has been modified
    if (this.index != -1) {
      this.deleteItem(this.index);
    }
    this.itemsList.push(this.itemType);
    this.itemType = new LossItemModel;
    this.index = -1;

    this.coverageForm.reset();

    this.saveLossItemList();
  }

  deleteItem(ind: number) {
    this.itemsList.splice(ind, 1);

    if (this.index == -1) {
      this.saveLossItemList();
    }
  }

  editItem(ind: number) {
    let lossItem = this.itemsList[ind];

    lossItem.Item.LossDate = this.datePipe.transform(lossItem.Item.LossDate, 'yyyy-MM-dd');

    this.index = ind;
    this.itemType = lossItem;
  }

  onPrevious() {
    localStorage.setItem("lossprev", JSON.stringify(this.entity.Detail));
    this.router.navigate(['credits']);
  }
}
