import { triggerBlur } from "@angular/cdk/testing/testbed/fake-events";
import { ElementRef } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Observable, fromEvent, merge } from "rxjs";
import { DisplayMessage, GenericValidator, ValidationMessages } from "src/app/util/generic-form-validator";
import { SessionStorageUtils } from "src/app/util/session-storage";

export abstract class FormBaseComponent{
    displayMessage: DisplayMessage = {};
    genericValidator: GenericValidator;
    validationMessages: ValidationMessages;
    notSavedChanges: boolean;
    public riskAddressHeader: string;
    public isLoading = false;

    protected mouseHandlers() {
      var elements = Array.from(document.getElementsByTagName("input"));

      elements.forEach((el: Element) => {
        el.addEventListener('wheel', (event: WheelEvent): void => {
          (event.target as HTMLElement).blur();
        });
        el.addEventListener('keydown', (event: KeyboardEvent): void => {
          if (event.key === "ArrowUp" || event.key === "ArrowDown") {
            event.preventDefault();
          }
        });
      });
    }

    protected configureValidationMessages(validationMessages: ValidationMessages) {
        this.genericValidator = new GenericValidator(validationMessages);
    }

    protected configureFormValidation(
        formInputElements: ElementRef[],
        formGroup: FormGroup) {

        let controlBlurs: Observable<any>[] = formInputElements
            .map((formControl: ElementRef) => fromEvent(formControl.nativeElement, 'blur'));

        merge(...controlBlurs).subscribe(() => {
            this.validateForm(formGroup)
        });
    }

    protected configureCheckForChange(
        formInputElements: ElementRef[]
    ) {
        let controlChanges: Observable<any>[] = formInputElements
        .map((formControl: ElementRef) => fromEvent(formControl.nativeElement, 'change'));

        merge(...controlChanges).subscribe(() => {
            sessionStorage.setItem('underwriterChangeFlg', "true")
        })
    }

    protected validateForm(formGroup: FormGroup) {
        this.displayMessage = this.genericValidator.processMessages(formGroup);
        this.notSavedChanges = true;
    }

    protected setRiskAddressHeader() {     
        this.riskAddressHeader = `${(this.insuredNameLine != "" ? `${this.insuredNameLine} - ` : "")}${this.addressLine}`; 
    }
    
    protected get insuredNameLine(){
        const insuredData = SessionStorageUtils.getInsured();

        if(insuredData != null){
            if(insuredData.FirstName != ""){
                return `${insuredData.FirstName.toLocaleUpperCase()} ${insuredData.LastName.toLocaleUpperCase()}`
            }
            else if(insuredData.TrustName != ""){
                return `${insuredData.TrustName.toLocaleUpperCase()}`
            }
            else if(insuredData.CorpName != ""){
                return `${insuredData.CorpName.toLocaleUpperCase()}`
            }
        }

        return "";
    }
    
    private get addressLine(){
        const address = SessionStorageUtils.getAddress();
    
        return `${address.Address1.toLocaleUpperCase()}, 
                ${address.City.toLocaleUpperCase()}, 
                ${address.State.Code.toLocaleUpperCase()} 
                ${address.Zip} - 
                ${address.County.toLocaleUpperCase().replace('COUNTY', '')}`;
    }
    
    protected showLoadingSpinner(){
        this.isLoading = true;
    }

    protected hideLoadingSpinner(){
        this.isLoading = false;
    }

    public validateNumericInput(event: any): void {
        const input = event.target.value;
        event.target.value = input.replace(/[^0-9]/g, '');
    }

}
