import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class ValidateService {
  invalidItems : string[] = [];

  getInvalidFormElements(formId: string): string[] {
    this.invalidItems = [];
    let form = document.forms[formId];
    let elements = form.elements;   
    let elementsArray = Array.prototype.slice.call(elements);

    elementsArray.forEach(item => { 
      if (item.classList.contains('ng-invalid' || 'is-invalid')) {
        this.invalidItems.push(item.id);
      }
    });

    return this.invalidItems;
  }

}
