import { Component, HostListener, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Router } from "@angular/router";
import { NavMenuModel } from "./nav-menu.class";
import Swal from 'sweetalert2';
import { AuthService } from "../../../_helpers/auth.service";
import { IIdentifier } from "../../interfaces/IIdentifier";
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { ViewSizeDefinition } from '../../../_helpers/view-size.service';
import { ValidateService } from '../../../_helpers/validate.service';
import { ScrollService } from "../../../_helpers/scroll.service";
import { Inject } from '@angular/core';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.css']
})

export class NavMenuComponent {
  arcWebUrl;
  model = new NavMenuModel;
  screenLocation = 0;

  @HostListener('window:scroll')
  updateScreenLocation() {
    //this.debouncedScroll();
    //console.log("nav");
    this.screenLocation = (document.documentElement.scrollTop || document.body.scrollTop);
  }

  @Input() hidePrevious: boolean = false;
  @Input() hideBackToTop: boolean = false;
  @Input() hideSpeakTo: boolean = false;
  @Input() hideLogin: boolean = true;
  @Input() hideUserName: boolean = false;
  @Input() hideHamberger: boolean = false;
  @Input() smallScreenTitle: string = '';
  @Input() disableEmailAndPrint: boolean = false;
  @Input() flgEzLynx: boolean = false;
  @Input() isWelcomePage: boolean = false;
  @Input() disableSmileyHome: boolean = false;

  @Output() onPrevious: EventEmitter<any> = new EventEmitter();
  @Output() onSmileyClick: EventEmitter<any> = new EventEmitter();
  @Output() emailQuote: EventEmitter<any> = new EventEmitter();
  @Output() printQuote: EventEmitter<any> = new EventEmitter();
  @Output() error: EventEmitter<any> = new EventEmitter();

  @ViewChild('loginModal', {}) loginModal: ElementRef;
  loginModalReference: any;
  @ViewChild('forgotPasswordOrNewUserModal', {}) forgotPasswordOrNewUserModal: ElementRef;
  forgotPasswordOrNewUserModalReference: any;
  @ViewChild('passwordExpiredModal', {}) passwordExpiredModal: ElementRef;
  passwordExpiredModalReference: any;
  @ViewChild('invalidItemsModal', {}) invalidItemsModal: ElementRef;
  invalidItemsModalReference: any;


  constructor(private authService: AuthService, private modalService: NgbModal, private router: Router, private breakpointObserver: BreakpointObserver, private scrollService: ScrollService, private validateService: ValidateService)//, @Inject('ARC_WEB_URL') arcWebUrl: string) 
    {    
    //this.arcWebUrl = arcWebUrl;
    //console.log(this.arcWebUrl);
  }

  ngOnInit() {

    this.model.FlgEzLynxInitialValues = sessionStorage.getItem("flgEzLynxInitialValues") ? eval(sessionStorage.getItem("flgEzLynxInitialValues").toLowerCase()) : false;

    this.breakpointObserver.observe([
      ViewSizeDefinition.ExtraSmallWidth,
      ViewSizeDefinition.ExtraSmallHeight,
      ViewSizeDefinition.SmallWidth,
      ViewSizeDefinition.SmallHeight,
      ViewSizeDefinition.MediumWidth,
      ViewSizeDefinition.MediumHeight,
      ViewSizeDefinition.Large
    ]).subscribe(result => {
      if (result.breakpoints[ViewSizeDefinition.ExtraSmallWidth] || result.breakpoints[ViewSizeDefinition.ExtraSmallHeight]) {
        this.model.topBarBehaviorTransition = 75;
        this.model.HamburgerMenu = true;
      }
      else if (result.breakpoints[ViewSizeDefinition.SmallWidth] || result.breakpoints[ViewSizeDefinition.SmallHeight]) {
        this.model.topBarBehaviorTransition = 75;
        this.model.HamburgerMenu = true;
      }
      else if (result.breakpoints[ViewSizeDefinition.MediumWidth] || result.breakpoints[ViewSizeDefinition.MediumHeight]) {
        this.model.topBarBehaviorTransition = 125;
        this.model.HamburgerMenu = false;
        this.model.HamburgerOpen = false;
      }
      else { //large
        this.model.topBarBehaviorTransition = 125;
        this.model.HamburgerMenu = false;
        this.model.HamburgerOpen = false;
      }
    });

    var todayDateTime = new Date();
    var startTime = new Date(todayDateTime.getFullYear(), todayDateTime.getMonth(), todayDateTime.getDate(), 8, 5, 0);
    var endTime = new Date(todayDateTime.getFullYear(), todayDateTime.getMonth(), todayDateTime.getDate(), 17, 15, 0);

    if (todayDateTime >= startTime && todayDateTime <= endTime && todayDateTime.getDay() >= 1 && todayDateTime.getDay() <= 5) {
      this.model.BusinessHours = true;
    }
  }

  openBurger() {
    //needed to open the menu AFTER the (clickOutside) closes it.
    setTimeout(() => {
      this.model.HamburgerOpen = true;
    }, 5);
  }

  openLogin() {
    this.loginModalReference = this.modalService.open(this.loginModal, { ariaLabelledBy: 'modal-basic-title', size: 'md' });
    this.loginModalReference.result.then(x => {
      Swal.fire({
        icon: 'success',
        title: 'Logged in!'
      });
    }, () => {
      //called if the modal is closed by clicking the x or by clicking out of it
    });
  }

  backToTop() {
    this.scrollService.scrollTo('');
  }

  goHome() {
    //sessionStorage.clear();

    if (this.model.Identifier.FullName != "") { //if logged in
      sessionStorage.setItem('identifier', JSON.stringify(this.model.Identifier));
    } else {
      this.model = new NavMenuModel;
      /*this.authService.clientLogin().subscribe(clientLoginResult => {
        let identifier: IIdentifier = <any>clientLoginResult[0] as IIdentifier;

        identifier.AgentId = 100;
        identifier.Agency = "T0000";
        identifier.ClientInitiated = true;

        sessionStorage.setItem('identifier', JSON.stringify(identifier));
        this.model.Identifier = JSON.parse(sessionStorage.getItem('identifier'));
        const agency = this.router.url.replace(/^\/|\/$/g, '');

        if (agency != null && agency !== '') {
          this.authService.getAgency(agency).subscribe(agencyResult => {
            console.log(agencyResult);
            identifier = this.authService.getAccessToken();
            identifier.Agency = (agencyResult as any).Number;
            identifier.AgentId = (agencyResult as any).PrimaryAgentId;
            sessionStorage.setItem('identifier', JSON.stringify(identifier));
          });
        }
      });*/
    }

    this.router.navigate(['']);
    this.onSmileyClick.emit();
  }

  debouncedScroll = this.debounce(() => {
    //console.log("scrolled (debounced)");

    //In chrome and some browser scroll is given to body tag
    let pos = (document.documentElement.scrollTop || document.body.scrollTop);
    this.screenLocation = pos;

    //let max = 200;
    //// pos/max will give you the distance between scroll bottom and and bottom of screen in percentage.
    //if (pos >= max) {
    //  //Do your action here
    //  console.log(`scroll stuff pos: ${pos}`);
    //}
  }, 50, true);

  debounce(func, wait, immediate) {
    var timeout;
    var self = this;
    return function () {
      var context = this, args = arguments;
      var later = () => {
        timeout = null;
        if (!immediate) func.apply(self, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  };

  login() {
    this.model.Spinner = true;
    var filterResponse;
    this.authService.login(this.model.Login).subscribe(result => {
      filterResponse = JSON.parse(JSON.stringify(result)).filter(function (e) { return e.AgencyStatusId != 4 }); //filters out terminated agencies
      this.model.Spinner = false;
      if (filterResponse.length === 1) {
        if (filterResponse[0]['TempPassword']) {
          this.openExpiredPasswordModal();
          return;
        };

        this.model.Identifier = <any>filterResponse[0] as IIdentifier;
        this.model.Identifier.ClientInitiated = false;
        sessionStorage.setItem('identifier', JSON.stringify(this.model.Identifier));

        this.model = new NavMenuModel;
        //this.model.LoggedInAs = identifier.FullName;
        //this.model.Identifier = JSON.parse(sessionStorage.getItem('identifier'));
        this.loginModalReference.close();

      } else if (filterResponse.length > 1) {
        this.model.AgencySelector.agencies = (filterResponse as IIdentifier[]);

      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error logging in',
          html: "Please contact the Operations Department at </br> (281) 367-3424 or send us an email at AgentInfo@twfg.com"
        });
      }
    }, error => {
      this.model.Spinner = false;
        if (error.status === 401) {
          Swal.fire({
          icon: 'error',
          title: 'Invalid username and/or password',
          text: error.error.Message
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Something went wrong',
          text: 'Please try again later'
        });
      }
    });
  }

  logout() {
    sessionStorage.removeItem("identifier");
    //this.model.LoggedInAs = null;

    this.authService.clientLogin().subscribe(clientLoginResult => {
      let identifier: IIdentifier = <any>clientLoginResult[0] as IIdentifier;

      identifier.AgentId = 100;
      identifier.Agency = "T0000";
      identifier.ClientInitiated = true;

      sessionStorage.setItem('identifier', JSON.stringify(identifier));
      this.model.Identifier = JSON.parse(sessionStorage.getItem('identifier'));
      const agency = this.router.url.replace(/^\/|\/$/g, '');

      if (agency != null && agency !== '') {
        this.authService.getAgency(agency).subscribe(agencyResult => {
          identifier = this.authService.getAccessToken();
          identifier.Agency = (agencyResult as any).Number;
          identifier.AgentId = (agencyResult as any).PrimaryAgentId;
          sessionStorage.setItem('identifier', JSON.stringify(identifier));
        });
      }
    });

    Swal.fire({
      icon: 'success',
      title: 'You have been logged out.'
    });
  }

  selectAgency() {
    const token = this.model.AgencySelector.agencies.filter(x => x.AgentId == this.model.AgencySelector.selected)[0];
    token.ClientInitiated = false;
    sessionStorage.setItem('identifier', JSON.stringify(token));
    this.model = new NavMenuModel;
    //this.model.LoggedInAs = token.FullName;

    Swal.fire({
      icon: 'success',
      title: 'Logged in!'
    }).then(() => {
      this.loginModalReference.close();
    });
  }

  openForgotPasswordorNewUser() {    
    this.forgotPasswordOrNewUserModalReference = this.modalService.open(this.forgotPasswordOrNewUserModal, { ariaLabelledBy: 'modal-basic-title', size: 'md' });
    this.forgotPasswordOrNewUserModalReference.result.then(x => {

    }, () => {

    });

    this.model.ShowForgotPassword = true;
  }

  openExpiredPasswordModal() {
    this.passwordExpiredModalReference = this.modalService.open(this.passwordExpiredModal, { ariaLabelledBy: 'modal-basic-title', size: 'lg' });
    this.passwordExpiredModalReference.result.then(x => {

    }, () => {

    });
  }

  resetPassword(email) {
    this.model.InvalidItemsText = this.getInvalidItemsText('newUserForm');

    if (this.model.InvalidItemsText.length > 0) {
      this.openInvalidItemsModal();
      return;
    }

    this.authService.resetPassword(email).subscribe(response => {   
      if (response) {
        Swal.fire({
          title: "Email Sent!",
          text: "You will receive an email with a temporary password shortly.",
          icon: "success"
        });
        this.model.Spinner = false;
      }
      else {
        Swal.fire({
          title: "Not Found",
          text: "The email address entered was not found.",
          icon: "error"
        });
        this.model.Spinner = false;
      }
    });
  }

  openInvalidItemsModal() {
    this.invalidItemsModalReference = this.modalService.open(this.invalidItemsModal, { ariaLabelledBy: 'modal-basic-title', size: 'lg' });
    this.invalidItemsModalReference.result.then(x => {

    }, () => {

    });
  }

  getInvalidItemsText(formId: string): string[] {
    this.model.InvalidItems = [];
    this.model.InvalidItemsText = [];
    this.model.InvalidItems = this.validateService.getInvalidFormElements(formId);

    if (this.model.InvalidItems.length > 0) {
      this.model.InvalidItemsText = this.displayInvalidItemsText(this.model.InvalidItems);
    }

    return this.model.InvalidItemsText;
  }

  displayInvalidItemsText(invalidItems: string[]): string[] {
    this.model.InvalidItemsText = [];

    if (invalidItems.includes('resetEmail')) {
      this.model.InvalidItemsText.push('Email');
    }
    
    return this.model.InvalidItemsText;
  }

}
