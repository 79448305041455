import { Injectable } from '@angular/core';
import { Inject } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { FormatService } from '../_helpers/format.service';
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { INewFeeInformation } from '../_shared/interfaces/INewFeeInformation';
import { CustomResponse } from '../_shared/classes/CustomResponse';
import { BaseService } from '../services/base-service.service';

@Injectable({
  providedIn: 'root'
})

export class MoratoriumService extends BaseService{
  arcApiBaseUrl;
  programId;
  fortunaApiBaseUrl;
  private url: string = "api/";

  constructor(private http: HttpClient, private formatService: FormatService) {
    super();
  }

  getCounties(): Observable<CustomResponse<any>> {
    return this.http.get<CustomResponse<any>>(this.buildXenithEndpoint(`/HighValueAddress/Get/QuotableCounties`), this.GetAuthHeaderJson());
  }

  getMoratoriumMessage(): Observable<any> {
    return this.http.get(this.buildXenithEndpoint('api/ActionLists/Moratoriums/Message'), this.GetAuthHeaderJson());
  }

  getInactiveCounties(): Observable<CustomResponse<any>> {
    return this.http.get<CustomResponse<any>>(this.buildXenithEndpoint(`/HighValueAddress/Get/InactiveCounties`), this.GetAuthHeaderJson());
  }

  getNewFeeInformation() : Observable<INewFeeInformation>{
    return this.http.get<INewFeeInformation>(this.buildXenithEndpoint(`/${this.programId}/Quote/GetNewFeeInformation`), this.GetAuthHeaderJson());
  }

  // isCountyMinTwoPercentWindHail(county: string, effDate: string, quoteDate: string) : Observable<boolean>{

  //   let endpoint = `${this.arcApiBaseUrl}/api/${this.programId}/Moratorium/IsMinTwoPercentWindHailCounty/${county}/${effDate}/${quoteDate.split('T')[0]}`;

  //   return this.http.get<boolean>(endpoint);
  // }

  isCountyMin2PercentWindHail(county: string, effDate: NgbDateStruct, quoteDate: NgbDateStruct): boolean {
    let minTwoPercentStartDate = new Date(2020, 8, 1);
    let minTwoPercentImplementDate = new Date(2020, 7, 20);

    let minTwoPercentStartDateTaylor = new Date(2020, 11, 1);
    let minTwoPercentImplementDateTaylor = new Date(2020, 10, 3);

    let quoteDateAsDate: Date = this.formatService.toDate(quoteDate);
    let effDateAsDate: Date = this.formatService.toDate(effDate);

    county = county.toLowerCase();
    let countyMatch: boolean = false;
    let counties = this.min2PercentWindHailCounties();

    counties.forEach(c => {
      if (county.indexOf(c) !== -1) {
        countyMatch = true;
      }
    });

    if (effDateAsDate >= minTwoPercentStartDate && quoteDateAsDate >= minTwoPercentImplementDate && countyMatch) {
      return true;
    }

    if (effDateAsDate >= minTwoPercentStartDateTaylor && quoteDateAsDate >= minTwoPercentImplementDateTaylor && county.indexOf("taylor") !== -1) {
      return true;
    }

    return false;
  }

  min2PercentWindHailCounties(): string[] {
    return [
      "bexar",
      "cameron",
      "collin",
      "dallas",
      "denton",
      "ellis",
      "hood",
      "johnson",
      "kaufman",
      "lubbock",
      "montague",
      "parker",
      "randall",
      "rockwall",
      "tarrant",
      "wichita"
    ];
  }

  declineTier3(county: string, effDate: NgbDateStruct){
    county = county.toLowerCase();
    county = county.replace('county', '').trim();

    let tier3DeclineEffDate = new Date(2021, 7, 1); //month is -1
    let effDateAsDate: Date = this.formatService.toDate(effDate);

    if (this.tier3CountiesToDecline.indexOf(county) !== -1 && effDateAsDate >= tier3DeclineEffDate) {
      return true;
    }

    return false;
  }

  private tier3CountiesToDecline: string[] = [
    "andrews", "archer", "armstrong", "bailey", "baylor", "bell", "bexar", "borden", "bowie", "briscoe", "callahan", "camp", "carson", "cass", "castro", "childress", "clay", "cochran", "collin", "collingsworth", "cooke", "cottle", "crosby", "dallam", "dallas", "dawson", "deaf smith", "delta", "denton", "dickens", "donley", "eastland", "ellis", "el paso", "fannin", "fisher", "floyd", "foard", "franklin", "gaines", "garza", "gray", "grayson", "hale", "hall", "hansford", "hardeman", "harrison", "hartley", "haskell", "hemphill", "hockley", "hood", "hopkins", "howard", "hunt", "hutchinson", "jack", "johnson", "jones", "kaufman", "kent", "king", "knox", "lamar", "lamb", "lipscomb", "lubbock", "lynn", "marion", "martin", "mclennan", "midland", "mitchell", "montague", "moore", "morris", "motley", "nolan", "ochiltree", "oldham", "palo pinto", "parker", "parmer", "potter", "rains", "randall", "red river", "roberts", "rockwall", "scurry", "shackelford", "sherman", "smith", "stephens", "stonewall", "swisher", "tarrant", "taylor", "terry", "throckmorton", "titus", "travis", "upshur", "van zandt", "webb", "wheeler", "wichita", "wilbarger", "williamson", "wise", "wood", "yoakum", "young"
  ];
}
