import { Component, Inject, OnInit } from '@angular/core';
import { INotificationData } from '../../../_shared/interfaces/notifications/INotificationData';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-notification-modal',
  templateUrl: './notification-modal.component.html',
  styleUrls: ['./notification-modal.component.css']
})
export class NotificationModalComponent implements OnInit {

  constructor( 
    public dialogReference: MatDialogRef<NotificationModalComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: INotificationData) { 
  }

  ngOnInit(): void {
    
  }

  get iconPath(): string{
    const basePath = '../../assets/img/notifications/'
    switch(this.data.Icon.toLowerCase()){
      case 'error':
        return `${basePath}Error.png`
      case 'success':
        return `${basePath}Success.png`
      case 'information':
      case 'info':
        return `${basePath}Information.png`
      case 'warning':
      case 'warn':
        return `${basePath}Warning.png`
      case 'question':
      case 'quest':
        return `${basePath}Question.png`
      default:
        return `${basePath}Information.png`;    
    }
  }

  public confirmAction(){
    this.dialogReference.close(true);
  }

  public cancelAction(){
    this.dialogReference.close(false);
  }
}
