import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FileUtilService {

  constructor() { }

  public downloadFile(fileData: File, fileName: string){
    var downloadURL = window.URL.createObjectURL(fileData);
    var link = document.createElement('a');
    link.href = downloadURL;
    link.download = fileName;
    link.click();
  }

  public formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
}
