/***************************************************************************************************
 * Load `$localize` onto the global scope - used if i18n tags appear in Angular templates.
 */
import '@angular/localize/init';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { SessionStorageUtils } from './app/util/session-storage';

export function getBaseUrl() {
  return document.getElementsByTagName('base')[0].href;
}

export function getArcBaseUrl() {
  //return "http://localhost:50290/";
  //return environment.xenithApiEndpoint;
}

export function getArcWebsiteUrl() {
  return environment.xenithUrl;
}

export function getFortunaBaseUrl() {
  //return "https://localhost:44321/";
  console.log(environment)
  return environment.FortunaBaseUrl;
}

export function getEnvironment() {
  return environment.environment;
}

export function IsUserLogged(): boolean{
  return SessionStorageUtils.getUserToken() != undefined && SessionStorageUtils.getUserToken() != null;
}

const providers = [
  { provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] },
  { provide: 'ARC_API_URL', useFactory: getArcBaseUrl, deps: [] },
  { provide: 'ARC_WEB_URL', useFactory: getArcWebsiteUrl, deps: [] },
  { provide: 'FORTUNA_BASE_URL', useFactory: getFortunaBaseUrl, deps: [] },
  { provide: 'ENVIRONMENT', useFactory: getEnvironment, deps: [] }
];

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic(providers).bootstrapModule(AppModule)
  .catch(err => console.log(err));
