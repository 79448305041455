import { IValidation } from "../interfaces/IValidarion";

export class Validation {
  data: IValidation = {
    YearBuilt: 0,
    Occupancy: "",
    HomeValue: 0,
    Tier1Flg: false,
    Tier2Flg: false,
    Tier3Flg: false,
    ZipCode: 0,
    PPCResponseTime: 0,
    BlanketOrScheduale: false,
    BusinessOnPremises: false,
    PPC: "",
    SplitPPC: false,
    PPCId: 0,
  }
}
