import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators, FormsModule, FormControl } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { InsuredInfoService } from '../_helpers/insured-info.service';
import { QuoteService } from '../_helpers/quote.service';
import { IIdentifier } from '../_shared/interfaces/IIdentifier';
import { InformationModel } from './information.class';
import { IQuote } from "../_shared/interfaces/IQuote";
import { UserService } from '../user/services/user.service';
import { NgbDateCustomParserFormatter } from '../components/NgbDateCustomParserFormatter';
import { FormatService } from "../_helpers/format.service";
import { IAddress } from "../_shared/interfaces/IAddress";
import { CustomResponse } from '../_shared/classes/CustomResponse';
import { SessionStorageUtils } from '../util/session-storage';
import { IInsuredInfo } from '../_shared/interfaces/IInsuredInfo';
import { FormBaseComponent } from '../components/shared/base-components/form-base.component';
import { VeriskService } from '../services/verisk/verisk.service';
import { CalculateValuationRequest } from '../_shared/interfaces/verisk/valuation/ICalculateValuationRequest';
import { of } from 'rxjs';
import { CalculateValuationResult } from '../_shared/interfaces/verisk/valuation/ICalculateValuationResult';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-insured-info',
  templateUrl: './insured-info.component.html',
  styleUrls: ['./insured-info.component.css']
})
export class InsuredInfoComponent extends FormBaseComponent implements OnInit, AfterViewInit {

  @ViewChildren('Underwriter') inputRef : ElementRef[];
  entity = new InformationModel;
  quoteId: number = +(sessionStorage.getItem('quoteId') ?? 0);
  quoteNumber: number = +(sessionStorage.getItem('quoteNumber') ?? 0);
  identifier: IIdentifier = new IIdentifier();
  currentYear: number = new Date().getFullYear();
  newEffectiveDateInfo: any;
  insuredType: number = 1;
  insuredTypes: any[] = [{ id: 1, text: "Individual" }, { id: 2, text: "Trust/Estate" }, { id: 3, text: "Corporation/LLC" }];
  occupationStatuses: Array<any> = [{ id: 1, text: "Employed", value: "Employed" },{ id: 1, text: "Unemployed", value: "Unemployed" },{ id: 1, text: "Retired", value: "Retired" }];
  occupationTypes: string[] = ["Actor", "Celebrity", "Lawyer", "Medical Professional", "Musician", "Politician", "Professional Athlete", "Public Figure/Social Influencer", "Other"];
  declinationTypes: string[] = ["Capacity Reached", "Underwriting Reason", "Other"];
  priorCarrier: string[] = ["New Purchase", "AIG", "Allstate", "American National", "Amica", "ASI", "Bankers", "Centauri", "Cincinnati", "Chubb", "Farmers", "Fed Nat", "Gulfstream", "Homesite", "Kemper", "Liberty Mutual", "Mercury", "National General", "Nationwide", "Occidental", "Progressive", "Pure", "QBE", "Safeco", "The Hartford", "Travelers", "Universal", "UPC", "USAA", "Other"];
  taxOnlySwitch: boolean = false;
  occupatationSwitch: boolean = false;
  coApplicantSwitch: boolean = false;
  declinedCovSwitch: boolean = false;
  today = new Date();
  dobMax = new Date();
  InsuredModel: any;
  @ViewChild('infoNeededForm') infoNeededForm: NgForm;

  constructor (
    private router: Router,
    private titleService: Title,
    private service: InsuredInfoService,
    private quoteService: QuoteService,
    private userService: UserService,
    private ngbDateCustomParserFormatter:NgbDateCustomParserFormatter,
    private formatService: FormatService,
    private veriskService: VeriskService,
    private datePipe: DatePipe
  ) { 
    super()
  }
  ngAfterViewInit(): void {
    super.configureCheckForChange(this.inputRef);
  }

  ngOnInit(): void {
    this.userService.refreshToken();
    this.titleService.setTitle("Hiscox High Value - Insured Info");
    this.dobMax.setFullYear(this.dobMax.getFullYear() - 18);
    this.getDataByQuote();
    this.setRiskAddressHeader();
  }

  private getDataByQuote(){    
    if (this.isQuoteCreated)
    {
      this.service.getByQuote(this.quoteId).subscribe(item => {
                 
        const data = item.Data;
        if (item.Success && data != null) {
          
          this.entity.data = data;
          this.entity.data.DOB=this.datePipe.transform(data.DOB, 'yyyy-MM-dd');
          if(this.entity.data.CoApplicantDOB!=null)
          this.entity.data.CoApplicantDOB=this.datePipe.transform(data.CoApplicantDOB, 'yyyy-MM-dd');
          return;
        }        
        this.entity = new InformationModel;       
      });
    }
    else if(localStorage.getItem("insuredinfoprev")){
      this.entity.data = JSON.parse(localStorage.getItem("insuredinfoprev"));
      localStorage.removeItem("insuredinfoprev");
    }
  }


  submitInsuredData(){   
    this.showLoadingSpinner();

    if (this.entity.data.InsuredTypeId != 1) {
      this.entity.data.FirstName = "";
      this.entity.data.MiddleName = "";
      this.entity.data.LastName = "";
    }

    if (this.entity.data.InsuredTypeId != 2) {
      this.entity.data.TrustName = "";
      this.entity.data.TrustBeneficiary = "";
      this.entity.data.BeneficiaryLastName = "";
    }

    this.saveInsuredData().subscribe((saveInsuredResult) => 
    {      
      
      SessionStorageUtils.saveInsured(this.entity.data);
      this.setRiskAddressHeader();

      if(saveInsuredResult.Success){
        
        this.calculateValuation().subscribe((calculateValuationResult) => {

          this.processValuationResult(calculateValuationResult);

          if(this.isQuoteCreated){
            this.hideLoadingSpinner();
            this.navigateToPropertyDetailPage();
            return;
          }

          this.createNewQuote(saveInsuredResult.Data).subscribe(createQuoteResult => {      
            if (createQuoteResult.Success) {
              SessionStorageUtils.saveQuoteId(createQuoteResult.Data.Id);
              SessionStorageUtils.saveQuoteNumber(createQuoteResult.Data.Number);
              this.hideLoadingSpinner();
              this.navigateToPropertyDetailPage();
            }      
            this.hideLoadingSpinner();
          },
          () => this.hideLoadingSpinner());
        },
        () => this.hideLoadingSpinner());
      }
      this.hideLoadingSpinner();     
    },
    () => this.hideLoadingSpinner());
  }

  private processValuationResult(valuationResult: CustomResponse<CalculateValuationResult>){
    if(valuationResult.Success){
      SessionStorageUtils.saveValuation(valuationResult.Data);
    }
  }

  private calculateValuation(){
    const currentValuation = SessionStorageUtils.getValuation();
    
    if(currentValuation != null){
      return of(new CustomResponse<CalculateValuationResult>(true, currentValuation,  null));
    }

    const riskAddressId = SessionStorageUtils.getRiskAddressId();
    const insuredName = this.insuredNameLine != "" ? this.insuredNameLine : "TODO";

    if(riskAddressId == null || riskAddressId <= 0){
      return of(new CustomResponse<CalculateValuationResult>(false, null,  null));
    }

    const valuationModel = {
      RiskAddressId: riskAddressId,
      ClientName: insuredName
    } as CalculateValuationRequest;

    return this.veriskService.calculateValuation(valuationModel);
  }

  private createNewQuote(data: any){
    const riskAddressId = SessionStorageUtils.getRiskAddressId();
    const user = SessionStorageUtils.getUser();
    const quoteModel: IQuote = {
      Id: 0,
      EffectiveDate: new Date(),
      RiskAddressId: riskAddressId,
      NamedInsuredId: data,
      PropertyDetailId: null,
      CoverageId: null,
      PersonalCoverageId: null,
      CreditsId: null,
      LossHistoryId: null,
      InsuredTypeId: this.entity.data.InsuredTypeId,
      AgentId:user.AgentId,
      AgencyId: user.AgencyId
    };

    return this.quoteService.create(quoteModel);
  }

  private get isQuoteCreated(){
    return this.quoteId != 0 && this.quoteId != null && this.quoteId != undefined;
  }

  private navigateToPropertyDetailPage(){
    this.router.navigate(['property-detail']);
  }

  private saveInsuredData(){
    this.InsuredModel = {
      Id: this.entity.data.Id,
      InsuredTypeId: this.entity.data.InsuredTypeId,
      FirstName: this.entity.data.FirstName,
      TrustName: this.entity.data.TrustName,
      CorpName: this.entity.data.CorpName,
      MiddleName: this.entity.data.MiddleName,
      LastName: this.entity.data.LastName,
      DOB: this.entity.data.DOB,
      Email: this.entity.data.Email,
      Phone: this.entity.data.Phone,
      TrustBeneficiary: this.entity.data.TrustBeneficiary,
      BeneficiaryLastName: this.entity.data.BeneficiaryLastName,
      OccupationFlg: this.entity.data.OccupationFlg,
      BankruptcyFlg: this.entity.data.BankruptcyFlg,
      ArsonFlg: this.entity.data.ArsonFlg,
      Occupation: this.entity.data.Occupation,
      CustomOccupation: this.entity.data.CustomOccupation,
      CoApplicantFlg: this.entity.data.CoApplicantFlg,
      CoApplicantDOB: this.entity.data.CoApplicantDOB,
      FirstDecliningCarrier: this.entity.data.FirstDecliningCarrier,
      SecondDecliningCarrier: this.entity.data.SecondDecliningCarrier,
      DecliningReason: this.entity.data.DecliningReason,
      CustomReason: this.entity.data.CustomReason,
      TaxPurpose: this.entity.data.TaxPurpose,
      DeclinedCarrierFlg: this.entity.data.DeclinedCarrierFlg,
      OccupationStatus: this.entity.data.OccupationStatus,
      PriorCarrier: this.entity.data.PriorCarrier
    }

    return this.service.save(this.InsuredModel);
  }

  onPrevious() {
    localStorage.setItem("insuredinfoprev", JSON.stringify(this.entity.data));
    this.showLoadingSpinner()
    this.calculateValuation().subscribe((calculateValuationResult) => {
      this.processValuationResult(calculateValuationResult);
      this.hideLoadingSpinner();
      this.router.navigate(['']);
    },
    () => {
      this.hideLoadingSpinner();
      this.router.navigate(['']);
    });
  }

  isDateOfBirthValid(): boolean {
    if (!this.entity.data.DOB) return false;
    const today = new Date();
    const dob = new Date(this.entity.data.DOB);
    const age = today.getFullYear() - dob.getFullYear();
    const monthDifference = today.getMonth() - dob.getMonth();
    
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < dob.getDate())) {
      return age - 1 >= 18; 
    }
    
    return age >= 18;
  }

  resetOther() {
    if (this.entity.data.DecliningReason != "Other") {
      this.entity.data.CustomReason = "";
    }

    if (this.entity.data.Occupation != "Other") {
      this.entity.data.CustomOccupation = "";
    }
  }
}
