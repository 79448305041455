import { Injectable } from '@angular/core';
import { Inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { IAddress } from "../_shared/interfaces/IAddress";
import { IPersonalCoverage } from '../_shared/interfaces/IPersonalCoverage';
import { CustomResponse } from '../_shared/classes/CustomResponse';

@Injectable({
  providedIn: 'root',
})
export class PersonalCoverageService {
  arcApiBaseUrl;
  fortunaApiBaseUrl;
  private url: string = "api/personalcoverage/";


  constructor(private http: HttpClient, @Inject('FORTUNA_BASE_URL') fortunaApiBaseUrl: string, @Inject('ARC_API_URL') arcApiBaseUrl: string) {
    this.arcApiBaseUrl = arcApiBaseUrl;
    this.fortunaApiBaseUrl = fortunaApiBaseUrl;
  }

  get(id: number): Observable<CustomResponse<any>> {
    return this.http.get<CustomResponse<any>>(this.fortunaApiBaseUrl + this.url + id);
  }

  getByQuote(quote: number): Observable<CustomResponse<any>> {
    return this.http.get<CustomResponse<any>>(this.fortunaApiBaseUrl + this.url + "quote/" + quote);
  }

  save(item: IPersonalCoverage) : Observable<CustomResponse<any>> {
    return this.http.post<CustomResponse<any>>(this.fortunaApiBaseUrl + this.url, item);
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.log("error in service");
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
