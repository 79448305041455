import { HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { LocalStorageUtils } from "../util/local-storage";
import { throwError } from "rxjs";
import { SessionStorageUtils } from "../util/session-storage";
import { IUser } from "../user/models/IUser";

export abstract class BaseService {

    protected ARC2_API_URL: string = environment.xenithApiEndpoint;

    protected GetHeaderJson() {
        return {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
    }

    protected GetAuthHeaderJson() {
        return {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${SessionStorageUtils.getUserToken().AccessToken}`
            })
        };
    }

    protected extractData(response: any) {
        return response.data || {};
    }

    protected buildXenithEndpoint(endpoint: string){
        return `${this.ARC2_API_URL}api${endpoint}`;
    }

    protected serviceError(response: Response | any) {
        let customError: string[] = [];
        let customResponse = { error: { errors: [] }}

        if (response instanceof HttpErrorResponse) {

            if (response.statusText === "Unknown Error") {
                customError.push("Something Went Wrong");
                response.error.errors = customError;
            }
        }
        if (response.status === 500) {
            customError.push("Something went wrong processing your request, try again later.");            
            customResponse.error.errors = customError;
            return throwError(customResponse);
        }

        return throwError(response);
    }

    protected get user(): IUser{
        return SessionStorageUtils.getUser();
    }
}