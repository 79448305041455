import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class FormatService {

  toDate(date: NgbDateStruct): Date {
    if (date === null || date === undefined || date.year === null || date.month === null || date.day === null) {
      return null;
    }

    return new Date(`${String(date.month)}/${String(date.day)}/${String(date.year)}`);
  }

  toDateFromString(date: string): Date {
    return this.toDate(this.toNgbDateStructFromString(date));
  }

  toNgbDateStruct(input: Date): NgbDateStruct {
    if (!input) {
      return null;
    }

    let result: NgbDateStruct = {
      year: input.getFullYear(),
      month: input.getMonth() + 1, //Since getMonth for Date type starts from 0 - 11, add 1
      day: input.getDate() //getDate is for day and getDay is for number of the week (0-6)
    };

    return result;
  }

  toNgbDateStructFromString(input: string): NgbDateStruct {
    
    if (!input) {
      return null;
    }

    let inputToDate = new Date(input);
    let result: NgbDateStruct = {
      year: inputToDate.getFullYear(),
      month: inputToDate.getMonth() + 1, //Since getMonth for Date type starts from 0 - 11, add 1
      day: inputToDate.getDate() //getDate is for day and getDay is for number of the week (0-6)
    };

    return result;
  }
}
