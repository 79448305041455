import { Validation } from "../_shared/classes/Validation";
import { IAddress } from "../_shared/interfaces/IAddress";
import { IIdentifier } from "../_shared/interfaces/IIdentifier";
import { IInsuredInfo } from "../_shared/interfaces/IInsuredInfo";
import { CalculateValuationResult } from "../_shared/interfaces/verisk/valuation/ICalculateValuationResult";
import { ValuationInformation } from "../_shared/interfaces/verisk/valuation/IValuationInformation";
import { ILoginResult } from "../user/models/ILoginResult";
import { IToken } from "../user/models/IToken";
import { IUser } from "../user/models/IUser";
import { IPropertyDetail } from "../_shared/interfaces/IPropertyDetail";

export class SessionStorageUtils{

    public static getUser() : IUser{
        return JSON.parse(sessionStorage.getItem('fortuna.user')) as IUser;
    }

    public static getUserToken(): IToken {
        return JSON.parse(sessionStorage.getItem('fortuna.token')) as IToken;
    }

    public static getUserIdentifier(): ILoginResult | IIdentifier {
        return JSON.parse(sessionStorage.getItem('identifier')) as ILoginResult | IIdentifier;
    }

    public static getModel(){
        return JSON.parse(sessionStorage.getItem('model'))
    }

    public static saveUserIdentifier(identifier: IIdentifier | ILoginResult){
        SessionStorageUtils.saveIdentifier(identifier);
        const user = SessionStorageUtils.convertIdentifierToUser(identifier);
        SessionStorageUtils.saveUserInfo(user);
        SessionStorageUtils.saveUserToken(user.Token);
    }

    public static saveUserData(user: IUser){
        SessionStorageUtils.saveUserInfo(user);
        SessionStorageUtils.saveUserToken(user.Token);
        SessionStorageUtils.saveIdentifier(SessionStorageUtils.convertUserToLoginResult(user))
    }

    public static refreshUser(identifier: ILoginResult | IIdentifier){
        SessionStorageUtils.clearUserData();
        SessionStorageUtils.saveUserData(SessionStorageUtils.convertIdentifierToUser(identifier));
    }

    public static convertUserToIdentifier(user: IUser) : IIdentifier{
        return SessionStorageUtils.convertUserTo(user) as IIdentifier;
    }

    public static convertUserToLoginResult(user: IUser) : ILoginResult{
        return SessionStorageUtils.convertUserTo(user) as ILoginResult;
    }

    private static convertUserTo(user: IUser): ILoginResult | IIdentifier{
        return {
            UserId: user.Id,
            AccessToken: user.Token.AccessToken,
            RefreshToken: user.Token.RefreshToken,
            ExpirationDate: user.Token.ExpirationDate,
            Agency: user.Agency,
            AgencyId: user.AgencyId,
            AgentId: user.AgentId,
            AgencyStatusId: user.AgencyStatusId,
            AgentLicenseExists: user.AgentLicenseExists,
            Permissions: user.Permissions,
            Roles: user.Roles,
            StateAccess: user.StateAccess,
            FullName: user.Name,
            TempPassword: user.Token.TempPassword,
            ClientInitiated: user.ClientInitiated
        } as ILoginResult | IIdentifier
    }

    public static convertIdentifierToUser(identifier: ILoginResult | IIdentifier): IUser{
        return {
            Id: identifier.UserId,
            Agency: identifier.Agency,
            AgencyId: identifier.AgencyId ?? null,
            AgentId: identifier.AgentId,
            Name: identifier.FullName,
            ClientInitiated: false,
            Permissions: identifier.Permissions,
            Roles: identifier.Roles,
            StateAccess: identifier.StateAccess,
            AgencyStatusId: identifier.AgencyStatusId,
            AgentLicenseExists: identifier.AgentLicenseExists,
            Token:{
              RefreshToken: identifier.RefreshToken,
              AccessToken: identifier.AccessToken,
              ExpirationDate: identifier.ExpirationDate,
              TempPassword: identifier.TempPassword
            }
        } as IUser
    }

    private static saveUserToken(token: IToken) {
        sessionStorage.setItem('fortuna.token', JSON.stringify(token));
    }

    private static saveUserInfo(user: IUser) {
        sessionStorage.setItem('fortuna.user', JSON.stringify(user));
    }

    private static saveIdentifier(identifier: ILoginResult | IIdentifier){
        sessionStorage.setItem('identifier', JSON.stringify(identifier));
    }

    public static saveModel(model: any){
        sessionStorage.setItem('model', model);
    }

    ////////////////////////////////////////////////////////////////////////
    public static saveQuoteId(quoteId: number){
        sessionStorage.setItem('quoteId', JSON.stringify(quoteId));
    }

    public static saveQuoteNumber(quoteNumber: number) {
      sessionStorage.setItem('quoteNumber', JSON.stringify(quoteNumber));
  }

  public static saveDwelling(dwelling: number) {
    sessionStorage.setItem('dwelling', JSON.stringify(dwelling));
  }
  
    
    public static getQuoteId(): number{
        return Number.parseInt(sessionStorage.getItem('quoteId') ?? '0');
    }

    ////////////////////////////////////////////////////////////////////////
    public static saveRiskAddressId(riskAddressId: number){
        sessionStorage.setItem('riskAddressId', JSON.stringify(riskAddressId));
    }

    public static getRiskAddressId(): number{
        return Number.parseInt(sessionStorage.getItem('riskAddressId') ?? '0');
    }

    ////////////////////////////////////////////////////////////////////////
    public static saveInsured(insured: IInsuredInfo){
        sessionStorage.setItem("IInsuredInfo", JSON.stringify(insured));
    }

    public static getInsured(): IInsuredInfo{
        return JSON.parse(sessionStorage.getItem("IInsuredInfo")) as IInsuredInfo;
    }

    ////////////////////////////////////////////////////////////////////////
    public static saveAddress(address: IAddress){
        sessionStorage.setItem("address", JSON.stringify(address));
    }

    public static getAddress(): IAddress{
        return JSON.parse(sessionStorage.getItem("address")) as IAddress;
    }
    ////////////////////////////////////////////////////////////////////////
    
    public static saveValuation(valuation: CalculateValuationResult){
        sessionStorage.setItem("valuation", JSON.stringify(valuation));
    }

    public static getValuation(): CalculateValuationResult{
        return JSON.parse(sessionStorage.getItem("valuation")) as CalculateValuationResult;
    }
    ////////////////////////////////////////////////////////////////////////
    public static saveValuationInformation(valuationInformation: ValuationInformation){
        sessionStorage.setItem("valuationInformation", JSON.stringify(valuationInformation));
    }

    public static getValuationInformation(): ValuationInformation{
        return JSON.parse(sessionStorage.getItem("valuationInformation")) as ValuationInformation;
    }
    ////////////////////////////////////////////////////////////////////////
    public static savePropertydetail(propertydetail: IPropertyDetail){
        sessionStorage.setItem("propertydetail", JSON.stringify(propertydetail));
    }

    public static getPropertydetail(): IPropertyDetail{
        return JSON.parse(sessionStorage.getItem("propertydetail")) as IPropertyDetail;
    }
    ////////////////////////////////////////////////////////////////////////
    public static saveValidation(validation: Validation){
        sessionStorage.setItem("validation", JSON.stringify(validation));
    }

    public static getValidation(): Validation{
        return JSON.parse(sessionStorage.getItem("validation")) as Validation;
    }
    ////////////////////////////////////////////////////////////////////////

    public static saveEffectiveDate(effectiveDate: string){
        sessionStorage.setItem("effectiveDate", effectiveDate);
    }

    public static getEffectiveDate(): string{
        return sessionStorage.getItem("effectiveDate") as string;
    }
    ////////////////////////////////////////////////////////////////////////

    public static clearModel(){
        sessionStorage.removeItem('model');
    }

    public static wipeStorage(){
        sessionStorage.clear();
    }

    public static clearUserData() {
        sessionStorage.removeItem('fortuna.token');
        sessionStorage.removeItem('fortuna.user');
        sessionStorage.removeItem('identifier');
    }
}
